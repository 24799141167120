import { ZmatChartSerieSchema, ZmatChartSerieItemSchema } from '../../generics/zmat-chart/zmap-chart.schema';
import * as moment from 'moment';
import { EstacaoSeriePayload } from './estacao-serie.model';

export function parserToZmatChart(data: any): ZmatChartSerieSchema[] {

  let zmatSeries: ZmatChartSerieSchema[] = [];

  data.estacaoSeries.forEach(serie => {
    let zmatItem: ZmatChartSerieSchema = {
      name: serie.name,
      serie: serie.series.map(_value => {
        return {
          x: moment(_value.name, moment.ISO_8601).toDate(),
          y: parseFloat(_value.value)
        }
      })
    };

    zmatSeries.push(zmatItem);
  });

  return zmatSeries;
}

export function getEstacaoSerieIdFromPayload(payload: EstacaoSeriePayload): string 
{
  let estacaoSerieId = payload.estacaoId + ':' + payload.sensores.join(',');
      
  if (payload.data_inicio && payload.data_fim)
    estacaoSerieId += ':' + payload.data_inicio + ';' + payload.data_fim;
  else 
    estacaoSerieId += ':' + payload.periodo;

  return estacaoSerieId;
}