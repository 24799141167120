import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import Map              from 'ol/Map';
import Overlay          from 'ol/Overlay';
import XYZ              from 'ol/source/XYZ';
import View             from 'ol/View';
import Projection       from 'ol/proj/Projection';
import StyleIcon        from 'ol/style/Icon';
import KML              from 'ol/format/KML.js';
import {defaults as defaultControls, Attribution} from 'ol/control.js';
import Feature          from 'ol/Feature';
import Point from 'ol/geom/Point.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector.js';
import { Icon, Stroke, Style } from 'ol/style.js';
import {fromLonLat, transform } from 'ol/proj.js';

import { ConfigFacade } from '../../../state/config/config.facade';
import { ViewMode } from '../../../state/config/config.model';
import { EstacaoFacade } from '../../../state/estacao/estacao.facade';

@Component({
    selector: 'app-map-core',
    templateUrl: './map-core.component.html',
    styleUrls: ['./map-core.component.css']
})

export class MapCoreComponent implements OnInit {
    
    public olMap: any;
    public popupOverlay: Overlay;
    public fullscreen: boolean = false;
    public isMiniApp: boolean = false;

    public mousePosition: String = '0.000, 0.000'

    private circleLayer = null;

    @Input() options;
    @ViewChild('popup', null) popup: ElementRef;
    @ViewChild('coord', null) coord: ElementRef;

    constructor(
        private configFacade: ConfigFacade,
        private estacaoFacade: EstacaoFacade
    ) { 
        this.configFacade.fullscreen$.subscribe(valor => {
            this.fullscreen = valor;
            this.redrawMap();
        });
        this.configFacade.viewMode$.subscribe(valor => {
            this.isMiniApp = (valor == ViewMode.MINI);
        });
    }

    ngAfterViewInit(): void {
        this.estacaoFacade.current$.subscribe(value => {
            this.removeLayerById('circle');
            if (!this.olMap || !value.geom) return;

            let layer = this.createCircleLayer(transform(value.geom.coordinates, 'EPSG:4326', 'EPSG:3857'));

            this.circleLayer = layer.vector;
            this.olMap.addLayer(this.circleLayer);
        })
    }

    getMap()
    {
        return this.olMap;
    }

    redrawMap()
    {
        let _map = this.getMap();
        let interval = window.setInterval(function() { 
            try {
            _map.updateSize(interval);
            clearInterval();
            } catch (e) {
                
            }
        }, 100);
    }

    ngOnInit() 
    {
        this.init();
    }

    private init() 
    {
        this.parseOptions();

        const attribution = new Attribution({
            collapsible: false
        });

        this.olMap = new Map({
            target: this.options.target,
            layers: [
                new TileLayer({
                    source: this.options.source
                }),
                new VectorLayer({
                    source: new VectorSource({
                      url: 'https://cdn.funceme.br/assets/kml/brasil_estados.kml',
                      format: new KML({
                        extractStyles: false
                      })
                    }),
                    style: new Style({
                        stroke: new Stroke({color: 'gray', width: 1})
                    })
                })
            ],
            loadTilesWhileAnimating: true,
            //overlays: [this.overlay],
            view: new View({
                center: fromLonLat(this.options.center),
                zoom: this.options.zoom,
                minZoom: this.options.minZoom,
                maxZoom: 19
            }),
            controls: defaultControls({
                attribution: false
            }).extend([attribution])
        });

        this.popupOverlay = new Overlay({
            element: this.popup.nativeElement,
            offset: [9, 9]
        });

        this.olMap.addOverlay(this.popupOverlay);

        this.redrawMap();

        return this;
    }

    public changeZoomInit() 
    {   
        this.olMap.getView().setCenter([-39, -5]);   
    }

    private parseOptions() 
    {
        if (!this.options)
            this.options = {};

        this.options['center']  = (this.options.hasOwnProperty('center'))    ? this.options.center    : [0, 0];
        this.options['target']  = (this.options.hasOwnProperty('target'))    ? this.options.target    : 'map';
        this.options['source']  = (this.options.hasOwnProperty('source'))    ? this.options.source    : this.getDefautSourceMap();
        this.options['zoom']    = (this.options.hasOwnProperty('zoom'))      ? this.options.zoom      : this.getInitialMaxZoom(this.options['target']);
        this.options['minZoom'] = (this.options.hasOwnProperty('minZoom'))   ? this.options.minZoom   : this.getMinZoom(this.options['target']);
    }

    private getDefautSourceMap()
    {
        return new XYZ({
            url: 'http://tiles.funceme.br/styles/klokantech-basic/{z}/{x}/{y}.png',
            maxZoom: 18
        });
    }

    private getSatteliteSourceMap() 
    {
        return new XYZ({
            attributions: ['Powered by Esri',
                           'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
            attributionsCollapsible: false,
            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            maxZoom: 18
        });
    }

    public setMapSource() {
        this.olMap.getLayers().getArray()[0].setSource(this.getDefautSourceMap());
    }

    public setSatelliteSource() {
        this.olMap.getLayers().getArray()[0].setSource(this.getSatteliteSourceMap());
    }
    
    private getMinZoom(targetId)
    {
        const viewport: any = document.getElementById(targetId);
        const width = viewport.clientWidth;
        return Math.ceil(Math.LOG2E * Math.log(width / 80));
    }
    
    private getInitialMaxZoom(targetId) 
    {
        const viewport: any = document.getElementById(targetId);
        
        if (!viewport) 
          return;
        
        const width = viewport.clientWidth;

        return Math.ceil(Math.LOG2E * Math.log(width / 30));
    }

    public createImageMarker(data:any, asset:string, coord, color:string) 
    {
        var imageMarker = new Feature({
            geometry: new Point(fromLonLat(coord)),
            data: data
        });

        imageMarker.setStyle(new Style({
            image: new Icon(({
                color: color,
                crossOrigin: 'anonymous',
                src: asset
            }))
        }));

        return imageMarker;
    }

    public createPopupOverlay(container, options)
    {
        return new Overlay({
            element: container,
            autoPan: options.hasOwnProperty("autoPan")? options.autoPan :true,
            autoPanAnimation: {
                duration: options.hasOwnProperty("duration")? options.duration :250,
            }
        });
    }

    public createVectorLayer(features)
    {
        return new VectorLayer({
            source: new VectorSource({
                features: features
            })
        });
    }

    public createPoint(lon,lat)
    {
        return new Point(Projection.fromLonLat([parseFloat(lon), parseFloat(lat)]));
    }

    public createFeature(point, field, reg, label)
    {
        return new Feature({
            geometry: point,
            field: field,
            model: reg,
            label: label
        });
    }

    public createStyleIcon(style) {
        return new StyleIcon(style);
    }

    public removeVectorLayer(vectorLayer)
    {
        this.olMap.removeLayer(vectorLayer);
        return this; 
    }

    public removeLayerById(layerId:string)
    {
        let layersToRemove = [];
        this.olMap.getLayers().forEach(function (layer) {
            if (layer.get('id') === layerId) {
                layersToRemove.push(layer);
            }
        });
    
        for(var i = 0; i < layersToRemove.length; i++) {
            this.olMap.removeLayer(layersToRemove[i]);
        }
    }

    public addDivOverlay(div)
    {
        this.olMap.addOverlay(div);
    }

    public createCircleLayer(coord) {

        let vectorSource = new VectorSource()
        let vectorLayer = new VectorLayer({
            source: vectorSource,
            id: 'circle'
        })

        var circle = new Style({
            image: new Icon({
                anchor: [0.5, 1.15],
                crossOrigin: 'anonymous',
                src: 'https://cdn.funceme.br/assets/icon/map-marker.svg',
                scale: 0.07,
            })
        });

        var feature = new Feature(
            new Point([0, 0])
        );
        feature.setStyle(circle);
        vectorSource.addFeature(feature);
        
        let layer = {
            type: 'Point',
            vector: vectorLayer
        };

        layer.vector.setZIndex(3);
        feature.getGeometry().setCoordinates(coord);

        return layer;
    }

    public addEvents(events) 
    {
        var _map = this.olMap;
        var _popup = this.popup;
        var _coord = this.coord;
        var _popupOverlay = this.popupOverlay;

        this.olMap.removeEventListener('pointermove');

        if (events && events.hasOwnProperty('on') && events.on.hasOwnProperty('pointermove') && typeof events.on.pointermove == 'function') {
            this.olMap.on("pointermove", function (e) {
                const element = _popup.nativeElement;
                const coordElement = _coord.nativeElement;

                let features = _map.getFeaturesAtPixel(e.pixel);
                let clicado = false;

                let mousePositionArr = transform(e.coordinate, 'EPSG:3857', 'EPSG:4326');
                coordElement.innerHTML = mousePositionArr[0].toFixed(3)+', '+mousePositionArr[1].toFixed(3)
                if (!features) {
                    element.hidden = true;
                    return
                };
                features.forEach(feature => {
                    if (clicado) return;
                    if (feature && feature.values_.model ){
                        element.innerHTML = (feature 
                            && feature 
                            && feature.values_ 
                            && feature.values_.model 
                            && feature.values_.model.nome)? feature.values_.model.nome : '';
                        element.hidden = false; 
                        _popupOverlay.setPosition(e.coordinate);
                        clicado = true;
                    } else {
                        element.innerHTML = '';
                        element.hidden = true;
                    }
                });
            });
        }
    }
}
