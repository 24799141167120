import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigFacade } from 'libs/core-pcd/src/lib/state/config/config.facade';
import { DadoSensorFacade, SensorFacade, InstituicaoFacade, UfFacade } from '@funceme/core-pcd';
import { MatDrawer } from '@angular/material';
import { NavigateLinkService } from 'libs/core-pcd/src/lib/components/pcd/navigate-link.service';
import { zip } from 'rxjs';

@Component({
  selector: 'app-mini',
  templateUrl: './mini.component.html',
  styleUrls: ['./mini.component.css']
})
export class MiniComponent implements OnInit {

  public loadingDadosSensores: boolean = false;
  public queueIn: Date;
  public dataAtualizacao: string = '00:00:00';
  public cachedAt: Date;
  public dataCached: string = '00/00/0000 00:00:00';

  @ViewChild('drawer', {static: true}) panelOptions:MatDrawer; 

  constructor(
    private sensorFacade: SensorFacade,
    private instituicaoFacade: InstituicaoFacade,
    private ufFacade: UfFacade,
    private dadoSensorFacade: DadoSensorFacade,
    private configFacade: ConfigFacade,
    private navigateLinkService: NavigateLinkService
  ) { 
    this.configFacade.mini();

    this.dadoSensorFacade.loading$.subscribe(value => {
      this.loadingDadosSensores = value;
      if (this.loadingDadosSensores && this.panelOptions)
        this.panelOptions.close();
    });

    this.dadoSensorFacade.queueIn$.subscribe(
      queueIn => this.queueIn = queueIn
    )

    this.dadoSensorFacade.cachedAt$.subscribe(
      cachedAt => {
        if (cachedAt)
          this.dataCached = cachedAt.toLocaleString('pt-BR');
      }
    );
  }

  ngOnInit() {
    this.sensorFacade.load();
    this.instituicaoFacade.load();
    this.ufFacade.load();

    zip(
      this.sensorFacade.all$,
      this.instituicaoFacade.all$,
      this.ufFacade.all$
      ).subscribe(
        ([sensors,instituicoes,ufs]) =>{

        this.navigateLinkService.setParametersByDefault();
        this.dadoSensorFacade.load();
      }
    );

    this.autoRefresh();
  }

  private autoRefresh(){
    setInterval(()=>{
      if(this.queueIn && !this.loadingDadosSensores){
        let queueInTime = this.queueIn.getTime();
        
        if (queueInTime > Date.now()) {
          let time = (queueInTime - Date.now());
          this.dataAtualizacao = new Date(time).toISOString().substr(11, 8);
        }

        if (queueInTime < Date.now())
          this.dadoSensorFacade.load(true);
      }
    },1000);
  }
}
