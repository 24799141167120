import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { GraficoActions, GraficoActionTypes } from './grafico.actions';
import { Grafico } from './grafico.model';

/**
 * Interface to the part of the Store containing GraficoState
 * and other information related to GraficoData.
 */
export interface GraficoState extends EntityState<Grafico> {
  selectedId: string | null;
}

export const adapter: EntityAdapter<Grafico> = createEntityAdapter<Grafico>({
  selectId: (grafico: Grafico) => grafico.id,
});

export const initialState: GraficoState = adapter.getInitialState({
  selectedId: null
});

export function graficoReducer(state = initialState, action: GraficoActions): GraficoState {

  switch (action.type) {
    case GraficoActionTypes.GraficoSelected: {
      return Object.assign({}, state, { selectedId: action.payload });
    }
    default:
      return state;
  }
}

export const getSelectedGraficoId = (state: GraficoState) => state.selectedId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of grafico ids
export const selectGraficoIds = selectIds;

// select the dictionary of grafico entities
export const selectGraficoEntities = selectEntities;

// select the array of grafico
export const selectAllGraficos = selectAll;

// select the total grafico count
export const selectGraficoTotal = selectTotal;
