import { Component, OnInit } from '@angular/core';
import { Sensor } from '../../../state/sensor/sensor.model';
import { SensorFacade } from '../../../state/sensor/sensor.facade';
import { MatDialog } from '@angular/material/dialog';
import { SensorModalComponent } from '../sensor-modal/sensor-modal.component';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-sensor-select',
  templateUrl: './sensor-select.component.html',
  styleUrls: ['./sensor-select.component.css']
})
export class SensorSelectComponent implements OnInit {

  public sensores: Sensor[];
  public selectedSensorId: number;

  //private sensoresHabilitados = [2, 3, 4, 6, 7, 8, 10, 14, 18, 19, 20, 21, 22, 23, 24, 30, 50, 51, 54, 62];
  //private sensoresHabilitados = [2, 3, 4, 6, 7, 8, 10, 14, 18, 19, 20, 22, 23, 24, 30, 50, 51, 54, 62];
  
  private sensoresHabilitados = [2, 3, 4, 6, 7, 8, 22, 20, 31];
  
  private filteredSensors = [];

  constructor(
    private facade: SensorFacade,
    private dialog:MatDialog
  ) { 

    combineLatest(
      this.facade.all$,
      this.facade.current$,
      this.facade.filteredIds$
    ).subscribe(([all,current,filtered]) =>{
      if (filtered && filtered.length > 0) 
        //this.sensoresHabilitados = this.sensoresHabilitados;
        this.sensoresHabilitados = filtered;
      else 
        this.facade.filterMany(this.sensoresHabilitados);

      this.selectedSensorId = (current)? current.id : null

      if (this.selectedSensorId && !this.sensoresHabilitados.includes(Number(this.selectedSensorId)))
        this.facade.filterMany([Number(this.selectedSensorId)]);
      
      this.sensores = all.filter(sensor => this.sensoresHabilitados.includes(Number(sensor.id))) 
    })
  }

  ngOnInit() { }

  changedSensor(event) {
    this.facade.select(event.value);
  }

  openSensorModal() {
    this.dialog.open(SensorModalComponent, {
      maxWidth: '98vw',
      data: { } 
    });
  }
}