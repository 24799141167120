import * as fromGrafico from './grafico.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { emptyGrafico } from './grafico.model';

export const selectGraficoState = createFeatureSelector<fromGrafico.GraficoState>('grafico');

export const selectGraficoIds = createSelector(
  selectGraficoState,
  fromGrafico.selectGraficoIds
);

export const selectGraficoEntities = createSelector(
  selectGraficoState,
  fromGrafico.selectGraficoEntities
);

export const selectAllGraficos = createSelector(
  selectGraficoState,
  fromGrafico.selectAllGraficos
);

export const selectCurrentGraficoId = createSelector(
  selectGraficoState,
  fromGrafico.getSelectedGraficoId
);

export const selectCurrentGrafico = createSelector(
  selectGraficoEntities,
  selectCurrentGraficoId,
  (estacaoSerieEntities, estacaoSerieId) => {
    return estacaoSerieId ? estacaoSerieEntities[estacaoSerieId] : emptyGrafico;
  }
);