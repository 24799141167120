import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZmatSelectOneComponent } from './zmat-select-one/zmat-select-one.component';
import { ZmatSelectManyComponent } from './zmat-select-many/zmat-select-many.component';
import { MaterialModule } from '@funceme/material';



@NgModule({
  declarations: [
    ZmatSelectOneComponent, 
    ZmatSelectManyComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    ZmatSelectOneComponent, 
    ZmatSelectManyComponent
  ]
})
export class ZmatSelectModule { }
