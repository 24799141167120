import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MunicipioLgridComponent } from './municipio-lgrid/municipio-lgrid.component';
import { MunicipioModalComponent } from './municipio-modal/municipio-modal.component';
import { MunicipioModalSelectorComponent } from './municipio-modal-selector/municipio-modal-selector.component';
import { MaterialModule } from '@funceme/material';
import { FormsModule } from '@angular/forms';
import { UfModule } from '../uf/uf.module';
import { CoreDataModule } from '../../core-pcd.module';
import { ZmatLGridModule } from '../../generics/zmat-lgrid/zmat-lgrid.module';

@NgModule({
  declarations: [
    MunicipioLgridComponent, 
    MunicipioModalComponent, 
    MunicipioModalSelectorComponent
  ],
  imports: [
    CommonModule,
    CoreDataModule,
    MaterialModule,
    FormsModule,        
    UfModule,
    ZmatLGridModule
  ],
  exports: [
    MunicipioModalSelectorComponent
  ],
  entryComponents: [
    MunicipioModalComponent
  ]
})
export class MunicipioModule { }
