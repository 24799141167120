import { Action } from '@ngrx/store';
import { DadoSensor, DadoSensorPayload } from './dado-sensor.model';

export enum DadoSensorActionTypes {
  DadoSensoresAction = '[DadoSensores] Action',
  DadoSensorSelected = '[DadoSensores] Selected',
  LoadDadoSensores = '[DadoSensores] Load Data',
  DadoSensoresLoaded = '[DadoSensores] Data Loaded',
  DadoSensoresFailed = '[DadoSensores] Load Data Failed',
  DadoSensoresQueueIn = '[DadoSensores] Load Data QueueIn',
  DadoSensoresCachedAt = '[DadoSensores] Load Data CachedAt',
  ReportDadoSensor = '[DadoSensores] Report Data',
  DadoSensorReported = '[DadoSensores] Reported Data',
}

export class DadoSensores implements Action {
  readonly type = DadoSensorActionTypes.DadoSensoresAction;
}

export class DadoSensorSelected implements Action {
  readonly type = DadoSensorActionTypes.DadoSensorSelected;
  constructor(public payload) { }
}

export class LoadDadoSensores implements Action {
  readonly type = DadoSensorActionTypes.LoadDadoSensores;
  constructor(public payload: DadoSensorPayload) { }
}

export class DadoSensoresFailed implements Action {
  readonly type = DadoSensorActionTypes.DadoSensoresFailed;
  constructor() { }
}

export class DadoSensoresLoaded implements Action {
  readonly type = DadoSensorActionTypes.DadoSensoresLoaded;
  constructor(public payload: DadoSensor[]) { }
}

export class DadoSensoresQueueIn implements Action {
  readonly type = DadoSensorActionTypes.DadoSensoresQueueIn;
  constructor(public payload) { }
}

export class DadoSensoresCachedAt implements Action {
  readonly type = DadoSensorActionTypes.DadoSensoresCachedAt;
  constructor(public payload) { }
}

export class ReportDadoSensor implements Action {
  readonly type = DadoSensorActionTypes.ReportDadoSensor;
  constructor(public payload) { }
}

export class DadoSensorReported implements Action {
  readonly type = DadoSensorActionTypes.DadoSensorReported;
  constructor(public payload) { 
    
  }
}

export type DadoSensorActions = DadoSensores
  | DadoSensorSelected
  | LoadDadoSensores
  | DadoSensoresLoaded
  | DadoSensoresFailed
  | DadoSensoresQueueIn
  | DadoSensoresCachedAt
  | ReportDadoSensor
  | DadoSensorReported
;
