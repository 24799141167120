import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZmatChartLineComponent } from './zmat-chart-line/zmat-chart-line.component';
import { MaterialModule } from '@funceme/material';

@NgModule({
  declarations: [
    ZmatChartLineComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    ZmatChartLineComponent
  ]
})
export class ZmatChartModule { }
