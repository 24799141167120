import { Component, OnInit, ElementRef, ViewChildren, QueryList, Input, EventEmitter, Output} from '@angular/core';
import { Observable } from 'rxjs';
import { MatChip } from '@angular/material';
import { Instituicao } from '../../../state/instituicao/instituicao.model';
import { DadoSensor } from '../../../state/dado-sensor/dado-sensor.model';
import { SensorFacade } from '../../../state/sensor/sensor.facade';
import { IntervaloFacade } from '../../../state/intervalo/intervalo.facade';
import { InstituicaoFacade } from '../../../state/instituicao/instituicao.facade';
import { UfFacade } from '../../../state/uf/uf.facade';
import { DadoSensorFacade } from '../../../state/dado-sensor/dado-sensor.facade';
import { ConfigFacade } from '../../../state/config/config.facade';
import { ViewMode } from '../../../state/config/config.model';


@Component({
  selector: 'app-pcd-titulo',
  templateUrl: './pcd-titulo.component.html',
  styleUrls: ['./pcd-titulo.component.css']
})
export class PcdTituloComponent implements OnInit {

  public soughtSensorNome: string;
  public soughtSensorUnidade: string;
  public soughtIntervaloNome: string;
  public soughtPeriodoNome: string;
  public operador: string;
  public soughtInstituicoes$: Observable<Instituicao[]>;
  public soughtUfs$: Observable<string[]>;

  public soughtInstituicoes: Instituicao[];
  public soughtUfs: string[];

  private dadosSensor: DadoSensor[];

  public fullscreen: boolean = false;
  public isMiniApp: boolean = false;

  public dicionarioDeOperadores =
  {
    'MIN' : 'Mínima',
    'AVG' : 'Média',
    'MAX' : 'Máxima'
  }

  @ViewChildren(MatChip) chips !: QueryList<MatChip>;

  @Input() showSelecionados;
  @Output() showSelecionadosChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private periodoNome = {
    '1h': '1 hora', '2h': '2 horas', '6h': '6 horas', '12h': '12 horas', '24h': '24 horas', '48h': '48 horas',
    '0d': 'Hoje', '1d': 'Ontem', '7d': '7 dias', '15d': '15 dias',
  }

  constructor(
    private sensorFacade: SensorFacade,
    private intervaloFacade: IntervaloFacade,
    private instituicaoFacade: InstituicaoFacade,
    private ufFacade: UfFacade,
    private dadoSensorFacade: DadoSensorFacade,
    private configFacade: ConfigFacade
  ) {

    this.configFacade.fullscreen$.subscribe(value => this.fullscreen = value);
    this.configFacade.viewMode$.subscribe(value => this.isMiniApp = (value == ViewMode.MINI));

    this.dadoSensorFacade.allDadoSensores$.subscribe(
      dadosSensor => this.dadosSensor = dadosSensor
    );

    this.sensorFacade.operador$.subscribe(value => this.operador = value);

    this.sensorFacade.sought$.subscribe(
      soughtSensor => this.soughtSensorNome = soughtSensor.descricao
    );

    this.sensorFacade.sought$.subscribe(
      soughtSensor => this.soughtSensorUnidade = soughtSensor.unidade
    );

    this.instituicaoFacade.sought$.subscribe(
      soughtInstituicoes => this.soughtInstituicoes = soughtInstituicoes
    );

    this.ufFacade.sought$.subscribe(
      soughtUfs => this.soughtUfs = soughtUfs
    );

    this.intervaloFacade.soughtIntervalo$.subscribe(
      soughtIntervalo => {
        if(soughtIntervalo && soughtIntervalo.nome)
          this.soughtIntervaloNome = soughtIntervalo.nome+" | "
      }
    );

    this.intervaloFacade.soughtPeriodo$.subscribe(
      soughtPeriodo => {
        if (!soughtPeriodo) return

        if (soughtPeriodo.length > 3) {
          let dates = soughtPeriodo.split(',')
          let date0 = dates[0].split('-');
          let dateI =  new Date(Number(date0[0]),Number(date0[1])-1,Number(date0[2]));

          if (dates[1]) {
            let date1 = dates[1].split('-');
            let dateF = new Date(Number(date1[0]),Number(date1[1])-1,Number(date1[2]));
            this.soughtPeriodoNome = dateI.toLocaleDateString('pt-BR')+" a "+dateF.toLocaleDateString('pt-BR')
          } else {
            this.soughtPeriodoNome = this.getMonth(dateI.getMonth())+" "+dateI.getFullYear();
          }
        } else
          this.soughtPeriodoNome = this.periodoNome[soughtPeriodo]
      }
    );

    this.soughtInstituicoes$ = this.instituicaoFacade.sought$;
    this.soughtUfs$ = this.ufFacade.sought$;
  }

  ngOnInit() {
  }

  getMonth(monthIndex) {
    let months = ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro']

    return months[monthIndex];
  }

  public ufCount(ufId: string): number {
    return this.dadosSensor.filter(dado => dado.valor && dado.municipio == ufId).length;
  }

  public instituicaoCount(instituicaoName: string): number {
    return this.dadosSensor.filter(dado => dado.valor && dado.instituicao == instituicaoName).length;
  }

  public close () {
    this.showSelecionadosChange.emit(false);
  }
}
