import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { Sensor } from '../../../state/sensor/sensor.model';
import { EstacaoSerieFacade } from '../../../state/estacao-serie/estacao-serie.facade';
import { SensorFacade } from '../../../state/sensor/sensor.facade';
import { SensorSerie } from '../../../state/estacao-serie/estacao-serie.model';

@Component({
  selector: 'app-chart-arrow',
  templateUrl: './chart-arrow.component.html',
  styleUrls: ['./chart-arrow.component.css']
})
export class ChartArrowComponent implements OnInit {

  chart = [];

  @Input() target: string;
  @Input() colors;
  @Input() sensorIds: number[];
  @Input() periodo: string;

  public targetDiv: string;

  private sensores: Sensor[];

  constructor(private estacaoSerieFacade: EstacaoSerieFacade, private sensorFacade: SensorFacade, private elementRef:ElementRef) 
  { 
    this.targetDiv = 'div-' + this.target;
  }

  ngOnInit() {

    this.sensorFacade.all$.subscribe( 
      sensores => {
        this.sensores = sensores.filter(sensor => this.sensorIds.includes(Number(sensor.id)));
      }
    );

    // this.estacaoSerieFacade.currentEstacaoSerie$.subscribe(
    //   currentSerie => {
    //     if (!currentSerie.estacaoSeries)
    //       return;
        
    //     let sensorSeries = currentSerie.estacaoSeries.filter(serie => this.sensorIds.includes(serie.name));

    //     if (sensorSeries.length && sensorSeries[0].series.length)
    //       this.createCanvas(sensorSeries, this.sensores);
    //   }
    // );   
  }

  getLabels(data) {
    let labels;
    for (const d of data) {
      labels = d.labels;
    }

    return labels;
  }

  getResults(results, colors, combo_chart = false) {

    const result = [];
    results.forEach((item, index) => {
      result.push(
        {
          data: item.data,
          borderColor: colors[index],
          fill: false,
          label: item.label,
          type: (index == 0 && combo_chart) ? 'bar' : 'line',
          backgroundColor: colors[index],
        }
      );
    });
    return result;
  }

  private getDatasets(series, sensores)
  {
    let datasets = [];

    sensores.forEach((sensor, index) => {

      if (!series[index])
        return;

      let color = this.colors[index];
      let serie = series[index].series;

      datasets.push({
        data: serie.map(item => {
          return {
            x: moment(item.name, moment.ISO_8601).toDate(),
            y: item.value
          };
        }),
        borderColor: color,
        fill: false,
        label: sensor.descricao + ' ( '+ sensor.id + ')',
        type: 'line',
        backgroundColor: color,
      })
    });

    return datasets;
  }

  private createCanvas(series: SensorSerie[] | null, sensores: Sensor[] | null)
  {
    let interval = window.setInterval(() => {
      try {
        let _canvas = this.elementRef.nativeElement.querySelector('#' + this.target);
        if (_canvas)
          _canvas.remove();

        let _div = this.elementRef.nativeElement.querySelector('#' + this.targetDiv);
        _div.insertAdjacentHTML('afterbegin', '<canvas id="'+this.target+'" width="100%"></canvas>');

        this.draw(series, sensores);

        clearInterval(interval);
      } catch (error) {
        console.log('error');
      }
    }, 300);
  }

  private draw(series: SensorSerie[] | null, sensores: Sensor[] | null) {

    this.chart = new Chart(this.target, {
      type: 'line',
      data: {
        labels: series[0].series.map(dado => dado.name),
        datasets: this.getDatasets(series, sensores)
      },
      options: {
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            display: true,
            type: 'time',
            distribution: 'series'
          }],
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: false
            }
          }],
        },
        spanGaps: false,
        tooltips: {
          callbacks: {
            title: () => {
              return '';
            },
            // beforeLabel: (tooltipItem, data) => {
            //   const dt = new Date(tooltipItem.xLabel);
            //   return ('00' + dt.getDate().toString()).slice(-2) + '/' + ('00' + (dt.getMonth() + 1).toString()).slice(-2) +
            //     '/' + dt.getFullYear() + ' ' + ('00' + dt.getHours().toString()).slice(-2) + ':' +
            //     ('00' + dt.getMinutes().toString()).slice(-2);
            // }
          }
        },
        zoom: {
          // Boolean to enable zooming
          enabled: true,

          // Enable drag-to-zoom behavior
          drag: true,

          // Zooming directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow zooming in the y direction
          mode: 'xy',
          rangeMin: {
            // Format of min zoom range depends on scale type
            x: null,
            y: null
          },
          rangeMax: {
            // Format of max zoom range depends on scale type
            x: null,
            y: null
          }
        }
      }
    });
  }

}
