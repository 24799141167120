import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { EstacaoActions, EstacaoActionTypes } from './estacao.actions';
import { Estacao } from './estacao.model';
import { ApiMetaData } from '../../generics/pagination.model';

/**
 * Interface to the part of the Store containing EstacaoState
 * and other information related to EstacaoData.
 */
export interface EstacaoState extends EntityState<Estacao> {
  selectedId: number | null;
  pickingId: number | null;
  loaded_at: Date | null;
  selectedIds: number[];
  loading: boolean;
  meta_data: ApiMetaData;
  total: number,
  last_pagination: string
}

export const adapter: EntityAdapter<Estacao> = createEntityAdapter<Estacao>({
  selectId: (estacao: Estacao) => estacao.id
});

export const initialState: EstacaoState = adapter.getInitialState({
  selectedId: null,
  pickingId: null,
  loaded_at: null,
  selectedIds: null,
  loading: null,
  meta_data: null,
  total: null,
  last_pagination: null
});

export function estacaoReducer(state = initialState, action: EstacaoActions): EstacaoState {

  switch (action.type) {
    case EstacaoActionTypes.EstacaoSelected: {
      return Object.assign({}, state, { selectedId: action.payload, pickingId: null });
    }

    case EstacaoActionTypes.PickEstacao: {
      return Object.assign({}, state, { pickingId: action.payload }) ;
    }

    case EstacaoActionTypes.EstacaoAdded: {
      state.selectedId = action.payload.id;
      state.pickingId = null;
      return adapter.addOne(action.payload, state);
    }
 
    case EstacaoActionTypes.EstacoesSelected: {
      return Object.assign({}, state, { selectedIds: [...state.selectedIds, action.payload] });
    }

    case EstacaoActionTypes.EstacoesUnselected: {
      return Object.assign({}, state, { 
        selectedEstacaoIds: state.selectedIds.filter(id => !action.payload.includes(id))
      });
    }

    case EstacaoActionTypes.LoadEstacoes: {      
      return Object.assign({}, state, { loading: true, selectedId: null, entities: [], selectedIds: [] });
    }

    case EstacaoActionTypes.EstacoesLoaded: {
      return adapter.addAll(action.payload, Object.assign({}, state, { loading: false, loaded_at : new Date() }));
    }

    case EstacaoActionTypes.PaginateEstacoes: {
      state.loading = true; 
      state.loaded_at = null;
      state.pickingId = null;
      state.selectedId = null;
      state.selectedIds = [];
      
      return adapter.removeAll(state);
    }

    case EstacaoActionTypes.EstacoesPaginated: {          
      return adapter.addAll(action.payload.data.list, Object.assign({}, state, { 
        meta_data: action.payload.meta, 
        total: action.payload.data.total_results,
        loading: false,
        loaded_at: new Date().toISOString(),
        last_pagination: JSON.stringify(action.payload.meta.request.query_params),
      }));  
    }

    case EstacaoActionTypes.EstacoesRequestDone:
    case EstacaoActionTypes.EstacoesRequestFailed: {
      return Object.assign({}, state, { loading: false });
    }
    
    default:
      return state;
    }
  }
  
export const getSelectedEstacaoIds = (state: EstacaoState) => state.selectedIds;
export const getMetaDataEstacoes = (state: EstacaoState) => state.meta_data;
export const getTotalEstacoes = (state: EstacaoState) => state.total;
export const getLoadingEstacoes = (state: EstacaoState) => state.loading;
export const getSelectedEstacaoId = (state: EstacaoState) => state.selectedId;
export const getPickingEstacaoId  = (state: EstacaoState) => state.pickingId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of estacao ids
export const selectEstacaoIds = selectIds;

// select the dictionary of estacao entities
export const selectEstacaoEntities = selectEntities;

// select the array of estacao
export const selectAllEstacoes = selectAll;

// select the total estacao count
export const selectEstacaoTotal = selectTotal;
