export interface TempoAgora {
    atualizacao: string;
    chuva: string;
    chuva_acum: string;
    codigo: string;
    codigo_municipio: string;
    dir_vento_vel_max: string;
    dir_vento_vel_min: string;
    imagem: string;
    img_fundo: string;
    latitude: string;
    longitude: string;
    nome: string;
    pressao: string;
    pressao_max: string;
    pressao_min: string;
    rad: string;
    rad_acum: string;
    rad_max: string;
    rad_min: string;
    temperatura: string;
    temperatura_max: string;
    temperatura_min: string;
    umidade: string;
    umidade_max: string;
    umidade_min: string;
    vento_dir: string;
    vento_vel: string;
    vento_vel_max: string;
    vento_vel_min: string;
    _cached_at: Date;
    _loaded_at: Date;
    _expires_in: number;
    _queue_in: number;
}

export const emptyTempoAgora: TempoAgora = {
    atualizacao: null,
    chuva: null,
    chuva_acum: null,
    codigo: null,
    codigo_municipio: null,
    dir_vento_vel_max: null,
    dir_vento_vel_min: null,
    imagem: null,
    img_fundo: null,
    latitude: null,
    longitude: null,
    nome: null,
    pressao: null,
    pressao_max: null,
    pressao_min: null,
    rad: null,
    rad_acum: null,
    rad_max: null,
    rad_min: null,
    temperatura: null,
    temperatura_max: null,
    temperatura_min: null,
    umidade: null,
    umidade_max: null,
    umidade_min: null,
    vento_dir: null,
    vento_vel: null,
    vento_vel_max: null,
    vento_vel_min: null,
    _cached_at: null,
    _loaded_at: null,
    _expires_in: null,
    _queue_in: null
}
