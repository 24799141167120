import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { Municipio } from './municipio.model';
import { MunicipioService } from './municipio.service';

import {
  LoadMunicipios, MunicipiosLoaded,
  MunicipioActionTypes,
  MunicipiosPaginated,
  PaginateMunicipios,
  MunicipiosRequestDone,
  MunicipiosRequestFailed
} from './municipio.actions';
import { MunicipioState } from './municipio.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { MunicipioFacade } from './municipio.facade';
import { ApiResponse, paginationEquals } from '../../generics/pagination.model';

@Injectable({providedIn: 'root'})
export class MunicipioEffects 
{
  //@Effect() effect$ = this.actions$.ofType(MunicipiosActionTypes.MunicipiosAction);

  @Effect()
  loadMunicipios$ = this.dataPersistence.fetch(MunicipioActionTypes.LoadMunicipios, {
    run: (action: LoadMunicipios, state: any) => {

      if(Object.keys(state.municipio.entities).length > 0){
        let allMunicipios;

        this.municipioFacade.all$.subscribe(municipios => allMunicipios = municipios )
        return new MunicipiosLoaded(allMunicipios);
      }

      return this.municipioservice
        .all()
        .pipe(
          map((res: Municipio[]) => new MunicipiosLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadMunicipios, error) => {
      this.notifyError.emit(error.message);
      return new MunicipiosRequestFailed;
    }
  });

  @Effect()
  paginateMunicipios$ = this.dataPersistence.fetch(MunicipioActionTypes.PaginateMunicipios, {
    run: (action: PaginateMunicipios, state: any) => {

      if (state.municipio.last_pagination && paginationEquals(action.payload, JSON.parse(state.municipio.last_pagination))) 
        return new MunicipiosRequestDone;

      return this.municipioservice
        .paginate(action.payload)
        .pipe(
          map((res: ApiResponse<Municipio>) => new MunicipiosPaginated(res))
        )
    },

    onError: (action: PaginateMunicipios, error) => {
      this.notifyError.emit(error.message);
      return new MunicipiosRequestFailed;
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<MunicipioState>,
    private municipioservice: MunicipioService,
    private municipioFacade: MunicipioFacade,
    private notifyError: NotificationsService
  ) {}
}
