import * as fromIntervalo from './intervalo.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { emptyIntervalo } from './intervalo.model';

export const selectIntervaloState = createFeatureSelector<fromIntervalo.IntervaloState>('intervalo');

export const selectIntervaloIds = createSelector(
  selectIntervaloState,
  fromIntervalo.selectIntervaloIds
);

export const selectIntervaloEntities = createSelector(
  selectIntervaloState,
  fromIntervalo.selectIntervaloEntities
);

export const selectAllIntervalos = createSelector(
  selectIntervaloState,
  fromIntervalo.selectAllIntervalos
);

export const selectCurrentIntervaloId = createSelector(
  selectIntervaloState,
  fromIntervalo.getSelectedIntervaloId
);

export const selectCurrentPeriodo = createSelector(
  selectIntervaloState,
  fromIntervalo.getSelectedPeriodo
);

export const selectCurrentIntervalo = createSelector(
  selectIntervaloEntities,
  selectCurrentIntervaloId,
  (intervaloEntities, intervaloId) => {
    return intervaloId ? intervaloEntities[intervaloId] : emptyIntervalo;
  }
);

export const selectSoughtIntervaloId = createSelector(
  selectIntervaloState,
  fromIntervalo.getSoughtIntervaloId
);

export const selectSoughtPeriodo = createSelector(
  selectIntervaloState,
  fromIntervalo.getSoughtPeriodo
);

export const selectSoughtIntervalo = createSelector(
  selectIntervaloEntities,
  selectSoughtIntervaloId,
  (intervaloEntities, intervaloId) => {
    return intervaloId ? intervaloEntities[intervaloId] : emptyIntervalo;
  }
);

