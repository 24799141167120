import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NotificationsService } from '../../notifications/notifications.service';
import { TempoAgora } from './tempo-agora.model';

@Injectable({
  providedIn: 'root'
})
export class TempoAgoraService {
  
  model = 'rpc/v1/tempo-agora';

  constructor(
    private http: HttpClient,
    private ns: NotificationsService
  ) { }

  getUrl() {
    return `${environment.apiEndpoint}${this.model}`;
  }

  getUrlForId(id) {
    if (id == null)
    {
      return "";
    }
    return `${this.getUrl()}?estacao=${id}`;
  }

  pick(id) {
    if (id == null)
    {
      return null;
    }
    return this.http.get<TempoAgora>(this.getUrlForId(id));
  }
}
