import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { map } from 'rxjs/operators';
import { isString } from 'util';
import { Instituicao } from '../../../state/instituicao/instituicao.model';
import { InstituicaoFacade } from '../../../state/instituicao/instituicao.facade';

@Component({
  selector: 'app-instituicao-select',
  templateUrl: './instituicao-select.component.html',
  styleUrls: ['./instituicao-select.component.css']
})
export class InstituicaoSelectComponent implements OnInit {

  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public instituicaoCtrl = new FormControl();

  public filteredInstituicoes$: Observable<Instituicao[]>;
  public selectedInstituicoes: Instituicao[] = [];
  public allInstituicoes: Instituicao[] = [];

  @Input() private instituicoesHabilitadas;

  @ViewChild('instituicaoInput', {static: false}) instituicaoInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  constructor(
    private instituicaoFacade: InstituicaoFacade
  ) { 
    this.filteredInstituicoes$ = this.instituicaoFacade.all$.pipe(
      map(instituicoes => this.allInstituicoes = instituicoes.filter(instituicao => instituicao && this.instituicoesHabilitadas.includes(Number(instituicao.id))))
    );

    this.instituicaoFacade.currentList$.subscribe(
      currentInstituicoes => this.selectedInstituicoes = currentInstituicoes
    );

    this.instituicaoFacade.sought$.subscribe(
      soughtInstituicoes => this.selectedInstituicoes = soughtInstituicoes
    )

    this.instituicaoCtrl.valueChanges.subscribe(
      search => {
        this.filteredInstituicoes$ = this.filteredInstituicoes$.pipe(
          map(() => search && isString(search)? this._filter(search) : this.allInstituicoes.slice()),
          map(instituicoes => instituicoes.filter(instituicao => this.selectedInstituicoes.indexOf(instituicao) === -1))
        )
      }
    );
  }

  ngOnInit() {
    
  } 

  remove(instituicao: Instituicao): void {
    if (!instituicao) return;
    this.instituicaoFacade.unselectMany([instituicao.id]);
    this.instituicaoCtrl.updateValueAndValidity();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    
    let selectedInstituicao = this.allInstituicoes.find(instituicao => instituicao.id == event.option.value);
    
    if (this.selectedInstituicoes.indexOf(selectedInstituicao) !== -1) 
      return;
    this.instituicaoFacade.selectMany([selectedInstituicao.id]);
    this.instituicaoInput.nativeElement.value = '';
    this.clickOut();  
}
  private _filter(search: string): Instituicao[] {
    const filterValue = search.toLowerCase();
    return this.allInstituicoes.filter(instituicao => instituicao.nome.toLowerCase().indexOf(filterValue) === 0);
  }

  clickOut(){
    let elementOut: HTMLElement = document.querySelector("#mat-chip-list-input-0") as HTMLElement;
    elementOut.click();
    let elementIn: HTMLElement = document.querySelector("#mat-chip-list-input-1") as HTMLElement;
    elementIn.click();
  }
}
