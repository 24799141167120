import { Action } from '@ngrx/store';
import { Instituicao } from './instituicao.model';
import { Pagination, ApiResponse } from '../../generics/pagination.model';

export enum InstituicaoActionTypes {
  InstituicoesAction        = '[Instituicoes] Action',
  LoadInstituicoes          = '[Instituicoes] Load Data',
  InstituicoesLoaded        = '[Instituicoes] Data Loaded',
  AddInstituicao            = '[Instituicoes] Add Data',
  InstituicaoAdded          = '[Instituicoes] Data Added',
  UpdateInstituicao         = '[Instituicoes] Update Data',
  InstituicaoUpdated        = '[Instituicoes] Data Updated',
  DeleteInstituicao         = '[Instituicoes] Delete Data',
  InstituicaoDeleted        = '[Instituicoes] Data Deleted',
  InstituicaoSelected       = '[Instituicoes] Selected one',
  InstituicaoUnselected     = '[Instituicoes] Unselected one',
  InstituicoesSought        = '[Instituicoes] Sought',
  InstituicoesSelected      = '[Instituicoes] Selected many',
  InstituicoesUnselected    = '[Instituicoes] Unselected many',
  PaginateInstituicoes      = '[Instituicoes] Paginate',
  InstituicoesPaginated     = '[Instituicoes] Paginated',
  InstituicoesRequestDone   = '[Instituicoes] RequestDone',
  InstituicoesRequestFailed = '[Instituicoes] Request Failed',
  PickInstituicao           = '[Instituicoes] Pick',
}

export class Instituicoes implements Action {
  readonly type = InstituicaoActionTypes.InstituicoesAction;
}

export class LoadInstituicoes implements Action {
  readonly type = InstituicaoActionTypes.LoadInstituicoes;
  constructor(public reset: boolean) { }
}

export class InstituicoesLoaded implements Action {
  readonly type = InstituicaoActionTypes.InstituicoesLoaded;
  constructor(public payload: Instituicao[]) { }
}

export class AddInstituicao implements Action {
  readonly type = InstituicaoActionTypes.AddInstituicao;
  constructor(public payload: Instituicao) { }
}

export class InstituicaoAdded implements Action {
  readonly type = InstituicaoActionTypes.InstituicaoAdded;
  constructor(public payload: Instituicao) { }
}

export class UpdateInstituicao implements Action {
  readonly type = InstituicaoActionTypes.UpdateInstituicao;
  constructor(public payload: Instituicao) { }
}

export class InstituicaoUpdated implements Action {
  readonly type = InstituicaoActionTypes.InstituicaoUpdated;
  constructor(public payload: Instituicao) { }
}

export class DeleteInstituicao implements Action {
  readonly type = InstituicaoActionTypes.DeleteInstituicao;
  constructor(public payload: Instituicao) { }
}

export class InstituicaoDeleted implements Action {
  readonly type = InstituicaoActionTypes.InstituicaoDeleted;
  constructor(public payload: Instituicao) { }
}

export class InstituicaoSelected implements Action {
  readonly type = InstituicaoActionTypes.InstituicaoSelected;
  constructor(public payload: number) { }
}

export class InstituicaoUnselected implements Action {
  readonly type = InstituicaoActionTypes.InstituicaoUnselected;
  constructor(public payload: number) { }
}

export class InstituicoesSought implements Action {
  readonly type = InstituicaoActionTypes.InstituicoesSought;
  constructor(public payload: number[]) { }
}

export class InstituicoesSelected implements Action {
  readonly type = InstituicaoActionTypes.InstituicoesSelected;
  constructor(public payload: number[]) { }
}

export class InstituicoesUnselected implements Action {
  readonly type = InstituicaoActionTypes.InstituicoesUnselected;
  constructor(public payload: number[]) { }
}

export class PaginateInstituicoes implements Action {
  readonly type = InstituicaoActionTypes.PaginateInstituicoes;
  constructor(public payload: Pagination) { }
}

export class InstituicoesPaginated implements Action {
  readonly type = InstituicaoActionTypes.InstituicoesPaginated;
  constructor(public payload: ApiResponse<Instituicao>) { }
}

export class InstituicoesRequestDone implements Action {
  readonly type = InstituicaoActionTypes.InstituicoesRequestDone;
  constructor() { }
}

export class InstituicoesRequestFailed implements Action {
  readonly type = InstituicaoActionTypes.InstituicoesRequestFailed;
  constructor() { }
}

export class PickInstituicao implements Action {
  readonly type = InstituicaoActionTypes.PickInstituicao;
  constructor(public payload: number) { }
}

export type InstituicaoActions = Instituicoes
  | LoadInstituicoes
  | InstituicoesLoaded
  | AddInstituicao
  | InstituicaoAdded
  | UpdateInstituicao
  | InstituicaoUpdated
  | DeleteInstituicao
  | InstituicaoDeleted
  | InstituicaoUnselected
  | InstituicaoSelected
  | InstituicoesSought
  | InstituicoesSelected
  | InstituicoesUnselected
  | PaginateInstituicoes
  | InstituicoesPaginated
  | InstituicoesRequestDone
  | InstituicoesRequestFailed
  | PickInstituicao   
;