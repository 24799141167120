import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { SensorFacade } from '../../../state/sensor/sensor.facade';
import { isUndefined } from 'util';
import { DadoSensorFacade } from '../../../state/dado-sensor/dado-sensor.facade';
import { DadoSensor } from '../../../state/dado-sensor/dado-sensor.model';
import { LegendaSchema } from '../../../state/sensor/sensor.legenda';
import { EstacaoFacade } from '../../../state/estacao/estacao.facade';
import { MapPcdMarkerFactory } from '../../map/map-pcd-marker/map-pcd-marker.factory';

@Component({
  selector: 'app-sensor-legenda',
  templateUrl: './sensor-legenda.component.html',
  styleUrls: ['./sensor-legenda.component.css']
})
export class SensorLegendaComponent implements OnInit {

  public title: string;
  public labels: any[] = null;
  public highlights: any[] = [];
  public dados: DadoSensor[];

  @Input() legenda;
  @Output() legendaChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() destaque;
  @Output() destaqueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() show;
  @Output() showChange: EventEmitter<String> = new EventEmitter<String>();

  constructor(
    private sensorFacade: SensorFacade,
    private dadoSensorFacade: DadoSensorFacade,
    private estacaoFacade: EstacaoFacade,
    private pcdMarkerFactory: MapPcdMarkerFactory
  ) { 
    this.sensorFacade.sought$.subscribe(value => this.plot(Number(value.id)));
    this.dadoSensorFacade.allDadoSensores$.subscribe(
      value => {
        this.dados = value.slice();
        this.plotHightlights()
      }
    );
  }

  ngOnInit() {

  }

  public pickEstacao(estacaoId)
  {
    this.estacaoFacade.pick(estacaoId);
  }

  private plot(sensorId: number)
  {
    let _legenda = LegendaSchema.find(sensor => sensor.values.includes(sensorId));

    if (isUndefined(_legenda)) _legenda = LegendaSchema[0];

    this.title  = _legenda.name;
    this.labels = _legenda.labels;
  }

  private plotHightlights() 
  {
    if (!this.dados || this.dados.length ==0) return this.highlights = [];

    this.highlights = [];

    let _legenda = LegendaSchema.find(sensor => sensor.values.includes(Number(this.dados[0].sensor)));

    if (isUndefined(_legenda)) _legenda = LegendaSchema[0];

    _legenda.highlights.forEach(item => {
      let predicate = null;
      switch(item.method) {
        case 'max': 
          predicate = this.sortDescPredicate;
          break;
        case 'min': 
          predicate = this.sortAscPredicate;
          break;  
        default: 
          predicate = this.sortDescPredicate;
          break;
      }

      let _highlight = {
        title: item.title,
        data: [] 
      };

      _highlight.data = this.dados.filter(dado => dado.valor != null).sort(predicate).slice(0, item.limit).map(dado => {
        dado.valor = Number(dado.valor).toFixed(1);
        return dado;
      });

      this.highlights.push(_highlight);
    });
  }

  private sortDescPredicate(a: DadoSensor, b: DadoSensor): number {
    if (Number(a.valor) == Number(b.valor))
      return 0;
    else if (Number(a.valor) < Number(b.valor))  
      return 1;
    else 
      return -1;  
  }

  private sortAscPredicate(a: DadoSensor, b: DadoSensor): number {
    if (Number(a.valor) == Number(b.valor))
      return 0;
    else if (Number(a.valor) > Number(b.valor))  
      return 1;
    else 
      return -1;  
  }

  public getLabelColor(dado: DadoSensor) {
    return this.pcdMarkerFactory.getLabelColor(this.labels, dado);
  }

  public getFontColor(dado: DadoSensor) {
    return this.pcdMarkerFactory.getFontColor(this.labels, dado);
  }
  public close(){
    this.showChange.emit('');
  }

  public showLegenda() {
    if (this.show != 'legenda')
      this.showChange.emit('legenda');
    else 
      this.showChange.emit('');
  }
}