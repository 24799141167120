import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { ZmatSelectOneSchema } from '../../../generics/zmat-select/zmat-select-one/zmat-select-one.schema';
import { BehaviorSubject } from 'rxjs';
import { MatSelect } from '@angular/material';

export interface EstacaoModelo {
  id: number;
  nome: string;
}

@Component({
  selector: 'estacao-modelo-select',
  templateUrl: './estacao-modelo-select.component.html',
  styleUrls: ['./estacao-modelo-select.component.css']
})
export class EstacaoModeloSelectComponent implements OnInit {

  public data: EstacaoModelo[] = [
    {id: 1, nome: 'Automática'},
    {id: 2, nome: 'Convencional'}    
  ];

  private _value = new BehaviorSubject<any>({});  
  
  @Input()
  set value(value) {
    this._value.next(value);
  };

  get value() {
    return this._value.getValue();
  }

  @Output() changed: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatSelect, {static: true}) select: MatSelect;

  constructor() { }

  public itemChanged(event)
  {    
    this.changed.emit(event.value);
  }

  ngOnInit() {
    this._value.subscribe(value => { 
      this.select.value = value;
    })
  }

  ngOnDestroy() {
  }
}