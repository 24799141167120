import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorSelectComponent } from './sensor-select/sensor-select.component';
import { MaterialModule } from '@funceme/material';
import { FormsModule } from '@angular/forms';
import { SensorLegendaComponent } from './sensor-legenda/sensor-legenda.component';
import { SensorOperadorSelectComponent } from './sensor-operador-select/sensor-operador-select.component';
import { SensorModalComponent } from './sensor-modal/sensor-modal.component';

@NgModule({
  declarations: [
    SensorSelectComponent, 
    SensorLegendaComponent, 
    SensorOperadorSelectComponent, SensorModalComponent
  ],
  exports: [
    SensorSelectComponent,
    SensorLegendaComponent,
    SensorOperadorSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule
  ],
  entryComponents: [
    SensorModalComponent
  ]
})
export class SensorModule { }
