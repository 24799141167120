import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import * as IntervaloActions from './intervalo.actions';
import { IntervaloState } from './intervalo.reducer';
import { selectAllIntervalos, selectCurrentIntervalo, selectSoughtIntervalo, selectCurrentPeriodo, selectSoughtPeriodo } from './intervalo.selectors';

@Injectable({ providedIn: 'root' })
export class IntervaloFacade {
  
  allIntervalos$ = this.store.pipe(select(selectAllIntervalos));
  currentIntervalo$ = this.store.pipe(select(selectCurrentIntervalo));
  soughtIntervalo$ = this.store.pipe(select(selectSoughtIntervalo));
  currentPeriodo$ = this.store.pipe(select(selectCurrentPeriodo));
  soughtPeriodo$ = this.store.pipe(select(selectSoughtPeriodo));

  constructor(private store: Store<IntervaloState>, private actions$: ActionsSubject) {}

  selectIntervalo(itemId) {
    this.store.dispatch(new IntervaloActions.IntervaloSelected(itemId));
  }

  soughtIntervalo(itemId) {
    this.store.dispatch(new IntervaloActions.IntervaloSought(itemId));
  }

  selectPeriodo(value: any) {
    this.store.dispatch(new IntervaloActions.PeriodoSelected(value));
  }

  soughtPeriodo(value: any) {
    this.store.dispatch(new IntervaloActions.PeriodoSought(value));
  }
}
