import * as fromEstacaoSerie from './estacao-serie.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectEstacaoSerieState = createFeatureSelector<fromEstacaoSerie.EstacaoSerieState>('estacaoSerie');

export const selectEstacaoSerieIds = createSelector(
  selectEstacaoSerieState,
  fromEstacaoSerie.selectEstacaoSerieIds
);

export const selectEstacaoSerieEntities = createSelector(
  selectEstacaoSerieState,
  fromEstacaoSerie.selectEstacaoSerieEntities
);

export const selectAllEstacaoSeries = createSelector(
  selectEstacaoSerieState,
  fromEstacaoSerie.selectAllEstacaoSeries
);

export const isLoadingEstacaoSerie = createSelector(
  selectEstacaoSerieState,
  fromEstacaoSerie.isLoadingEstacaoSerie
); 