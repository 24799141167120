import { Component, OnInit, Input } from '@angular/core';
import { Estacao } from '../../../state/estacao/estacao.model';

@Component({
  selector: 'funceme-estacao-titulo',
  templateUrl: './estacao-titulo.component.html',
  styleUrls: ['./estacao-titulo.component.css']
})
export class EstacaoTituloComponent implements OnInit {

  @Input('estacao') estacao: Estacao;

  constructor() { }

  ngOnInit() {
  }
}