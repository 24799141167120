import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import * as GraficoActions from './grafico.actions';
import { GraficoState } from './grafico.reducer';
import { selectAllGraficos, selectCurrentGrafico} from './grafico.selectors';
import { Grafico, GraficoPayload } from './grafico.model';

@Injectable({ providedIn: 'root' })
export class GraficoFacade {
  allGraficos$     = this.store.pipe(select(selectAllGraficos));
  currentGrafico$  = this.store.pipe(select(selectCurrentGrafico));

  constructor(private store: Store<GraficoState>, private actions$: ActionsSubject) {}

  selectGrafico(estacaoSerie: Grafico) {
    this.store.dispatch(new GraficoActions.GraficoSelected(estacaoSerie));
  }

  pickGrafico(payload: GraficoPayload) {
    this.store.dispatch(new GraficoActions.PickGrafico(payload));
  }
}
