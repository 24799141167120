import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { ZmatMapSchema, PointSchema } from '../../../generics/zmat-map/zmat-map.schema';
import { Estacao } from '../../../state/estacao/estacao.model';
import { EstacaoFacade } from '../../../state/estacao/estacao.facade';
import { ZmatMapComponent } from '../../../generics/zmat-map/zmat-map/zmat-map.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'estacao-map',
  templateUrl: './estacao-map.component.html',
  styleUrls: ['./estacao-map.component.css']
})
export class EstacaoMapComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  public mapSchema: ZmatMapSchema;

  public data: Estacao[] = [];

  @ViewChild('_map_estacoes_', {static: true}) map: ZmatMapComponent;

  constructor(
    public facade: EstacaoFacade
  ) { 
    this.mapSchema = {
      center: [-39.6, -5.3],
      zoom: 8,
      marker: 'assets/img/pcd.png',
      parsePointData: (values: Estacao[]) => {
        return values.map(item => {
          return {
            lon: item.geom.coordinates[0],
            lat: item.geom.coordinates[1],
            name: item.nome,
            data: item
          }
        })
      },
      parseColor: (value: Estacao) => {
        if (value.modelo == 1)
          return '#0067C0';
        else
          return '#B81B22';
      },
      onClick: (point: PointSchema) => {
        //this.openModal(point.data)
        this.facade.select(point.data.id);
      }
    }
  }

  public init() {
    this.map.init();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.facade.all$.subscribe(values => this.data = values)
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
