import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { MunicipioActions, MunicipioActionTypes } from './municipio.actions';
import { Municipio } from './municipio.model';
import { ApiMetaData } from '../../generics/pagination.model';

/**
 * Interface to the part of the Store containing MunicipioState
 * and other information related to MunicipioData.
 */
export interface MunicipioState extends EntityState<Municipio> {
  selectedId: number;
  selectedIds: number[];
  loading: boolean;
  loaded_at: Date | null;
  meta_data: ApiMetaData;
  total: number,
  last_pagination: string
}

export const adapter: EntityAdapter<Municipio> = createEntityAdapter<Municipio>({
  selectId: (Municipio: Municipio) => Municipio.id,
});

export const initialState: MunicipioState = adapter.getInitialState({
  selectedId: null,
  selectedIds: [],
  loading: false,
  loaded_at: null,
  meta_data: null,
  total: null,
  last_pagination: null
});

export function municipioReducer(state = initialState, action: MunicipioActions): MunicipioState {

  switch (action.type) {
    
    case MunicipioActionTypes.Municipioselected: {
      return Object.assign({}, state, { selectedId: action.payload });
    }

    case MunicipioActionTypes.MunicipiosSelected: {
      return Object.assign({}, state, { selectedIds: state.selectedIds.concat(action.payload) });
    }

    case MunicipioActionTypes.MunicipiosUnselected: {
      return Object.assign({}, state, { 
        selectedMunicipioIds: state.selectedIds.filter(id => !action.payload.includes(id))
      });
    }

    case MunicipioActionTypes.LoadMunicipios: {
      return Object.assign({}, state, { loading: true });
    }

    case MunicipioActionTypes.MunicipiosLoaded: {
      return adapter.addAll(action.payload, Object.assign({}, state, { loading: false }));
    }

    case MunicipioActionTypes.PaginateMunicipios: {
      return Object.assign({}, state, { loading: true });
    }

    case MunicipioActionTypes.MunicipiosPaginated: {          
      return adapter.addAll(action.payload.data.list, Object.assign({}, state, { 
        meta_data: action.payload.meta, 
        total: action.payload.data.total_results,
        loading: false,
        loaded_at: new Date().toISOString(),
        last_pagination: JSON.stringify(action.payload.meta.request.query_params),
      }));  
    }

    case MunicipioActionTypes.MunicipiosRequestDone:
    case MunicipioActionTypes.MunicipiosRequestFailed: {
      return Object.assign({}, state, { loading: false });
    }
    
    default:
      return state;
  }
}

export const getSelectedMunicipioIds = (state: MunicipioState) => state.selectedIds;
export const getSelectedMunicipioId = (state: MunicipioState) => state.selectedId;
export const getMetaDataMunicipios = (state: MunicipioState) => state.meta_data;
export const getTotalMunicipios = (state: MunicipioState) => state.total;
export const getLoadingMunicipios = (state: MunicipioState) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of Municipio ids
export const selectMunicipioIds = selectIds;

// select the dictionary of Municipio entities
export const selectMunicipioEntities = selectEntities;

// select the array of Municipio
export const selectAllMunicipios = selectAll;

// select the total Municipio count
export const selectMunicipioTotal = selectTotal;
