import { Estacao } from "../estacao/estacao.model";

export interface Grafico {
    id: number;
    estacao: Estacao;
    series: any[]; //GraficoSerie[];
}

export const emptyGrafico: Grafico = {
    id: null,
    estacao: null,
    series: null
}

export interface GraficoPayload {
    estacao: Estacao;
    periodo: string;
}
