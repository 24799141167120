import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'estacao-serie-sensor-select',
  templateUrl: './estacao-serie-sensor-select.component.html',
  styleUrls: ['./estacao-serie-sensor-select.component.css']
})
export class EstacaoSerieSensorSelectComponent implements OnInit {

  public data = [
    {id: 'temp', nome: 'Temperatura'},
    {id: 'pluvio', nome: 'Pluviometria'},    
    {id: 'umidade', nome: 'Umidade Relativa'},   
    {id: 'pressao', nome: 'Pressão Atmosférica'},
    {id: 'orvalho', nome: 'Ponto de Orvalho'}
  ];

  private _value = new BehaviorSubject<any>({});  
  
  @Input()
  set value(value) {
    this._value.next(value);
  };

  get value() {
    return this._value.getValue();
  }

  @ViewChild(MatSelect, {static: true}) select: MatSelect;

  @Output() changed: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public itemChanged(event)
  { 
    this.changed.emit(event.value);
  }

  ngOnInit() {
    this._value.subscribe(value => {
      this.select.value = value;
    })
  }
}
