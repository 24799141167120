import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import decode from 'jwt-decode';
import { Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tap, map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})

export class AuthService 
{
    private callAuthService = new Subject<any>();
    callAuthService$ = this.callAuthService.asObservable();

    constructor(public jwtHelper: JwtHelperService, protected http: HttpClient, private router: Router) 
    { 
        
    }

    public isAuthenticated(): boolean 
    {
        const token = localStorage.getItem('access_token');

        if (token == 'null' || token == null)
            return false;

        return !this.jwtHelper.isTokenExpired(token);
    }

    public logout()
    {
        localStorage.setItem('access_token', null);
        localStorage.setItem('user_name', null);
        localStorage.setItem('user_id', null);  
    }
}