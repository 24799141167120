import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';

import { Sensor } from 'libs/core-pcd/src/lib/state/sensor/sensor.model';
import { zip } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorFacade } from '../../../state/sensor/sensor.facade';
import { DadoSensorFacade } from '../../../state/dado-sensor/dado-sensor.facade';
import { IntervaloFacade } from '../../../state/intervalo/intervalo.facade';
import { InstituicaoFacade } from '../../../state/instituicao/instituicao.facade';
import { UfFacade } from '../../../state/uf/uf.facade';
import { NotificationsService } from '../../../notifications/notifications.service';
import { select } from '@ngrx/store';
import { ConfigFacade } from '../../../state/config/config.facade';
import { ViewMode } from '../../../state/config/config.model';
import { EstacaoFacade } from '../../../state/estacao/estacao.facade';
import { DadoSensorPayload, emptyDadoSensorPayload } from '../../../state/dado-sensor/dado-sensor.model';
import {SensorOperadorSelectComponent} from '../../sensor/sensor-operador-select/sensor-operador-select.component'

@Component({
  selector: 'app-pcd-settings',
  templateUrl: './pcd-settings.component.html',
  styleUrls: ['./pcd-settings.component.css']
})
export class PcdSettingsComponent implements OnInit, OnDestroy, AfterViewInit{
  @ViewChild('child', {static: false})
  sensorOperadorelectComponent: SensorOperadorSelectComponent

  public sensores$: Observable<Sensor[]> = this.sensorFacade.all$;
  public currentSensor$: Observable<Sensor> = this.sensorFacade.current$;

  public first: boolean = true;
  public isMiniApp: boolean = false;
  // private dadoSensorPayload: DadoSensorPayload = emptyDadoSensorPayload;

  public disableButton = false;

  public instituicoesHabilitadas = [1, 39, 41, 42, 43, 116, 185, 186, 190];
  private currentInstituicoes;

  private subscription: Subscription;

  constructor(
    private sensorFacade: SensorFacade,
    private dadosSensorFacade: DadoSensorFacade,
    private intervaloFacade: IntervaloFacade,
    private instituicaoFacade: InstituicaoFacade,
    private ufFacade: UfFacade,
    private estacaoFacade: EstacaoFacade,
    private configFacade: ConfigFacade,
  ) {
      this.configFacade.viewMode$.subscribe(value => this.isMiniApp = (value == ViewMode.MINI));

      this.subscription = combineLatest(
        this.instituicaoFacade.currentList$,
        this.ufFacade.currentList$).subscribe(
          ([instituicoes,ufs]) => {
            this.currentInstituicoes = instituicoes;
            this.disableButton = (instituicoes.length > 0 || ufs.length > 0) ? false : true
        }
      )
  }
  ngAfterViewInit(): void {
  }

  ngOnInit() { }

  ngOnDestroy()
  {
    this.subscription.unsubscribe();
  }

  public loadDadosSendor()
  {
    if (this.currentInstituicoes.length == 0) 
      this.instituicaoFacade.selectMany(this.instituicoesHabilitadas);
    
    this.sensorFacade.changeOperador(this.sensorOperadorelectComponent.currentOperador);

    this.dadosSensorFacade.load();
  }

  setParametersByQueryParams(params)
  {
    this.clearSelecteds();

    this.sensorFacade.select(params['sensor']);
    this.intervaloFacade.selectIntervalo(params['intervalo']);
    this.intervaloFacade.selectPeriodo(params['periodo']);

    params['instituicao'].split(',').map(instituicao => this.instituicaoFacade.select(instituicao));

    if (params['uf'])
      params['uf'].split(',').map(uf => this.ufFacade.selectMany([uf]));

    if (params['estacao'])
      this.estacaoFacade.pick(params['estacao']);

    this.dadosSensorFacade.load(true);
  }

  setParametersByDefault(sensors, instituicoes, Ufs)
  {
    let sensor = sensors.find(sensor => sensor.id == sensors[0].id)
    if(!sensor) return
    this.sensorFacade.select(sensor.id);

    let instituicao = instituicoes.find(instituicao => instituicao.id == instituicoes[0].id)
    this.instituicaoFacade.selectMany([instituicao.id]);

    let uf = Ufs.find(uf => uf.id == 'CE')
    this.ufFacade.selectMany([uf.id])

    this.intervaloFacade.allIntervalos$.subscribe(intervalos => {
      let intervalo = intervalos.find(intervalo => intervalo.id == intervalos[0].id)
      this.intervaloFacade.selectIntervalo(intervalo.id);
      this.intervaloFacade.selectPeriodo('24h');

      this.dadosSensorFacade.load();
    })
  }

  clearSelecteds(){
  
    let selectInstituicao = [];
    this.instituicaoFacade.currentList$.subscribe(
      instituicoes => selectInstituicao = instituicoes
    )
    selectInstituicao.forEach(element => {
      this.instituicaoFacade.unselect(element.id)
    });

    let selectUfs = [];
    this.ufFacade.currentList$.subscribe(
      values => selectUfs = values
    )
    selectUfs.forEach(element => {
      this.ufFacade.unselectMany([element.id])
    });
    this.disableButton = true;
  }
}
