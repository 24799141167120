import * as fromInstituicao from './instituicao.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { emptyInstituicao, Instituicao } from './instituicao.model';

export const selectInstituicaoState = createFeatureSelector<fromInstituicao.InstituicaoState>('instituicao');

export const selectInstituicaoIds = createSelector(
  selectInstituicaoState,
  fromInstituicao.selectInstituicaoIds
);

export const selectInstituicaoEntities = createSelector(
  selectInstituicaoState,
  fromInstituicao.selectInstituicaoEntities
);

export const selectAllInstituicoes = createSelector(
  selectInstituicaoState,
  fromInstituicao.selectAllInstituicoes
);

export const selectCurrentInstituicaoId = createSelector(
  selectInstituicaoState,
  fromInstituicao.getSelectedInstituicaoId
);

export const selectCurrentInstituicao = createSelector(
  selectInstituicaoEntities,
  selectCurrentInstituicaoId,
  (instituicaoEntities, instituicaoId) => {
    return instituicaoId ? instituicaoEntities[instituicaoId] : emptyInstituicao;
  }
);

export const selectCurrentInstituicaoIds = createSelector(
  selectInstituicaoState,
  fromInstituicao.getSelectedInstituicaoIds
);

export const selectCurrentInstituicoes = createSelector(
  selectInstituicaoEntities,
  selectCurrentInstituicaoIds,
  (instituicaoEntities, instituicaoIds) => {

    let list: Instituicao[] = [];

    instituicaoIds.forEach(element => {
      list.push(instituicaoEntities[element]);
    });

    return list;
  }
);

export const selectSoughtInstituicaoIds = createSelector(
  selectInstituicaoState,
  fromInstituicao.getSoughtInstituicaoIds
);

export const selectSoughtInstituicoes = createSelector(
  selectInstituicaoEntities,
  selectSoughtInstituicaoIds,
  (instituicaoEntities, instituicaoIds) => {

    let list: Instituicao[] = [];

    instituicaoIds.forEach(element => {
      list.push(instituicaoEntities[element]);
    });

    return list;
  }
);

export const selectMetaDataInstituicoes = createSelector(
  selectInstituicaoState,
  fromInstituicao.getMetaDataInstituicoes
);

export const selectTotalInstituicoes = createSelector(
  selectInstituicaoState,
  fromInstituicao.getTotalInstituicoes
); 

export const selectLoadingInstituicoes = createSelector(
  selectInstituicaoState,
  fromInstituicao.getLoadingInstituicoes
); 

