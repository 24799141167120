import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { EstacaoSerie } from './estacao-serie.model';
import { EstacaoSerieHorarioService } from './estacao-serie-horario.service';
import { EstacaoSerieDiarioService } from './estacao-serie-diario.service';
import { EstacaoSerieMensalService } from './estacao-serie-mensal.service';

import {
  PickEstacaoSeries,
  EstacaoSerieActionTypes,
  EstacaoSeriePicked,
} from './estacao-serie.actions';
import { EstacaoSerieState } from './estacao-serie.reducer';
import { getEstacaoSerieIdFromPayload } from './estacao-serie.adapter';

@Injectable({providedIn: 'root'})
export class EstacaoSerieEffects 
{
  //@Effect() effect$ = this.actions$.ofType(EstacaoSeriesActionTypes.EstacaoSeriesAction);

  @Effect()
  pickEstacaoSeries$ = this.dataPersistence.fetch(EstacaoSerieActionTypes.PickEstacaoSeries, {
    run: (action: PickEstacaoSeries, state: any) => {

      let estacaoSerieId = getEstacaoSerieIdFromPayload(action.payload);

      if (this.isValid(state.estacaoSerie.entities[estacaoSerieId]))
        return new EstacaoSeriePicked(state.estacaoSerie.entities[estacaoSerieId]);

      return this.getService(action.payload.periodo)
        .pick(action.payload.estacaoId, action.payload.sensores, action.payload.periodo, action.payload.data_inicio, action.payload.data_fim)
        .pipe(
          map((res: EstacaoSerie[]) => new EstacaoSeriePicked({
            id: estacaoSerieId, 
            estacao: action.payload.estacaoId,
            queue_in: res['meta']['cache']['queue_in'],
            expires_in: res['meta']['cache']['expires_in'],
            cached_at: new Date(res['meta']['cache']['cached_at']['date']),
            estacaoSeries: res['data']['list']
          }))
        );
    },

    onError: (action: PickEstacaoSeries, error) => {
      console.error('Error', error);
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<EstacaoSerieState>,
    private estacaoSerieHorarioService: EstacaoSerieHorarioService,
    private estacaoSerieDiarioService: EstacaoSerieDiarioService,
    private estacaoSerieMensalService: EstacaoSerieMensalService,
  ) {}

  private isValid(estacaoSerie: EstacaoSerie) {
    if (!estacaoSerie)
      return false;

    let lowerTime = (estacaoSerie.queue_in < estacaoSerie.expires_in)? estacaoSerie.queue_in : estacaoSerie.expires_in;

    let now = new Date;
    let time = new Date(estacaoSerie.cached_at.getTime());
    time.setSeconds(time.getSeconds() + lowerTime);

    return now.getTime() < time.getTime();
  }

  private getService(periodo){
    if (periodo.includes('h') || periodo.includes('d'))
      return this.estacaoSerieHorarioService

    else if (periodo.includes('a'))
      return this.estacaoSerieMensalService

    else
      return this.estacaoSerieDiarioService
  }
}
