import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'estacao-serie-intervalo-select',
  templateUrl: './estacao-serie-intervalo-select.component.html',
  styleUrls: ['./estacao-serie-intervalo-select.component.css']
})
export class EstacaoSerieIntervaloSelectComponent implements OnInit {

  public data: any[];

  private hourly = [    
    {id: '0d', nome: 'Dia selecionado'},    
    {id: '1d', nome: '- 1 dia'},            
    {id: '3d', nome: '- 3 dias'},
    {id: '5d', nome: '- 5 dias'},
    {id: '7d', nome: '- 7 dias'},
  ];

  private daily = [
    {id: '0m', nome: 'Mês selecionado'},
    {id: '1m', nome: '- 1 mês'},    
    {id: '2m', nome: '- 2 meses'},   
    {id: '3m', nome: '- 3 meses'},
    {id: '6m', nome: '- 6 meses'}    
  ];

  private monthly = [
    {id: '0a', nome: 'Ano selecionado'},
    {id: '1a', nome: '- 1 ano'},    
    {id: '2a', nome: '- 2 anos'},   
    {id: '3a', nome: '- 3 anos'},
    {id: '5a', nome: '- 5 anos'},
  ];

  private _value = new BehaviorSubject<any>({});  
  private _option = new BehaviorSubject<any>({});  
  
  @Input()
  set value(value) {
    this._value.next(value);
  };

  get value() {
    return this._value.getValue();
  }

  @Input()
  set option(value) {
    this._option.next(value);
  };

  get option() {
    return this._option.getValue();
  }

  @ViewChild(MatSelect, {static: true}) select: MatSelect;

  @Output() changed: EventEmitter<any> = new EventEmitter();

  constructor() {
    this._option.subscribe(value => {
      switch(value) {
        case 'hourly': 
          this.data = this.hourly;
          break;
        case 'daily': 
          this.data = this.daily;
          break;
        case 'monthly': 
          this.data = this.monthly;
          break;        
      }
    });
   }

  public itemChanged(event)
  { 
    this.changed.emit(event.value);
  }

  ngOnInit() {
    this._value.subscribe(value => {
      this.select.value = value;
    })
  }

}
