import { Action } from '@ngrx/store';
import { Grafico, GraficoPayload } from './grafico.model';

export enum GraficoActionTypes {
  GraficosAction   = '[Graficos] Action',
  GraficoSelected  = '[Graficos] Selected',
  PickGrafico     = '[Graficos] Pick'
}

export class Graficos implements Action {
  readonly type = GraficoActionTypes.GraficosAction;
}

export class GraficoSelected implements Action {
  readonly type = GraficoActionTypes.GraficoSelected;
  constructor(public payload: Grafico) { }
}

export class PickGrafico implements Action {
  readonly type = GraficoActionTypes.PickGrafico;
  constructor(public payload: GraficoPayload) { }
}

export type GraficoActions = Graficos
  | GraficoSelected
  | PickGrafico
;
