import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { DadoSensorFacade } from '@funceme/core-pcd';
import { ConfigFacade } from 'libs/core-pcd/src/lib/state/config/config.facade';
import { ViewMode } from 'libs/core-pcd/src/lib/state/config/config.model';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit {

  @ViewChild('sidenav',null) sidenav: MatSidenav;

  public fullscreen: boolean = false;
  public viewMode: ViewMode = ViewMode.LARGE;

  constructor(private configFacade: ConfigFacade,private router: Router) {
    this.configFacade.viewMode$.subscribe(value => this.fullscreen = this.fullscreen || (value == ViewMode.MINI));
    this.configFacade.fullscreen$.subscribe(value => this.fullscreen = value);

    const navEndEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navEndEvents$.subscribe((event: NavigationEnd) => {
        gtag('config', 'UA-7298747-8',{
            'page_path': event.urlAfterRedirects
        });
    })

  }

  ngOnInit() {
  }

  close() 
  {
    this.sidenav.close();
  }

  link(url:string) {
    this.close();
    this.router.navigate([url]);
  }
}