import { Action } from '@ngrx/store';

export enum IntervaloActionTypes {
  IntervalosAction = '[Intervalo] Action',
  IntervaloSelected = '[Intervalo] Intervalo',
  IntervaloSought = '[Intervalo] Intervalo Sought',
  PeriodoSelected = '[Intervalo] Periodo Selected',
  PeriodoSought = '[Intervalo] Periodo Sought'
}

export class Intervalos implements Action {
  readonly type = IntervaloActionTypes.IntervalosAction;
}

export class IntervaloSelected implements Action {
  readonly type = IntervaloActionTypes.IntervaloSelected;
  constructor(public payload) { }
}

export class IntervaloSought implements Action {
  readonly type = IntervaloActionTypes.IntervaloSought;
  constructor(public payload) { }
}

export class PeriodoSelected implements Action {
  readonly type = IntervaloActionTypes.PeriodoSelected;
  constructor(public payload) { }
}

export class PeriodoSought implements Action {
  readonly type = IntervaloActionTypes.PeriodoSought;
  constructor(public payload) { }
}

export type IntervaloActions = Intervalos
  | IntervaloSelected
  | IntervaloSought
  | PeriodoSelected
  | PeriodoSought
;
