import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UfSelectComponent } from './uf-select/uf-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@funceme/material';

@NgModule({
  declarations: [UfSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  exports: [UfSelectComponent]
})
export class UfModule { }
