import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstituicaoSelectComponent } from './instituicao-select/instituicao-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@funceme/material';
import { InstituicaoZmatSelectComponent } from './instituicao-zmat-select/instituicao-zmat-select.component';
import { ZmatSelectModule } from '../../generics/zmat-select/zmat-select.module';

@NgModule({
  declarations: [
    InstituicaoSelectComponent, 
    InstituicaoZmatSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    ZmatSelectModule
  ],
  exports: [
    InstituicaoSelectComponent,
    InstituicaoZmatSelectComponent
  ]
})
export class InstituicaoModule { }
