import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ConfigActions, ConfigActionTypes } from './config.actions';
import { Config, ViewMode } from './config.model';

export interface ConfigState extends EntityState<Config> {
  viewMode: ViewMode;
  fullscreen: boolean | null;
}

export const adapter: EntityAdapter<Config> = createEntityAdapter<Config>();

export const initialState: ConfigState = adapter.getInitialState({
  viewMode: ViewMode.LARGE,
  fullscreen: false
});

export function configReducer(state = initialState, action: ConfigActions): ConfigState {

  switch (action.type) {
    case ConfigActionTypes.ToggleFullscreenAction: {
      return Object.assign({}, state, { fullscreen: !state.fullscreen });;
    }

    case ConfigActionTypes.MiniAppSelected: {
      return Object.assign({}, state, { viewMode: ViewMode.MINI });
    }

    case ConfigActionTypes.LargeAppSelected: {
      return Object.assign({}, state, { viewMode: ViewMode.LARGE });;
    }

    default:
      return state;
  }
}

export const getFullscreenConfig = (state: ConfigState)   => state.fullscreen;
export const getViewModeConfig   = (state: ConfigState)   => state.viewMode;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of dado-sensor ids
export const selectConfigIds = selectIds;

// select the dictionary of dado-sensor entities
export const selectConfigEntities = selectEntities;

// select the array of dado-sensor
export const selectAllConfig = selectAll;

// select the total dado-sensor count
export const selectConfigTotal = selectTotal;