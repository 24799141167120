import * as fromIntervalo from './config.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectConfigState = createFeatureSelector<fromIntervalo.ConfigState>('config');

export const selectViewMode = createSelector(
  selectConfigState,
  fromIntervalo.getViewModeConfig
);

export const selectFullscreen = createSelector(
  selectConfigState,
  fromIntervalo.getFullscreenConfig
);