import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Intervalo } from '../../../state/intervalo/intervalo.model';
import { IntervaloFacade } from '../../../state/intervalo/intervalo.facade';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from './date.adapter';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-intervalo-select',
  templateUrl: './intervalo-select.component.html',
  styleUrls: ['./intervalo-select.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class IntervaloSelectComponent implements OnInit {

  showTestAlert(resp) {
    Swal.fire({
      title: "Aviso",
      html: resp,
      icon: "warning",
      confirmButtonText: "Ok"
    });
  };

  public intervalos$: Observable<Intervalo[]>;
  public currentIntervaloId: string;
  public currentPeriodo: string;
  public selectedDate: string;

  public today: Date = new Date;

  constructor(private intervaloFacade: IntervaloFacade) { 
    this.intervalos$ = this.intervaloFacade.allIntervalos$;

    this.intervaloFacade.currentPeriodo$.subscribe(
      currentPeriodo => {
        if (currentPeriodo && currentPeriodo.length > 3){
          currentPeriodo = currentPeriodo.split(',')[0]
          let date = currentPeriodo.split('-');
          this.currentPeriodo = new Date(Number(date[0]), Number(date[1])-1, Number(date[2])).toISOString()
          return
        }
        this.currentPeriodo = currentPeriodo
      }
    );

    this.intervaloFacade.currentIntervalo$.subscribe(
      currentIntervalo => this.currentIntervaloId = currentIntervalo.id
    );
  }

  ngOnInit() {
    
  }

  public changedIntervalo(event) {
    this.intervaloFacade.selectPeriodo(null);
    this.intervaloFacade.selectIntervalo(event.value);
    if (this.currentIntervaloId == '1h') {
      this.intervaloFacade.selectPeriodo('24h');

    } else if (this.currentIntervaloId == '1d'){
      this.intervaloFacade.selectPeriodo('0d');

    } else if (this.currentIntervaloId == '1m'){
      this.intervaloFacade.selectPeriodo(this.today.toISOString().slice(0,10));

    } else if (this.currentIntervaloId == 'l'){
      let date = new Date()
      date.setDate(this.today.getDate() - 1)
      this.intervaloFacade.selectPeriodo(
        date.toISOString().slice(0,10)+','+this.today.toISOString().slice(0,10)
      );
    }
  }

  public changedPeriodo(event) {
    this.intervaloFacade.selectPeriodo(event.value);
  }

  public chosenMonthHandler(event:Date, picker){
    this.intervaloFacade.selectPeriodo(event.toISOString().slice(0,10));
    picker.close();
  }

  public manualChosenMonthHandler(value:string, picker){
    this.checkManualInputDate(value);
    picker.close();
  }
  
  private checkManualInputDate(value){
    let selectedDate: Date;
    const todayMonth = this.today.getMonth() +1;
    const todayYear = this.today.getFullYear();
    if (value.includes('/')) {
      const parts = value.split('/');
      if (parts.length === 2) {
        const month = parts[0].trim();
        const year = parts[1].trim();
        selectedDate = this.getManualInputDate(month, year, todayMonth, todayYear)
        this.intervaloFacade.selectPeriodo(selectedDate.toISOString().slice(0,10));    
    }
  }
    else {
      selectedDate = new Date(todayYear, todayMonth, 1);
      this.intervaloFacade.selectPeriodo(selectedDate.toISOString().slice(0,10)); 
      this.showTestAlert("A data deve ser no padrão '" + this.convertNumberToMonthName(todayMonth)+ "/" + todayYear + "'");
    }
  }
  
  private getManualInputDate(month: string, year:string, todayMonth, todayYear): Date
  {  
    const monthNumber = this.convertMonthNameToNumber(month);

    if(isNaN(monthNumber) || isNaN(parseInt(year))){      
      this.showTestAlert("Digite a data corretamente! Ex: "+ this.convertNumberToMonthName(todayMonth)+ "/" + todayYear);
      return new Date(this.today.getFullYear(), todayMonth -1, 1);
      }
    else if(monthNumber > todayMonth || parseInt(year) > todayYear){  
      this.showTestAlert("Data máxima permitida: " + this.convertNumberToMonthName(todayMonth) + "/" + todayYear);      
      return new Date(this.today.getFullYear(), todayMonth -1, 1)
    }
    else if(parseInt(year)<1911){ 
      this.showTestAlert("Data mínima permitida: Janeiro/1911");
      return new Date(this.today.getFullYear(), todayMonth -1, 1); 
    }      
    else{
    return new Date(parseInt(year), monthNumber - 1, 1);
    }
  }    


  private convertMonthNameToNumber(monthName: string): number {
    const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril',
      'Maio', 'Junho', 'Julho', 'Agosto',
      'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    const index = months.findIndex(m => m.toLowerCase() === monthName.toLowerCase());
    return index !== -1 ? index + 1 : NaN;
  }

  private convertNumberToMonthName(monthNumber: number): string {
    const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril',
      'Maio', 'Junho', 'Julho', 'Agosto',
      'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1];
    }
  }
}

