import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@funceme/material';
import { PcdSettingsComponent } from './pcd-settings/pcd-settings.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SensorModule } from '../sensor/sensor.module';
import { InstituicaoModule } from '../instituicao/instituicao.module';
import { IntervaloModule } from '../invervalo/intervalo.module';
import { PcdTituloComponent } from './pcd-titulo/pcd-titulo.component';
import { UfModule } from '../uf/uf.module';

@NgModule({
  declarations: [
    PcdSettingsComponent,
    PcdTituloComponent
  ],
  exports: [
    PcdSettingsComponent,
    PcdTituloComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    SensorModule,
    InstituicaoModule,
    UfModule,
    IntervaloModule
  ]
})
export class PcdModule { }
