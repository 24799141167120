import { Action } from '@ngrx/store';
import { Uf } from './uf.model';
import { Pagination, ApiResponse } from '../../generics/pagination.model';

export enum UfActionTypes {
  UfsAction         = '[Ufs] Action',
  LoadUfs           = '[Ufs] Load Data',
  UfsLoaded         = '[Ufs] Data Loaded',
  UfSelected        = '[Uf] Selected',
  UfsSelected       = '[Ufs] Selected',
  UfsUnselected     = '[Ufs] Unselected',
  PaginateUfs       = '[Ufs] Paginate',
  UfsPaginated      = '[Ufs] Paginated',
  UfsRequestDone    = '[Ufs] RequestDone',
  UfsRequestFailed  = '[Ufs] Request Failed',
  UfsSought         = '[Ufs] Sought',
}

export class Ufs implements Action {
  readonly type = UfActionTypes.UfsAction;
}

export class LoadUfs implements Action {
  readonly type = UfActionTypes.LoadUfs;
  constructor(public reset: boolean) { }
}

export class UfsLoaded implements Action {
  readonly type = UfActionTypes.UfsLoaded;
  constructor(public payload: Uf[]) { }
}

export class UfSelected implements Action {
  readonly type = UfActionTypes.UfSelected;
  constructor(public payload: string) { }
}

export class UfsSelected implements Action {
  readonly type = UfActionTypes.UfsSelected;
  constructor(public payload: string[]) { }
}

export class UfsUnselected implements Action {
  readonly type = UfActionTypes.UfsUnselected;
  constructor(public payload: string[]) { }
}

export class PaginateUfs implements Action {
  readonly type = UfActionTypes.PaginateUfs;
  constructor(public payload: Pagination) { }
}

export class UfsPaginated implements Action {
  readonly type = UfActionTypes.UfsPaginated;
  constructor(public payload: ApiResponse<Uf>) { }
}

export class UfsRequestDone implements Action {
  readonly type = UfActionTypes.UfsRequestDone;
  constructor() { }
}

export class UfsRequestFailed implements Action {
  readonly type = UfActionTypes.UfsRequestFailed;
  constructor() { }
}

export class UfsSought implements Action {
  readonly type = UfActionTypes.UfsSought;
  constructor(public payload: string[]) { }
}

export type UfActions = Ufs
  | LoadUfs
  | UfsLoaded  
  | UfSelected
  | UfsSelected
  | UfsUnselected
  | PaginateUfs
  | UfsPaginated
  | UfsRequestDone
  | UfsRequestFailed
  | UfsSought
;