export enum ViewMode {
    MINI = 0,
    LARGE = 1
}

export interface Config {
    viewMode: ViewMode;
    fullscreen: boolean;
}

export const emptyConfig: Config = {
    viewMode: ViewMode.LARGE,
    fullscreen: false
}