import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';

import {
  PickGrafico,
  GraficoActionTypes,
} from './grafico.actions';
import { GraficoState } from './grafico.reducer';

@Injectable({providedIn: 'root'})
export class GraficoEffects 
{
  //@Effect() effect$ = this.actions$.ofType(GraficosActionTypes.GraficosAction);

  @Effect()
  pickGraficos$ = this.dataPersistence.fetch(GraficoActionTypes.PickGrafico, {
    run: (action: PickGrafico, state: GraficoState) => {

    },

    onError: (action: PickGrafico, error) => {
      console.error('Error', error);
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<GraficoState>,
  ) {}
}
