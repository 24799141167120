import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NotificationsService } from '../../notifications/notifications.service';
import { Sensor } from './sensor.model';
import { Pagination, ApiResponse, paginationToString } from '../../generics/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class SensorService {
  
  model = 'rest/pcd/sensor';

  constructor(
    private http: HttpClient,
    private ns: NotificationsService
  ) { }

  getUrl() {
    return `${environment.apiEndpoint}${this.model}`;
  }

  getUrlForId(id) {
    return `${this.getUrl()}/${id}`;
  }

  all() {
    return this.http.get<Sensor[]>(this.getUrl() + '?limit=no&orderBy=id');
  }

  load(id) {
    return this.http.get<Sensor>(this.getUrlForId(id));
  }  

  paginate(pagination: Pagination) {
    return this.http.get<ApiResponse<Sensor>>(this.getUrl() + '?' + paginationToString(pagination));
  }
}
