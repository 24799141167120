export interface Sensor {
    id: number;
    descricao: string;
    unidade: string;
}

export const emptySensor: Sensor = {
    id: null,
    descricao: '',
    unidade: ''
}
