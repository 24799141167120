import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Pagination, getPaginationDefault } from '../../../generics/pagination.model';
import { InstituicaoFacade } from '../../../state/instituicao/instituicao.facade';
import { MunicipioFacade } from '../../../state/municipio/municipio.facade';
import { EstacaoFacade } from '../../../state/estacao/estacao.facade';
import { MatTabChangeEvent } from '@angular/material';
import { EstacaoMapComponent } from '../estacao-map/estacao-map.component';
import { EstacaoLgridComponent } from '../estacao-lgrid/estacao-lgrid.component';

@Component({
  selector: 'estacao-index',
  templateUrl: './estacao-index.component.html',
  styleUrls: ['./estacao-index.component.css']
})
export class EstacaoIndexComponent implements OnInit {

  private subscriptions = new Subscription();
  
  public estacao: string;
  public id: number;
  public statusFilter: boolean = false;

  private pagination: Pagination = getPaginationDefault();

  private cleaning: boolean = false;
  private limitDefault: number = 10;

  @Input() showColumns: string[] = [];
  @Input() showFilters: string[] = [];

  public estacaoModeloSelectedId: number;
  public estacaoTipoSelectedId: number;

  @ViewChild('map_estacoes',   {static: true}) map: EstacaoMapComponent;
  @ViewChild('grid_estacoes',  {static: true}) grid: EstacaoLgridComponent;

  constructor(
    private facade: EstacaoFacade,
    private instituicaoFacade: InstituicaoFacade,
    private municipioFacade: MunicipioFacade
  ) { }

  ngOnInit() {

    this.instituicaoFacade.load();

    this.subscriptions.add(
      this.facade.all$.subscribe(values => { 
        this.cleaning = false;
      })
    ).add(
      this.facade.metaData$.subscribe(value => {
        if (!value) return;
        this.pagination = value.request.query_params;     
        this.estacao = value.request.query_params.filter_list['nome-lk'];
        this.id =  value.request.query_params.filter_list['id'];
        this.estacaoModeloSelectedId = parseInt(value.request.query_params.filter_list['modelo']);
        this.estacaoTipoSelectedId = parseInt(value.request.query_params.filter_list['tipo_estacao']);
      })
    ).add(
      this.instituicaoFacade.current$.subscribe(value => {
        if (!value || this.cleaning) return;
        this.pagination.filter_list['instituicao'] = value.id;
        this.pagination.paginator.page = 1;

        this.facade.paginate(this.pagination);
      })
    ).add(
      this.municipioFacade.current$.subscribe(value => {
        if (!value || this.cleaning) return;
        this.pagination.filter_list['municipio'] = value.id;
        this.pagination.paginator.page = 1;

        this.facade.paginate(this.pagination);
      })
    );  
  }

  tabChanged($event: MatTabChangeEvent) {

    //this.data = [];

    if ($event.tab.textLabel == "Mapa") {
      this.pagination.paginator.limit = 9999999;
      this.limitDefault = 9999999;
      this.facade.paginate(this.pagination);
      this.map.init();         
      
    } else if ($event.tab.textLabel == "Tabela") {
      this.pagination.paginator.limit = 10;
      this.limitDefault = 10;
      this.facade.paginate(this.pagination);
    }
  }

  estacaoModeloChanged($event) 
  {
    if ($event)
      this.pagination.filter_list['modelo'] = $event;
    else 
      delete this.pagination.filter_list['modelo'];
    
    this.pagination.paginator.page = 1;
    this.pagination.paginator.limit = this.limitDefault;

    this.facade.paginate(this.pagination);
  }

  estacaoTipoChanged($event) 
  {
    if ($event)
      this.pagination.filter_list['tipo_estacao'] = $event;
    else 
      delete this.pagination.filter_list['tipo_estacao'];

    this.pagination.paginator.page = 1;
    this.pagination.paginator.limit = this.limitDefault;

    this.facade.paginate(this.pagination);
  }

  cleanFilters()
  {
    this.cleaning = true;
    
    this.pagination.filter_list = [];

    this.instituicaoFacade.select(null);
    this.municipioFacade.select(null);

    this.estacao = '';
    this.estacaoModeloSelectedId = null;
    this.estacaoTipoSelectedId = null;

    this.pagination.paginator.page = 1;    

    this.facade.paginate(this.pagination);
  }

  applyEstacaoFilter($event) 
  {
    if ($event.code == 'NumpadEnter' || $event.code == 'Enter') {
      this.pagination.paginator.page = 1;
      this.pagination.filter_list['nome-lk'] = $event.target.value;
      this.facade.paginate(this.pagination);
    }
  }

  applyIdFilter($event) 
  {
    if ($event.code == 'NumpadEnter' || $event.code == 'Enter') {
      this.pagination.paginator.page = 1;
      this.pagination.filter_list['id'] = $event.target.value;
      this.facade.paginate(this.pagination);
    }
  }  
}
