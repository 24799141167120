import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@funceme/material';
import { DynamicOutlet } from './dynamic-outlet';
import { ZmatLGridTableComponent } from './zmat-lgrid-table/zmat-lgrid-table.component';
import { ZmatLGridPaginationComponent } from './zmat-lgrid-pagination/zmat-lgrid-pagination.component';
import { ZmatLGridTdTextComponent } from './zmat-lgrid-td-text/zmat-lgrid-td-text.component';
import { ZmatLGridTdBoolComponent } from './zmat-lgrid-td-bool/zmat-lgrid-td-bool.component';
import { ZmatLGridTdActionComponent } from './zmat-lgrid-td-action/zmat-lgrid-td-action.component';

@NgModule({
  declarations: [
    ZmatLGridTableComponent, 
    ZmatLGridPaginationComponent, 
    ZmatLGridTdTextComponent,      
    ZmatLGridTdBoolComponent, 
    ZmatLGridTdActionComponent,
    DynamicOutlet,
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    ZmatLGridTableComponent,
    ZmatLGridPaginationComponent,
    ZmatLGridTdTextComponent,
    ZmatLGridTdBoolComponent,
    ZmatLGridTdActionComponent
  ],
  entryComponents: [
    ZmatLGridTdTextComponent, 
    ZmatLGridTdBoolComponent,
    ZmatLGridTdActionComponent
  ]
})
export class ZmatLGridModule { }
