import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule, ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { NxModule } from '@nrwl/nx';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { reducers } from '../state';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EstacaoEffects } from './estacao/estacao.effects';
import { SensorEffects } from './sensor/sensor.effects';
import { InstituicaoEffects } from './instituicao/instituicao.effects';
import { DadoSensorEffects } from './dado-sensor/dado-sensor.effects';
import { EstacaoSerieEffects } from './estacao-serie/estacao-serie.effects';
import { UfEffects } from './uf/uf.effects';
import { GraficoEffects } from './grafico/grafico.effects';
import { TempoAgoraEffects } from './tempo-agora/tempo-agora.effects';
import { MunicipioEffects } from './municipio/municipio.effects';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  // return localStorageSync({keys: ['todos'],rehydrate: true})(reducer);
  return localStorageSync({keys: ['estacao','sensor','instituicao','intervalo','dadosSensor','estacaoSerie','uf', 'config', 'municipio'],rehydrate: true})(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    NxModule.forRoot(),
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreDevtoolsModule.instrument({ maxAge: 10 }),
    EffectsModule.forRoot([
      EstacaoEffects,
      SensorEffects,
      InstituicaoEffects,
      DadoSensorEffects,
      EstacaoSerieEffects,
      UfEffects,
      GraficoEffects,
      TempoAgoraEffects,
      MunicipioEffects
    ]),
  ],
  declarations: []
})
export class StateModule { }