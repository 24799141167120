import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { Estacao } from './estacao.model';
import { EstacaoService } from './estacao.service';

import {
  LoadEstacoes, 
  EstacoesLoaded,
  EstacaoActionTypes,
  PickEstacao,
  EstacaoSelected,
  EstacaoAdded,
  PaginateEstacoes,
  EstacoesRequestDone,
  EstacoesPaginated,
  EstacoesRequestFailed,
} from './estacao.actions';
import { EstacaoState } from './estacao.reducer';
import { EstacaoFacade } from './estacao.facade';
import { NotificationsService } from '../../notifications/notifications.service';
import { paginationEquals, ApiResponse } from '../../generics/pagination.model';

@Injectable({providedIn: 'root'})
export class EstacaoEffects 
{
  //@Effect() effect$ = this.actions$.ofType(EstacoesActionTypes.EstacoesAction);

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<EstacaoState>,
    private service: EstacaoService,
    private facade: EstacaoFacade,
    private notifyError: NotificationsService
  ) {}

  @Effect()
  loadEstacoes$ = this.dataPersistence.fetch(EstacaoActionTypes.LoadEstacoes, {
    run: (action: LoadEstacoes, state: EstacaoState) => {

      return this.service
        .all(action.payload.instituicaoIds, action.payload.modelos, action.payload.uf)
        .pipe(
          map((res: Estacao[]) => new EstacoesLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadEstacoes, error) => {
      this.notifyError.emit(error.message);
    }
  });

  @Effect()
  paginateEstacoes$ = this.dataPersistence.fetch(EstacaoActionTypes.PaginateEstacoes, {
    run: (action: PaginateEstacoes, state: any) => {

      if (state.estacao.last_pagination && paginationEquals(action.payload, JSON.parse(state.estacao.last_pagination))) 
        return new EstacoesRequestDone;

      return this.service
        .paginate(action.payload)
        .pipe(
          map((res: ApiResponse<Estacao>) => new EstacoesPaginated(res))
        )
    },

    onError: (action: PaginateEstacoes, error) => {
      this.notifyError.emit(error.message);
      return new EstacoesRequestFailed;
    }
  });

  @Effect()
  pickEstacao$ = this.dataPersistence.fetch(EstacaoActionTypes.PickEstacao, {
    run: (action: PickEstacao, state: any) => {

      let pickingEstacao: number;

      this.facade.picking$.subscribe(
        value => pickingEstacao = value
      );

      if (!pickingEstacao) return;
      
      if (state.estacao.entities[pickingEstacao])
        return new EstacaoSelected(state.estacao.entities[pickingEstacao].id)

      return this.service
        .pick(pickingEstacao)
        .pipe(
          map((res) => new EstacaoAdded(res['data']))
        )
    },

    onError: (action: PickEstacao, error) => {
      this.notifyError.emit(error.message);
    }
  });
}