import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from '@funceme/material';
import { CoreDataModule, MapModule, PcdModule, SensorModule, EstacaoModule, IntervaloModule, InstituicaoModule } from '@funceme/core-pcd';
import { MiniComponent } from './mini/mini.component';
import { ReportsComponent } from './reports/reports.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TempoAgoraMiniComponent } from './tempo-agora-mini/tempo-agora-mini.component';
import { ContatoComponent } from './contato/contato.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { EstacoesComponent } from './estacoes/estacoes.component';

@NgModule({
  declarations: [HomeComponent, MiniComponent, ReportsComponent, TempoAgoraMiniComponent, ContatoComponent, EstacoesComponent],
  imports: [
    CommonModule,
    CoreDataModule,
    MapModule,
    PcdModule,
    SensorModule,
    EstacaoModule,
    MaterialModule,
    IntervaloModule,
    InstituicaoModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule
  ]
})
export class PagesModule { }
