import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import StyleStyle from 'ol/style/Style';
import StyleStroke from 'ol/style/Stroke';
import StyleIcon from 'ol/style/Icon';
import StyleFill from 'ol/style/Fill';
import StyleText from 'ol/style/Text';
import LayerVector from 'ol/layer/Vector';
import SourceVector from 'ol/source/Vector';
import {fromLonLat } from 'ol/proj.js';
import { MapCoreComponent } from '../map-core/map-core.component';
import { DadoSensor } from '../../../state/dado-sensor/dado-sensor.model';
import { LegendaSchema } from '../../../state/sensor/sensor.legenda';
import { isUndefined } from 'util';

export class MapPcdMarkerFactory {

  private olMap = null;
  private pcdLayer = null;
  private sensorId: number = null;
  private labels: any[] = null;

  constructor() { }

  public plot(map: MapCoreComponent, dadosSensor: DadoSensor[]) 
  {
    this.olMap = map.getMap();

    if (!this.olMap) return;

    this.removeLayerPCD(map);

    if (dadosSensor.length == 0) return;

    this.sensorId = Number(dadosSensor[0].sensor);

    let _legenda = LegendaSchema.find(sensor => sensor.values.includes(this.sensorId));

    if (isUndefined(_legenda)) _legenda = LegendaSchema[0];

    this.labels = _legenda.labels;

    let layer = {
      type: 'Point',
      vector: new LayerVector({
        source: new SourceVector({
          features: dadosSensor
            .filter(dadoSensor => dadoSensor.valor !== null)
            .map(dadoSensor => this.makePoint(dadoSensor))
        }),
        id: 'pcd'
      })
    };

    const extent = layer.vector.getSource().getExtent();
    if (extent[0] != 'Infinity')
      this.olMap.getView().fit(extent)

    layer.vector.setZIndex(2);

    this.pcdLayer = layer.vector;

    this.olMap.addLayer(this.pcdLayer);

    this.olMap.removeEventListener('singleclick');
    this.onClick(map);
    map.addEvents({
      on: {
        pointermove: () => { }
      }
    })
  }
  
  private onClick(map: MapCoreComponent) {

    this.olMap.on('singleclick', (e) => {
      let clicado = false;
      this.olMap.forEachFeatureAtPixel(e.pixel, (feature) => {
        if (clicado) return;
          if(feature.values_.model){
            map.options.on.singleclick(feature.get('model'));
            clicado = true;
          }
      });
    });
  }

  private removeLayerPCD(map: MapCoreComponent) 
  {
    // this.olMap.removeLayer(this.pcdLayer);
    map.removeLayerById('pcd')
  }

  private makePoint(dadoSensor: DadoSensor) 
  {
    const point = new Point(fromLonLat(dadoSensor.geom.coordinates));
    const value = parseFloat(dadoSensor.valor);

    const regPoint = new Feature({
      geometry: point,
      field: 'geom',
      model: dadoSensor,
      label: 'Estações'
    });

    const pointStyle = {
      crossOrigin: 'anonymous',
      src: 'assets/img/btn_white.jpg',
      size: this.getSizeImg(value.toFixed(1).toString()),
      color: this.getLabelColor(this.labels, dadoSensor)
    };

    regPoint.setStyle(new StyleStyle({
      text: new StyleText({
        font: '11px Calibri,sans-serif',
        fill: new StyleFill({ color: this.getFontColor(this.labels, dadoSensor) }),
        stroke: new StyleStroke({
          color: this.getFontColor(this.labels, dadoSensor), width: 0.5
        }),
        text: value.toFixed(1),
      }),
      image: new StyleIcon((pointStyle))
    }));

    return regPoint;
  }

  private getSizeImg(value) {
    let w = 30;
    if (value.length < 4) {
      w = 30;
    } else if (value.length <= 7) {
      w = 48;
    } else if (value.length >= 8) {
      w = 50;
    }
    return [w, 20];
  }

  public getLabelColor(labels: any[], dado: DadoSensor) {

    let _color = 'lightgray'; 

    labels.forEach(item => {
      if (item.predicate(dado.valor)) {
        _color = item.labelColor;
        return;
      }  
    });
  
    return _color;
  }

  public getFontColor(labels: any[], dado: DadoSensor) {
    let _color = 'black'; 

    labels.forEach(item => {
      if (item.predicate(dado.valor)) {
        _color = item.fontColor;
        return;
      }  
    });
  
    return _color;
  }
}
