import { Component, OnInit } from '@angular/core';
import {MAT_DATE_FORMATS } from '@angular/material';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

import { AppDateAdapter, APP_DATE_FORMAT_2 } from '../intervalo-select/date.adapter';
import { IntervaloFacade } from '../../../state/intervalo/intervalo.facade';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-intervalo-livre-select',
  templateUrl: './intervalo-livre-select.component.html',
  styleUrls: ['./intervalo-livre-select.component.css'],
  providers: [    
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMAT_2
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'pt-BR'
    }
  ],

})
export class IntervaloLivreSelectComponent implements OnInit {
  showTestAlert(resp) {
    Swal.fire({
      title: "Aviso",
      html: resp,
      icon: "warning",
      confirmButtonText: "Ok"
    });
  };

  public minDataInicio: string;
  public maxDataInicio: string;
  public minDataFim: string;
  public maxDataFim: string;
  public today: Date = new Date;
  public dataInicio: string;
  public dataFim: string;

  constructor(private intervaloFacade: IntervaloFacade, private dateAdapter : DateAdapter<any>) {
    this.maxDataInicio = new Date().toISOString();
    this.maxDataFim = new Date().toISOString();
    this.intervaloFacade.currentPeriodo$.subscribe(
      periodo => {
        if (!periodo) return;
        let [dataI, dataF] = periodo.split(',');
        if (!dataF) return;

        let date0 = dataI.split('-');
        let date1 = dataF.split('-');

        this.dataInicio = new Date(Number(date0[0]),Number(date0[1])-1,Number(date0[2])).toISOString()
        this.dataFim = new Date(Number(date1[0]),Number(date1[1])-1,Number(date1[2])).toISOString()
      }
    )

  }

  ngOnInit() {
    this.minDataFim = this.dataInicio;
  }

  public dataInicioHandler(event){
    this.dataInicio = event.toISOString();
    this.checkLimitInicial();
    if(this.dataInicio && this.dataFim)
      this.intervaloFacade.selectPeriodo(this.dataInicio.slice(0,10)+','+this.dataFim.slice(0,10));

    this.minDataFim = this.dataInicio;

  }

  public dataFimHandler(event){
    this.dataFim = event.toISOString()   
    this.checkLimiteFinal();
    if(this.dataInicio && this.dataFim)
      this.intervaloFacade.selectPeriodo(this.dataInicio.slice(0,10)+','+this.dataFim.slice(0,10));

    this.maxDataInicio = this.dataFim;
  }

  private checkLimitInicial(){
    let dataI = new Date(this.dataInicio)
    let dataF = new Date(this.dataFim)
    var diff = Math.abs(dataI.getTime() - dataF.getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));    
    
    if (diffDays > 45){
      dataF.setTime(dataI.getTime() + (24*60*60*1000) * 60);
      this.dataFim = dataF.toISOString();
      this.maxDataInicio = this.dataFim;
      this.minDataFim = this.dataInicio;
    }

    if(dataI.getTime() > dataF.getTime()){
      this.showTestAlert("A data inicial deve ser menor do que a final");
      dataI.setTime(dataF.getTime() - (24*60*60*1000));
      this.dataInicio = dataI.toISOString();
      this.maxDataInicio = this.dataFim;
      this.minDataFim = this.dataInicio;    
    }else if(dataI.getFullYear()<1911){
      this.showTestAlert("A data inicial deve ser maior que 01/01/1911");
      dataI.setTime(this.today.getTime()-( 1000 * 3600 * 24));
      this.dataInicio = dataI.toISOString();
      this.dataFim = this.today.toISOString();
    }else if (dataF.getTime() > this.today.getTime()){
      this.showTestAlert("A data máxima para consulta é: "+this.today.getDate().toString() + "/" + this.today.getMonth()+ "/" + this.today.getFullYear());
      dataI.setTime(this.today.getTime()-( 1000 * 3600 * 24));
      this.dataInicio = dataI.toISOString();
      this.dataFim = this.today.toISOString();
    }
  }

  private checkLimiteFinal(){
    let dataI = new Date(this.dataInicio)
    let dataF = new Date(this.dataFim)
    const diff = Math.abs(dataI.getTime() - dataF.getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));       

    if (diffDays > 45){
      dataI.setTime(dataF.getTime() - (1000 * 3600 * 24) * 60);
      this.dataInicio = dataI.toISOString();

      this.maxDataInicio = this.dataFim;
      this.minDataFim = this.dataInicio;
    }

    if(dataI.getTime() > dataF.getTime()){
      this.showTestAlert("A data inicial deve ser menor do que a final");
      dataI.setTime(dataF.getTime() - (1000 * 3600 * 24));
      this.dataInicio = dataI.toISOString();
      this.maxDataInicio = this.dataFim;
      this.minDataFim = this.dataInicio;     
    }else if (dataF.getFullYear()<1911){
      this.showTestAlert("A data inicial deve ser maior que 01/01/1911");      
      dataI.setTime(this.today.getTime()-( 1000 * 3600 * 24));
      this.dataInicio = dataI.toISOString();
      this.dataFim = this.today.toISOString();
    }else if (dataF.getTime() > this.today.getTime()){
      this.showTestAlert("A data máxima para consulta é: "+this.today.getDate().toString() + "/" + this.today.getMonth()+ "/" + this.today.getFullYear());
      dataI.setTime(this.today.getTime()-( 1000 * 3600 * 24));
      this.dataInicio = dataI.toISOString();
      this.dataFim = this.today.toISOString();
    }
  }
}
