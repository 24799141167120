import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as TempoAgoraActions from './tempo-agora.actions';
import { TempoAgoraState } from './tempo-agora.reducer';
import { selectAllTempoAgora, selectCurrentTempoAgora, selectLoadingTempoAgora } from './tempo-agora.selectors';

@Injectable({ providedIn: 'root' })
export class TempoAgoraFacade {
  allTempoAgora$ = this.store.pipe(select(selectAllTempoAgora));
  currentTempoAgora$ = this.store.pipe(select(selectCurrentTempoAgora));
  loading$ = this.store.pipe(select(selectLoadingTempoAgora));

  constructor(private store: Store<TempoAgoraState>, private actions$: ActionsSubject) {}

  pickTempoAgora(estacaoId: number) {
    this.store.dispatch(new TempoAgoraActions.PickTempoAgora(estacaoId));
  }
}
