import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as DadoSensorActions from './dado-sensor.actions';
import { DadoSensorState } from './dado-sensor.reducer';
import { 
  selectAllDadoSensores, 
  isLoadingDadoSensor, 
  selectLoadedAtDadoSensor,
  selectQueueInDadoSensor,
  selectCachedAtDadoSensor
} from './dado-sensor.selectors';
import { NavigateLinkService } from '../../components/pcd/navigate-link.service';
import { DadoSensorPayload, DadoSensor } from './dado-sensor.model';

@Injectable({ providedIn: 'root' })
export class DadoSensorFacade {

  allDadoSensores$    = this.store.pipe(select(selectAllDadoSensores));
  loading$            = this.store.pipe(select(isLoadingDadoSensor));
  loadedAt$           = this.store.pipe(select(selectLoadedAtDadoSensor));
  queueIn$            = this.store.pipe(select(selectQueueInDadoSensor));
  cachedAt$           = this.store.pipe(select(selectCachedAtDadoSensor));

  constructor(
    private navigateLinkService: NavigateLinkService, 
    private store: Store<DadoSensorState>
  ) {}

  select(itemId:number) {
    this.store.dispatch(new DadoSensorActions.DadoSensorSelected(itemId));
  }

  load(refresh: boolean = false) {
    let payload: DadoSensorPayload = this.navigateLinkService.getPayload();
    payload.refresh = refresh;

    this.store.dispatch(new DadoSensorActions.LoadDadoSensores(payload));
  }

  report($estacao_id, $sensor_id, $periodo, $intervalo, $operador, $data, $valor, $instituicao_nome, $instituicao_id) {
    this.store.dispatch(new DadoSensorActions.ReportDadoSensor({
      'estacao_id': $estacao_id,
      'sensor_id': $sensor_id,
      'periodo': $periodo,
      'intervalo': $intervalo,
      'operador': $operador,
      'data': $data,
      'valor': $valor,
      'instituicao_nome': $instituicao_nome,
      'instituicao_id': $instituicao_id
    }));
  }

  dadoSensoresQueueIn(queue_in:Date) {
    this.store.dispatch(new DadoSensorActions.DadoSensoresQueueIn(queue_in));
  }

  dadoSensoresCachedAt(cached_at:Date) {
    this.store.dispatch(new DadoSensorActions.DadoSensoresCachedAt(cached_at));
  }
}
