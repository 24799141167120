import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StateModule } from './state/state.module';
import { SensorService } from './state/sensor/sensor.service';
import { NotificationsService } from './notifications/notifications.service';
import { InstituicaoService } from './state/instituicao/instituicao.service';
import { DadoSensorService } from './state/dado-sensor/dado-sensor.service';
import { EstacaoService } from './state/estacao/estacao.service';
import { EstacaoSerieHorarioService } from './state/estacao-serie/estacao-serie-horario.service';
import { EstacaoSerieDiarioService } from './state/estacao-serie/estacao-serie-diario.service';
import { EstacaoSerieMensalService } from './state/estacao-serie/estacao-serie-mensal.service';
import { UfService } from './state/uf/uf.service';
import { TempoAgoraService } from './state/tempo-agora/tempo-agora.service';
import { MunicipioService } from './state/municipio/municipio.service';


@NgModule({
  providers: [
    SensorService,
    InstituicaoService,
    DadoSensorService,
    EstacaoService,
    EstacaoSerieHorarioService,
    EstacaoSerieDiarioService,
    EstacaoSerieMensalService,
    UfService,
    TempoAgoraService,
    NotificationsService,
    MunicipioService
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    StateModule
  ],
})
export class CoreDataModule {}
