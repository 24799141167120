import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

    logged_user = '';
    logged_user_id = null;

    constructor(private router: Router) 
    { 
        
    }

    ngOnInit() 
    {
        if (localStorage.getItem('user_name') != 'null') {
            this.logged_user = localStorage.getItem('user_name');
            this.logged_user_id = localStorage.getItem('user_id');
        } else {
            this.logged_user = 'Visitante';
            this.logged_user_id = null;
        }
    }

    logout()
    {
        
    }

    login()
    {
        
    }

    link(url:string) {
        this.router.navigate([url]);
    }
}

