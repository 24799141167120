import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigFacade } from 'libs/core-pcd/src/lib/state/config/config.facade';
import { TempoAgoraFacade, TempoAgora, EstacaoFacade, Estacao } from '@funceme/core-pcd';
import { emptyTempoAgora } from 'libs/core-pcd/src/lib/state/tempo-agora/tempo-agora.model';
import { ActivatedRoute, Router } from '@angular/router';
import { EstacaoSelectComponent } from 'libs/core-pcd/src/lib/components/estacao/estacao-select/estacao-select.component';

@Component({
  selector: 'app-tempo-agora-mini',
  templateUrl: './tempo-agora-mini.component.html',
  styleUrls: ['./tempo-agora-mini.component.css']
})
export class TempoAgoraMiniComponent implements OnInit {

  public estacao: Estacao;
  public routeEstacaoId: number;
  public tempoAgora: TempoAgora = emptyTempoAgora;
  public loading: boolean = false;
  public countdown: number = 100;

  @ViewChild('selectEstacao', {static: true}) selectEstacao: EstacaoSelectComponent;

  constructor(
    private configFacade: ConfigFacade,
    private tempoAgoraFacade: TempoAgoraFacade,
    private estacaoFacade: EstacaoFacade,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.routeEstacaoId = Number(this.route.snapshot.paramMap.get('estacaoId'));

    this.configFacade.mini();

    this.tempoAgoraFacade.currentTempoAgora$.subscribe(value => this.tempoAgora = value);
    this.tempoAgoraFacade.loading$.subscribe(value => this.loading = value);

    this.estacaoFacade.current$.subscribe(value => {
      if(!value)
        return;
      this.estacao = value;
      this.tempoAgoraFacade.pickTempoAgora(this.estacao.id);
      if (this.estacao && this.estacao.id !== this.routeEstacaoId) {
        this.router.navigate(['mini/tempo-agora/' + this.estacao.id]);
      }
    });
  }

  public updateTempoAgora()
  {
    if (this.tempoAgora == null || this.tempoAgora.atualizacao == null)
      return "";

    let updateBackup: string = this.tempoAgora.atualizacao;
    let updateBackupSplitted: string[] = updateBackup.split(" ", 2); 
    let hour: string  = updateBackupSplitted[0];
    let data: string  = updateBackupSplitted[1];

    let dataSplited: string[] = data.split("/", 3); 
    let day: string = dataSplited[0];
    let month: string = dataSplited[1];
    let year: string = dataSplited[2];

    let formatedDate: string = day + '/' + month + '/' + year;

    return formatedDate + ' ' + hour;
  }

  ngOnInit() {    
    if (this.estacao && this.estacao.id !== this.routeEstacaoId) {
      this.estacaoFacade.pick(this.routeEstacaoId);
      this.router.navigate(['mini/tempo-agora/' + this.routeEstacaoId]);
    }
  }

  public refresh() 
  {
    this.tempoAgoraFacade.pickTempoAgora(this.estacao.id);
  }

  private autoRefresh(tempoAgora: TempoAgora)
  {
    if (!tempoAgora._loaded_at)
      return;

    setTimeout(() => {
      let lowerTime = (tempoAgora._expires_in < tempoAgora._queue_in)? tempoAgora._expires_in : tempoAgora._queue_in;
      let now:number = new Date().getTime();
      
      let loadedAt:number = tempoAgora._loaded_at.getTime();
      let cachedAt:number = tempoAgora._cached_at.getTime();
      let reloadAt:number = loadedAt + lowerTime + 5000;

      let waitTime: number = reloadAt - cachedAt;
      let timeToQueue: number = reloadAt - now;
      
      this.countdown = Math.floor((timeToQueue / waitTime) * 100);

      if (this.countdown < 0)
        this.tempoAgoraFacade.pickTempoAgora(this.estacao.id);
      else 
        this.autoRefresh(tempoAgora)
    },1000);
  }
}