import * as fromDadoSensor from './dado-sensor.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectDadoSensorState = createFeatureSelector<fromDadoSensor.DadoSensorState>('dadosSensor');

export const selectDadoSensorIds = createSelector(
  selectDadoSensorState,
  fromDadoSensor.selectDadoSensorIds
);

export const selectDadoSensorEntities = createSelector(
  selectDadoSensorState,
  fromDadoSensor.selectDadoSensorEntities
);

export const selectAllDadoSensores = createSelector(
  selectDadoSensorState,
  fromDadoSensor.selectAllDadoSensores
);

export const isLoadingDadoSensor = createSelector(
  selectDadoSensorState,
  fromDadoSensor.isLoading
);

export const selectLoadedAtDadoSensor = createSelector(
  selectDadoSensorState,
  fromDadoSensor.getLoadedAt
);

export const selectQueueInDadoSensor = createSelector(
  selectDadoSensorState,
  fromDadoSensor.getQueueIn
);

export const selectCachedAtDadoSensor = createSelector(
  selectDadoSensorState,
  fromDadoSensor.getCachedAt
);