import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { SensorActions, SensorActionTypes } from './sensor.actions';
import { Sensor } from './sensor.model';
import { ApiMetaData } from '../../generics/pagination.model';

/**
 * Interface to the part of the Store containing SensorState
 * and other information related to SensorData.
 */
export interface SensorState extends EntityState<Sensor> {
  soughtId: string;
  operador: string;
  selectedId: number | null;
  pickingId: number | null;
  loaded_at: Date | null;
  filteredIds: number[];
  selectedIds: number[];
  loading: boolean;
  meta_data: ApiMetaData;
  total: number,
  last_pagination: string
}

export const adapter: EntityAdapter<Sensor> = createEntityAdapter<Sensor>({
  selectId: (sensor: Sensor) => sensor.id,
});

export const initialState: SensorState = adapter.getInitialState({
  selectedId: null,
  loaded_at: null,
  soughtId: null,
  operador: 'AVG',
  pickingId: null,
  filteredIds: null,
  selectedIds: null,
  loading: null,
  meta_data: null,
  total: null,
  last_pagination: null
});

export function sensorReducer(state = initialState, action: SensorActions): SensorState {

  switch (action.type) {
    case SensorActionTypes.SensorSelected: {
      return Object.assign({}, state, { selectedId: action.payload, pickingId: null });
    }

    case SensorActionTypes.SensorSought: {
      return Object.assign({}, state, { soughtId: action.payload });
    }

    case SensorActionTypes.SensorOperadorChanged: {
      return Object.assign({}, state, { operador: action.payload });
    }

    case SensorActionTypes.SensoresLoaded: {
      state.loaded_at = new Date();
      return adapter.addAll(action.payload, state);
    }

    case SensorActionTypes.SensoresFiltered: {
      if (!state.filteredIds) state.filteredIds = []

      return Object.assign({}, state, { filteredIds: state.filteredIds.concat(action.payload) });
    }

    case SensorActionTypes.SensoresUnfiltered: {
      return Object.assign({}, state, { 
        filteredIds: state.filteredIds.filter(id => !action.payload.includes(id))
      });
    }

    case SensorActionTypes.SensoresSelected: {
      return Object.assign({}, state, { selectedIds: [...state.selectedIds, action.payload] });
    }

    case SensorActionTypes.SensoresUnselected: {
      return Object.assign({}, state, { 
        selectedSensorIds: state.selectedIds.filter(id => !action.payload.includes(id))
      });
    }

    case SensorActionTypes.LoadSensores: {
      return Object.assign({}, state, { loading: true });
    }

    case SensorActionTypes.SensoresLoaded: {
      return adapter.addAll(action.payload, Object.assign({}, state, { loading: false, loaded_at : new Date() }));
    }

    case SensorActionTypes.PaginateSensores: {
      return Object.assign({}, state, { loading: true });
    }

    case SensorActionTypes.SensoresPaginated: {          
      return adapter.addAll(action.payload.data.list, Object.assign({}, state, { 
        meta_data: action.payload.meta, 
        total: action.payload.data.total_results,
        loading: false,
        loaded_at: new Date().toISOString(),
        last_pagination: JSON.stringify(action.payload.meta.request.query_params),
      }));  
    }

    case SensorActionTypes.SensoresRequestDone:
    case SensorActionTypes.SensoresRequestFailed: {
      return Object.assign({}, state, { loading: false });
    }

    default:
      return state;
  }
}

export const getSelectedSensorId = (state: SensorState) => state.selectedId;
export const getSoughtSensorId = (state: SensorState) => state.soughtId;
export const getSensorOperador = (state: SensorState) => state.operador;
export const getFilteredSensorIds = (state: SensorState) => state.filteredIds;
export const getSelectedSensorIds = (state: SensorState) => state.selectedIds;
export const getMetaDataSensores = (state: SensorState) => state.meta_data;
export const getTotalSensores = (state: SensorState) => state.total;
export const getLoadingSensores = (state: SensorState) => state.loading;
export const getPickingSensorId  = (state: SensorState) => state.pickingId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of sensor ids
export const selectSensorIds = selectIds;

// select the dictionary of sensor entities
export const selectSensorEntities = selectEntities;

// select the array of sensor
export const selectAllSensores = selectAll;

// select the total sensor count
export const selectSensorTotal = selectTotal;
