import { Action } from '@ngrx/store';
import { Estacao, EstacaoPayload } from './estacao.model';
import { Pagination, ApiResponse } from '../../generics/pagination.model';

export enum EstacaoActionTypes {
  EstacoesAction = '[Estacoes] Action',  
  LoadEstacoes = '[Estacoes] Load Data',
  EstacoesLoaded = '[Estacoes] Data Loaded',
  EstacaoSelected = '[Estacoes] Selected',
  EstacoesSelected = '[Estacoes] Selected',
  EstacoesUnselected = '[Estacoes] Unselected',
  PaginateEstacoes = '[Estacoes] Paginate',
  EstacoesPaginated = '[Estacoes] Paginated',
  EstacoesRequestDone = '[Estacoes] RequestDone',
  EstacoesRequestFailed = '[Estacoes] Request Failed',
  PickEstacao = '[Estacoes] Pick',
  EstacaoAdded = '[Estacoes] Data added',
}

export class Estacoes implements Action {
  readonly type = EstacaoActionTypes.EstacoesAction;
}

export class EstacaoSelected implements Action {
  readonly type = EstacaoActionTypes.EstacaoSelected;
  constructor(public payload: number) { }
}

export class LoadEstacoes implements Action {
  readonly type = EstacaoActionTypes.LoadEstacoes;
  constructor(public payload: EstacaoPayload) { }
}

export class EstacoesLoaded implements Action {
  readonly type = EstacaoActionTypes.EstacoesLoaded;
  constructor(public payload: Estacao[]) { }
}

export class EstacaoAdded implements Action {
  readonly type = EstacaoActionTypes.EstacaoAdded;
  constructor(public payload: Estacao) { }
}

export class PickEstacao implements Action {
  readonly type = EstacaoActionTypes.PickEstacao;
  constructor(public payload: number) { }
}

export class EstacoesSelected implements Action {
  readonly type = EstacaoActionTypes.EstacoesSelected;
  constructor(public payload: number[]) { }
}

export class EstacoesUnselected implements Action {
  readonly type = EstacaoActionTypes.EstacoesUnselected;
  constructor(public payload: number[]) { }
}

export class PaginateEstacoes implements Action {
  readonly type = EstacaoActionTypes.PaginateEstacoes;
  constructor(public payload: Pagination) { }
}

export class EstacoesPaginated implements Action {
  readonly type = EstacaoActionTypes.EstacoesPaginated;
  constructor(public payload: ApiResponse<Estacao>) { }
}

export class EstacoesRequestDone implements Action {
  readonly type = EstacaoActionTypes.EstacoesRequestDone;
  constructor() { }
}

export class EstacoesRequestFailed implements Action {
  readonly type = EstacaoActionTypes.EstacoesRequestFailed;
  constructor() { }
}

export type EstacaoActions = Estacoes
  | LoadEstacoes
  | EstacoesLoaded
  | EstacaoSelected
  | EstacoesSelected
  | EstacoesUnselected
  | PaginateEstacoes
  | EstacoesPaginated
  | EstacoesRequestDone
  | EstacoesRequestFailed
  | PickEstacao
  | EstacaoAdded
;
