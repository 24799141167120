export const LegendaSchema = [
    {
      'name' : 'Sensor',
      'values' : [10, 14, 18, 19, 20, 21, 23, 24, 30, 50, 51, 54, 62],
      'highlights' : [
        {
          'title' : 'Máximas', 
          'method': 'max',
          'limit' : 10
        }
      ],
      'labels' : [
        {
          'labelColor' : 'lightgray', 
          'fontColor' : 'black',
          'text' : 'valor absoluto',       
          'predicate': (value) => {
            return true; 
          }
        }
      ]
    },
    {
      'name' : 'Temperatura',
      'values' : [2, 3, 4],
      'highlights' : [
        {
          'title' : 'Máximas', 
          'method': 'max',
          'limit' : 5 
        },
        {
          'title' : 'Mínimas', 
          'method': 'min',
          'limit' : 5 
        }
      ],
      'labels' : [
        {
          'labelColor' : 'rgb(0, 0, 245)', 
          'fontColor' : 'white',
          'text' : '< 10º',       
          'predicate': (value) => {
            return value < 10; 
          }
        },
        {
          'labelColor' : 'rgb(28, 152, 252)', 
          'fontColor' : 'black',
          'text' : '>= 10º < 15',       
          'predicate': (value) => {
            return value >= 10 && value < 15; 
          }
        },
        {
          'labelColor' : 'rgb(135, 206, 235)', 
          'fontColor' : 'black',
          'text' : '>= 15º < 20º',       
          'predicate': (value) => {
            return value >= 15 && value < 20; 
          }
        },
        {
          'labelColor' : 'rgb(230, 223, 64)', 
          'fontColor' : 'black',
          'text' : '>= 20º < 25º',       
          'predicate': (value) => {
            return value >= 20 && value < 25; 
          }
        },
        {
          'labelColor' : 'rgb(248, 162, 32)', 
          'fontColor' : 'black',
          'text' : '>= 25º < 30º',       
          'predicate': (value) => {
            return value >= 25 && value < 30; 
          }
        },
        {
          'labelColor' : 'rgb(244, 56, 57)', 
          'fontColor' : 'white',
          'text' : '>= 30º < 35º',       
          'predicate': (value) => {
            return value >= 30 && value < 35; 
          }
        },
        {
          'labelColor' : 'rgb(167, 39, 48)', 
          'fontColor' : 'white',
          'text' : '>= 35º',       
          'predicate': (value) => {
            return value >= 35; 
          }
        }
      ]
    },{
      'name' : 'Precipitação',
      'values' : [22],
      'highlights' : [
        {
          'title' : 'Máximas', 
          'method': 'max',
          'limit' : 10 
        }
      ],
      'labels' : [
        {
          'labelColor' : 'black', 
          'fontColor' : 'white',
          'text' : 'Sem chuva (0.0 mm)',       
          'predicate': (value) => {
            return value == 0; 
          }
        },
        {
          'labelColor' : 'rgb(244, 56, 57)', 
          'fontColor' : 'white',
          'text' : 'De 0.1 a 5.0 mm',       
          'predicate': (value) => {
            return value > 0 && value <= 5; 
          }
        },
        {
          'labelColor' : 'rgb(230, 223, 64)', 
          'fontColor' : 'black',
          'text' : 'De 5.1 a 15.0 mm',       
          'predicate': (value) => {
            return value > 5 && value <= 15; 
          }
        },
        {
          'labelColor' : 'rgb(45, 224, 51)', 
          'fontColor' : 'black',
          'text' : 'De 15.1 a 25.0 mm',       
          'predicate': (value) => {
            return value > 15 && value <= 25; 
          }
        },
        {
          'labelColor' : 'rgb(28, 152, 252)', 
          'fontColor' : 'white',
          'text' : 'De 25.1 a 50.0 mm',       
          'predicate': (value) => {
            return value > 25 && value <= 50; 
          }
        },
        {
          'labelColor' : 'rgb(120, 0, 217)', 
          'fontColor' : 'white',
          'text' : 'Acima de 50.0 mm',       
          'predicate': (value) => {
            return value > 50; 
          }
        }
      ]
    },
    {
      'name' : 'Umidade Relativa do Ar',
      'values' : [6, 7, 8],
      'highlights' : [
        {
          'title' : 'Máximas', 
          'method': 'max',
          'limit' : 5 
        },
        {
          'title' : 'Mínimas', 
          'method': 'min',
          'limit' : 5 
        }
      ],
      'labels' : [
        {
          'labelColor' : 'rgb(252, 0, 0)', 
          'fontColor' : 'white',
          'text' : '< 20%',       
          'predicate': (value) => {
            return value < 20; 
          }
        },{
          'labelColor' : 'rgb(252, 119, 21)', 
          'fontColor' : 'black',
          'text' : '20% a 30%',       
          'predicate': (value) => {
            return value >= 20 && value < 30; 
          }
        },{
          'labelColor' : 'rgb(251, 201, 42)', 
          'fontColor' : 'black',
          'text' : '30% a 40%',       
          'predicate': (value) => {
            return value >= 30 && value < 40; 
          }
        },{
          'labelColor' : 'rgb(254, 255, 111)', 
          'fontColor' : 'black',
          'text' : '40% a 50%',       
          'predicate': (value) => {
            return value >= 40 && value < 50; 
          }
        },{
          'labelColor' : 'rgb(44, 221, 93)', 
          'fontColor' : 'black',
          'text' : '50% a 60%',       
          'predicate': (value) => {
            return value >= 50 && value < 60; 
          }
        },{
          'labelColor' : 'rgb(44, 221, 178)', 
          'fontColor' : 'black',
          'text' : '60% a 70%',       
          'predicate': (value) => {
            return value >= 60 && value < 70; 
          }
        },{
          'labelColor' : 'rgb(40, 198, 197)', 
          'fontColor' : 'black',
          'text' : '70% a 75%',       
          'predicate': (value) => {
            return value >= 70 && value < 75; 
          }
        },{
          'labelColor' : 'rgb(50, 255, 254)', 
          'fontColor' : 'black',
          'text' : '75% a 80%',       
          'predicate': (value) => {
            return value >= 75 && value < 80; 
          }
        },{
          'labelColor' : 'rgb(43, 218, 253)', 
          'fontColor' : 'black',
          'text' : '80% a 85%',       
          'predicate': (value) => {
            return value >= 80 && value < 85; 
          }
        },{
          'labelColor' : 'rgb(27, 145, 252)', 
          'fontColor' : 'black',
          'text' : '85% a 90%',       
          'predicate': (value) => {
            return value >= 85 && value < 90; 
          }
        },{
          'labelColor' : 'rgb(23, 0, 249)', 
          'fontColor' : 'white',
          'text' : '90% a 95%',       
          'predicate': (value) => {
            return value >= 90 && value < 95; 
          }
        },{
          'labelColor' : 'rgb(25, 0, 176)', 
          'fontColor' : 'white',
          'text' : '> 95%',       
          'predicate': (value) => {
            return value >= 95; 
          }
        }
      ]
    }
  ];