import { Component, OnInit, OnDestroy } from '@angular/core';
import { ZmatSelectOneSchema } from '../../../generics/zmat-select/zmat-select-one/zmat-select-one.schema';
import { Instituicao, emptyInstituicao } from '../../../state/instituicao/instituicao.model';
import { Subscription } from 'rxjs';
import { InstituicaoFacade } from '../../../state/instituicao/instituicao.facade';

@Component({
  selector: 'instituicao-zmat-select',
  templateUrl: './instituicao-zmat-select.component.html',
  styleUrls: ['./instituicao-zmat-select.component.css']
})
export class InstituicaoZmatSelectComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  public schema: ZmatSelectOneSchema;
  public data: Instituicao[] = [emptyInstituicao];
  public value: Instituicao;

  constructor(private facade: InstituicaoFacade) 
  { 
    this.schema = {
      label: 'Instituição',
      placeholder: 'Selecionar Instituição',
      getId(item: Instituicao) {
        return item.id;
      },
      getName(item: Instituicao) {
        return item.nome
      }
    };
  }

  ngOnInit() {
    this.subscriptions.add(
      this.facade.all$.subscribe(values => { 
        // Instiruições que contem estações
        this.data = values.filter(value => [1, 11, 39, 41, 42, 43,
          105, 106, 107, 108, 109, 110, 112, 113, 114, 115, 116,
          117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
          128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138,
          139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149,
          150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160,
          161, 162, 163, 165, 166, 167, 168, 171, 172, 173, 175,
          176, 177, 179, 181, 182, 183, 184, 185, 186, 9999].includes(value.id)
        ); 
      })
    ).add(
      this.facade.current$.subscribe(value => this.value = value)
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  changed($event) {
    this.facade.select($event);
  }
}
