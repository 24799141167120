import { Component, OnInit, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { EstacaoSerieFacade } from '../../../state/estacao-serie/estacao-serie.facade';
import { Estacao, emptyEstacao } from '../../../state/estacao/estacao.model';
import { EstacaoFacade } from '../../../state/estacao/estacao.facade';
import { IntervaloFacade } from '../../../state/intervalo/intervalo.facade';
import { Router } from '@angular/router';
import { ChartLineComponent } from '../../chart/chart-line/chart-line.component';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-estacao-show',
  templateUrl: './estacao-show.component.html',
  styleUrls: ['./estacao-show.component.css']
})
export class EstacaoShowComponent implements OnInit {

  public estacao: Estacao = emptyEstacao;
  public sensores = [];
  public periodo: string;
  public customGraphs = [2, 3, 4, 6, 7, 8, 10, 11, 12, 14, 15, 16, 18, 19, 20, 22, 50, 30, 51];
  public otherSensores = [];
  public loading = false;

  @ViewChildren(ChartLineComponent) chartChildren !: QueryList<ChartLineComponent>;

  constructor(
    private estacaoFacade: EstacaoFacade, 
    private estacaoSerieFacade: EstacaoSerieFacade, 
    private intervaloFacade: IntervaloFacade,
    private router: Router,
    private elementRef: ElementRef
  ) { }

  ngOnInit() 
  {
    this.estacaoSerieFacade.loading$.subscribe(value => this.loading = value);

    combineLatest(
      this.intervaloFacade.soughtPeriodo$,
      this.estacaoFacade.current$
    ).subscribe(
      ([periodo, estacao]) => {
        this.periodo = periodo;

        if (!estacao) {
          this.estacao = emptyEstacao;
          return;
        }
        
        this.estacao = estacao;

        if (!this.estacao.id || !this.estacao.sensor) 
          return;

        this.sensores = this.estacao.sensor.map(sensor => Number(sensor.id));
        this.sensores = [31, 3, 54, 6, 14, 15, 16, 2, 22, 41, 53];

        //REMOVE SENSORES [100] BATERIA [9] TEMPERATURA INTERNA [17] CORRENTE PAINEL SOLAR, DA LISTA A SER EXIBIDA
        /*for( var i = 0; i < this.sensores.length; i++){
          if ((this.sensores[i] === 100) || (this.sensores[i] === 9) || (this.sensores[i] === 17)) { 
            this.sensores.splice(i, 1); 
          }
        }*/

        this.otherSensores = this.sensores
          .filter(sensor => !this.customGraphs.includes(Number(sensor)))
          .map(sensor => Number(sensor));

        if (this.chartChildren)
          this.chartChildren.map(chart => chart.reload(this.estacao.id, this.periodo));
      }
    );
  }

  private clearCanvas()
  {
    let _canvas = this.elementRef.nativeElement.querySelector('canvas');
    while (_canvas = this.elementRef.nativeElement.querySelector('canvas')) {
      _canvas.remove();
      _canvas = this.elementRef.nativeElement.querySelector('canvas');
    }
  }

  close() {
    this.clearCanvas();
    this.estacaoFacade.select(null);
  }

  refresh() {
    if (this.chartChildren)
      this.chartChildren.map(chart => chart.reload(this.estacao.id, this.periodo));
  }

  link(url:string) {
    this.router.navigate([url]);
  }

  ngAfterViewInit()
  {
    if (this.chartChildren)
      this.chartChildren.map(chart => chart.reload(this.estacao.id, this.periodo));
  }
}
