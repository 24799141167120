import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Estacao } from '../../../state/estacao/estacao.model';
import { Pagination, getPaginationDefault } from '../../../generics/pagination.model';
import { EstacaoFacade } from '../../../state/estacao/estacao.facade';
import { ZmatLGridTdTextComponent } from '../../../generics/zmat-lgrid/zmat-lgrid-td-text/zmat-lgrid-td-text.component';
import { ZmatLGridSchema } from '../../../generics/zmat-lgrid/zmat-lgrid.schema';
import { EstacaoSerie } from '../../../state/estacao-serie/estacao-serie.model';
import { EstacaoSerieModalButtonComponent } from '../../estacao-serie/estacao-serie-modal-button/estacao-serie-modal-button.component';

@Component({
  selector: 'estacao-lgrid',
  templateUrl: './estacao-lgrid.component.html',
  styleUrls: ['./estacao-lgrid.component.css']
})
export class EstacaoLgridComponent implements OnInit, OnDestroy 
{
  private subscriptions = new Subscription();

  private pagination: Pagination;
  
  public data: Estacao[] = [];

  @Input() enableActions: boolean = true;

  public gridSchema: ZmatLGridSchema;

  constructor(
    public facade: EstacaoFacade
  ) {     
    this.gridSchema = {    
      columns: [
        {
          title: "ID",
          field: 'id',
          ordenable: true,
          render: ZmatLGridTdTextComponent,
          getData: function(obj: Estacao) {
            return obj.id;
          }
        },
        {
          title: "Estação",
          field: 'nome',
          ordenable: true,
          render: ZmatLGridTdTextComponent,
          getData: function(obj: Estacao) {
            return obj.nome;
          }
        },
        {
          title: "Município",
          field: 'municipio',
          ordenable: true,
          render: ZmatLGridTdTextComponent,        
          getData: function(obj: Estacao) {
            return (obj.municipio)? obj.municipio.nome : '-';
          }
        },
        {
          title: "Instituição",
          field: 'instituicao',
          ordenable: true,
          render: ZmatLGridTdTextComponent,        
          getData: function(obj: Estacao) {
            return (obj.instituicao)? obj.instituicao.nome : '-';
          }
        },
        {
          title: "Lon",
          field: 'lon',
          ordenable: false,
          render: ZmatLGridTdTextComponent,        
          getData: function(obj: Estacao) {
            return obj.geom.coordinates[0].toFixed(4);
          }
        },
        {
          title: "Lat",
          field: 'lat',
          ordenable: false,
          render: ZmatLGridTdTextComponent,        
          getData: function(obj: Estacao) {
            return obj.geom.coordinates[1].toFixed(4);
          }
        },
        {
          title: "Tipo",
          field: 'tipo',
          ordenable: true,
          render: ZmatLGridTdTextComponent,        
          getData: function(obj: Estacao) {
            switch(obj.tipo_estacao) {
              case "1": 
                return 'Agrometeorológica';
              case "2": 
                return 'Hidrológica';
              case "3": 
                return 'Meteorologica';
              case "4": 
                return 'Pluviométrica';
              default: 
                return '-';
            };
          }
        },
        {
          title: "Modelo",
          field: 'modelo',
          ordenable: true,
          render: ZmatLGridTdTextComponent,        
          getData: function(obj: Estacao) {
            switch(obj.modelo) {
              case 1: 
                return 'Automática';
              case 2: 
                return 'Convencional';
              case 3: 
                return obj.tipo_estacao;
              case 9: 
                return obj.tipo_estacao;
              default: 
                return '-';
            };
          }
        },
      ],
      actions: [
        {
          title: "Dados",
          label: "Visualizar dados",
          color: "primary",
          icon: 'list',  
          render: EstacaoSerieModalButtonComponent,  
          action: function(obj: EstacaoSerie) { },
          getData: function() {}
        }
      ]
    }
  }

  ngOnInit() 
  {
    this.subscriptions.add(
      this.facade.all$.subscribe(values => this.data = values)
    );
  }  

  init() 
  {
    this.pagination = getPaginationDefault();

    this.pagination.with_list = ['instituicao.nome', 'municipio.nome'];
    this.pagination.filter_list['instituicao'] = 1;
   
    this.facade.paginate(this.pagination);
  }

  

  ngOnDestroy() {    
    this.subscriptions.unsubscribe();    
  }

  

}
