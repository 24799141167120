import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapCoreComponent } from './map-core/map-core.component';
import { MapPcdComponent } from './map-pcd/map-pcd.component';
import { MapPcdMarkerFactory } from './map-pcd-marker/map-pcd-marker.factory';

@NgModule({
  declarations: [
    MapCoreComponent, 
    MapPcdComponent
  ],
  exports: [MapPcdComponent],
  imports: [
    CommonModule
  ],
  providers: [
    MapPcdMarkerFactory
  ]
})
export class MapModule { }
