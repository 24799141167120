import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstacaoSerieModalButtonComponent } from './estacao-serie-modal-button/estacao-serie-modal-button.component';
import { EstacaoSerieModalComponent } from './estacao-serie-modal/estacao-serie-modal.component';
import { MaterialModule } from '@funceme/material';
import { EstacaoSerieSensorSelectComponent } from './estacao-serie-sensor-select/estacao-serie-sensor-select.component';
import { ChartModule } from '../chart/chart.module';
import { ZmatDatepickerModule } from '../../generics/zmat-datepicker/zmat-datepicker.module';
import { EstacaoSerieIntervaloSelectComponent } from './estacao-serie-intervalo-select/estacao-serie-intervalo-select.component';
import { ZmatChartModule } from '../../generics/zmat-chart/zmat-chart.module';
import { EstacaoSerieTableComponent } from './estacao-serie-table/estacao-serie-table.component';
import { DownloadCSVService } from '../../generics/downloadCSV.service';
import { ZmatChartTimeseriesService } from '../../generics/zmat-chart/zmat-chart-timeseries.service';

@NgModule({
  declarations: [
    EstacaoSerieModalButtonComponent, 
    EstacaoSerieModalComponent, 
    EstacaoSerieSensorSelectComponent, 
    EstacaoSerieIntervaloSelectComponent, EstacaoSerieTableComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ChartModule,
    ZmatDatepickerModule,
    ZmatChartModule
  ],
  providers: [
    DownloadCSVService,
    ZmatChartTimeseriesService
  ],
  exports: [
    EstacaoSerieModalButtonComponent, 
    EstacaoSerieModalComponent,
    EstacaoSerieSensorSelectComponent
  ],
  entryComponents: [
    EstacaoSerieModalButtonComponent, 
    EstacaoSerieModalComponent
  ]
})
export class EstacaoSerieModule { }
