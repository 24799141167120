import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { EstacaoSerieActions, EstacaoSerieActionTypes } from './estacao-serie.actions';
import { EstacaoSerie } from './estacao-serie.model';

/**
 * Interface to the part of the Store containing EstacaoSerieState
 * and other information related to EstacaoSerieData.
 */
export interface EstacaoSerieState extends EntityState<EstacaoSerie> {
  loading: boolean;
}

export const adapter: EntityAdapter<EstacaoSerie> = createEntityAdapter<EstacaoSerie>({
  selectId: (estacaoSerie: EstacaoSerie) => estacaoSerie.id,
});

export const initialState: EstacaoSerieState = adapter.getInitialState({
  loading: false
});

export function estacaoSerieReducer(state = initialState, action: EstacaoSerieActions): EstacaoSerieState {

  switch (action.type) {
    case EstacaoSerieActionTypes.EstacaoSeriePicked: {
      state = adapter.upsertOne(action.payload, state);
      return Object.assign({}, state, { loading: false });
    }

    case EstacaoSerieActionTypes.PickEstacaoSeries: {
      return Object.assign({}, state, { pickingEstacao: action.payload, loading: true }) ;
    }

    default:
      return state;
  }
}

export const isLoadingEstacaoSerie = (state: EstacaoSerieState) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of estacaoSerie ids
export const selectEstacaoSerieIds = selectIds;

// select the dictionary of estacaoSerie entities
export const selectEstacaoSerieEntities = selectEntities;

// select the array of estacaoSerie
export const selectAllEstacaoSeries = selectAll;

// select the total estacaoSerie count
export const selectEstacaoSerieTotal = selectTotal;
