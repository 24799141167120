import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstacaoShowComponent } from './estacao-show/estacao-show.component';
import { MaterialModule } from '@funceme/material';
import { ChartModule } from '../chart/chart.module';
import { EstacaoTituloComponent } from './estacao-titulo/estacao-titulo.component';
import { EstacaoGraphsComponent } from './estacao-graphs/estacao-graphs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EstacaoSelectComponent } from './estacao-select/estacao-select.component';
import { EstacaoLgridComponent } from './estacao-lgrid/estacao-lgrid.component';
import { ZmatLGridModule } from '../../generics/zmat-lgrid/zmat-lgrid.module';
import { InstituicaoModule } from '../instituicao/instituicao.module';
import { EstacaoModeloSelectComponent } from './estacao-modelo-select/estacao-modelo-select.component';
import { EstacaoTipoSelectComponent } from './estacao-tipo-select/estacao-tipo-select.component';
import { ZmatMapModule } from '../../generics/zmat-map/zmat-map.module';
import { MunicipioModule } from '../municipio/municipio.module';
import { EstacaoIndexComponent } from './estacao-index/estacao-index.component';
import { EstacaoMapComponent } from './estacao-map/estacao-map.component';
import { EstacaoSerieModule } from '../estacao-serie/estacao-serie.module';

@NgModule({
  declarations: [
    EstacaoShowComponent, 
    EstacaoTituloComponent, 
    EstacaoGraphsComponent, 
    EstacaoSelectComponent, 
    EstacaoLgridComponent, 
    EstacaoModeloSelectComponent,
    EstacaoTipoSelectComponent,
    EstacaoIndexComponent,
    EstacaoMapComponent    
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ChartModule,
    FormsModule,
    ReactiveFormsModule,
    ZmatLGridModule, 
    ZmatMapModule,
    InstituicaoModule,
    MunicipioModule,
    EstacaoSerieModule
  ],
  exports: [
    EstacaoShowComponent,
    EstacaoTituloComponent,
    EstacaoGraphsComponent,
    EstacaoSelectComponent,
    EstacaoLgridComponent,
    EstacaoModeloSelectComponent,
    EstacaoTipoSelectComponent,
    EstacaoIndexComponent,
    EstacaoMapComponent 
  ]
})
export class EstacaoModule { }
