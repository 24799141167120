import * as fromEstacao from './estacao.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { emptyEstacao, Estacao } from './estacao.model';

export const selectEstacaoState = createFeatureSelector<fromEstacao.EstacaoState>('estacao');

export const selectEstacaoIds = createSelector(
  selectEstacaoState,
  fromEstacao.selectEstacaoIds
);

export const selectEstacaoEntities = createSelector(
  selectEstacaoState,
  fromEstacao.selectEstacaoEntities
);

export const selectAllEstacoes = createSelector(
  selectEstacaoState,
  fromEstacao.selectAllEstacoes
);

export const selectPickingEstacaoId = createSelector(
  selectEstacaoState,
  fromEstacao.getPickingEstacaoId
);

export const selectCurrentEstacaoId = createSelector(
  selectEstacaoState,
  fromEstacao.getSelectedEstacaoId
);

export const selectCurrentEstacao = createSelector(
  selectEstacaoEntities,
  selectCurrentEstacaoId,
  (estacaoEntities, estacaoId) => {
    return estacaoId ? estacaoEntities[estacaoId] : emptyEstacao;
  }
);

export const selectMetaDataEstacoes = createSelector(
  selectEstacaoState,
  fromEstacao.getMetaDataEstacoes
);

export const selectTotalEstacoes = createSelector(
  selectEstacaoState,
  fromEstacao.getTotalEstacoes
); 

export const selectLoadingEstacoes = createSelector(
  selectEstacaoState,
  fromEstacao.getLoadingEstacoes
); 

export const selectCurrentEstacaoIds = createSelector(
  selectEstacaoState,
  fromEstacao.getSelectedEstacaoIds
);

export const selectCurrentEstacoes = createSelector(
  selectEstacaoEntities,
  selectCurrentEstacaoIds,
  (estacaoEntities, estacaoIds) => {

    let list: Estacao[] = [];

    estacaoIds.forEach(element => {
      list.push(estacaoEntities[element]);
    });

    return list;
  }
);