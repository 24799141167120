import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { map } from 'rxjs/operators';
import { isString } from 'util';
import { Uf } from '../../../state/uf/uf.model';
import { UfFacade } from '../../../state/uf/uf.facade';

@Component({
  selector: 'app-uf-select',
  templateUrl: './uf-select.component.html',
  styleUrls: ['./uf-select.component.css']
})
export class UfSelectComponent implements OnInit {
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public ufCtrl = new FormControl();

  public filteredUfs$: Observable<Uf[]>;
  public selectedUfs: Uf[] = [];
  public allUfs: Uf[] = [];

  @ViewChild('ufInput', {static: false}) ufInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
  
  constructor(
    private ufFacade: UfFacade
  ) { 
    this.filteredUfs$ = this.ufFacade.all$.pipe(
      map(ufs => this.allUfs = ufs)
    );

    this.ufFacade.currentList$.subscribe(
      currentUfs => this.selectedUfs = currentUfs
    );

    this.ufCtrl.valueChanges.subscribe(
      search => {
        this.filteredUfs$ = this.filteredUfs$.pipe(
          map(() => search && isString(search)? this._filter(search) : this.allUfs.slice()),
          map(ufs => ufs.filter(uf => this.selectedUfs.indexOf(uf) === -1))
        )
      }
    );

  }

  ngOnInit() {
    
  } 

  remove(uf: Uf): void {
    this.ufFacade.unselectMany([uf.id]);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
   
    let selectedUf = this.allUfs.find(uf => uf.id == event.option.value);
    
    let norteFlag = 'false';
    let nordesteFlag = 'false';
    let centroesteFlag = 'false';
    let sudesteFlag = 'false';
    let sulFlag = 'false';


  //   switch (event.option.value) {
  //     case 'NORTE':
  //       if (norteFlag)
  //         this.ufFacade.unselectMany(['AC', 'PA', 'RO', 'RR', 'TO', 'AM', 'AP']);
  //       norteFlag = 'true';
  //       if (!this.matAutocomplete.options.filter(option => option.value == "AC")[0]['_selected']) this.ufFacade.selectMany(['AC']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "PA")[0]['_selected']) this.ufFacade.selectMany(['PA']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "RO")[0]['_selected']) this.ufFacade.selectMany(['RO']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "RR")[0]['_selected']) this.ufFacade.selectMany(['RR']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "TO")[0]['_selected']) this.ufFacade.selectMany(['TO']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "AM")[0]['_selected']) this.ufFacade.selectMany(['AM']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "AP")[0]['_selected']) this.ufFacade.selectMany(['AP']);
  //       break;
  //     case 'NORDESTE':
  //       if (nordesteFlag)
  //         this.ufFacade.unselectMany(['AL', 'BA', 'CE', 'MA', 'PB', 'PE', 'PI', 'RN', 'SE']);
  //       nordesteFlag = 'true';
  //       if (!this.matAutocomplete.options.filter(option => option.value == "AL")[0]['_selected']) this.ufFacade.selectMany(['AL']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "BA")[0]['_selected']) this.ufFacade.selectMany(['BA']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "CE")[0]['_selected']) this.ufFacade.selectMany(['CE']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "MA")[0]['_selected']) this.ufFacade.selectMany(['MA']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "PB")[0]['_selected']) this.ufFacade.selectMany(['PB']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "PE")[0]['_selected']) this.ufFacade.selectMany(['PE']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "PI")[0]['_selected']) this.ufFacade.selectMany(['PI']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "RN")[0]['_selected']) this.ufFacade.selectMany(['RN']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "SE")[0]['_selected']) this.ufFacade.selectMany(['SE']);
  //       break;
  //     case 'CENTROESTE':
  //       if (centroesteFlag)
  //         this.ufFacade.unselectMany(['GO', 'MS', 'MT', 'DF']);
  //       centroesteFlag = 'true';
  //       if (!this.matAutocomplete.options.filter(option => option.value == "GO")[0]['_selected']) this.ufFacade.selectMany(['GO']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "MS")[0]['_selected']) this.ufFacade.selectMany(['MS']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "MT")[0]['_selected']) this.ufFacade.selectMany(['MT']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "DF")[0]['_selected']) this.ufFacade.selectMany(['DF']);
  //       break;
  //     case 'SUDESTE':
  //       if (sudesteFlag)
  //         this.ufFacade.unselectMany(['ES', 'MG', 'RJ', 'SP']);
  //       sudesteFlag = 'true';
  //       if (!this.matAutocomplete.options.filter(option => option.value == "ES")[0]['_selected']) this.ufFacade.selectMany(['ES']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "MG")[0]['_selected']) this.ufFacade.selectMany(['MG']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "RJ")[0]['_selected']) this.ufFacade.selectMany(['RJ']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "SP")[0]['_selected']) this.ufFacade.selectMany(['SP']);
  //       break;
  //     case 'SUL':
  //       if (sulFlag)
  //         this.ufFacade.unselectMany(['RS', 'SC', 'PR']);
  //       sulFlag = 'true';
  //       if (!this.matAutocomplete.options.filter(option => option.value == "RS")[0]['_selected']) this.ufFacade.selectMany(['RS']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "SC")[0]['_selected']) this.ufFacade.selectMany(['SC']);
  //       if (!this.matAutocomplete.options.filter(option => option.value == "PR")[0]['_selected']) this.ufFacade.selectMany(['PR']);
  //       break;
  //     default:
  //       if (this.selectedUfs.indexOf(selectedUf) !== -1)
  //         return;
  //       this.ufFacade.selectMany([selectedUf.id]);
  //       break;
  //     }
      
  //   this.ufInput.nativeElement.value = '';
  //   this.clickOut();
  // }
    if(this.selectedUfs.length<1){
    this.ufFacade.selectMany([selectedUf.id]); 
    this.ufInput.nativeElement.value = '';
    this.clickOut();
    }
    else{
    this.ufFacade.unselectMany([this.selectedUfs[0].id]);
    this.ufFacade.selectMany([selectedUf.id]);
    this.clickOut();
    }
  }

  private _filter(search: string): Uf[] {
    const filterValue = search.toLowerCase();
    return this.allUfs.filter(uf => uf.nome.toLowerCase().indexOf(filterValue) === 0);
  }

  clickOut(){

    let elementOut: HTMLElement = document.querySelector("#mat-chip-list-input-0") as HTMLElement;
    elementOut.click();
    let elementIn: HTMLElement = document.querySelector("#mat-chip-list-input-1") as HTMLElement;
    elementIn.click();

    let inp: HTMLInputElement = document.querySelector("#mat-chip-list-input-1") as HTMLInputElement;
    var ev = new Event('input');
    
    inp.value = '.';
    inp.dispatchEvent(ev);

    inp.value = '';
    inp.dispatchEvent(ev);
  }

}