import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NotificationsService } from '../../notifications/notifications.service';
import { Estacao } from './estacao.model';
import { Pagination, ApiResponse, paginationToString } from '../../generics/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class EstacaoService {
  
  model = 'rest/pcd/estacao';

  constructor(
    private http: HttpClient,
    private ns: NotificationsService
  ) { }

  getUrl() {
    return `${environment.apiEndpoint}${this.model}`;
  }

  getUrlForId(id) {
    return `${this.getUrl()}/${id}`;
  }

  all(instituicaoIds: number[], modelos: number[], uf: string) {
    if (uf == '')
    {
      //return this.http.get<Estacao[]>(this.getUrl() + '?instituicao-or='+instituicaoIds.join(',')+'&modelo-or='+modelos.join(',')+'&with=municipio.uf,sensor,instituicao&limit=no&orderBy=id');
      return this.http.get<Estacao[]>(this.getUrl() + '?instituicao-or='+instituicaoIds.join(',')+'&modelo-or='+modelos.join(',')+'&with=municipio.uf,sensor,instituicao&limit=no&orderBy=id&tipo_estacao-or=1,3');
    }
    else
    {
      //return this.http.get<Estacao[]>(this.getUrl() + '?instituicao-or='+instituicaoIds.join(',')+'&modelo-or=' +modelos.join(',')+ '&municipio-uf=' + uf + '&with=municipio.uf,sensor,instituicao&limit=no&orderBy=id');
      return this.http.get<Estacao[]>(this.getUrl() + '?instituicao-or='+instituicaoIds.join(',')+'&modelo-or=' +modelos.join(',')+ '&municipio-uf=' + uf + '&with=municipio.uf,sensor,instituicao&limit=no&orderBy=id&tipo_estacao-or=1,3');
    }
  }

  pick(id) {
    return this.http.get<Estacao>(this.getUrlForId(id));
  }  

  load(id) {
    return this.http.get<Estacao>(this.getUrlForId(id));
  }

  paginate(pagination: Pagination) {
    return this.http.get<ApiResponse<Estacao>>(this.getUrl() + '?' + paginationToString(pagination));
  }
}
