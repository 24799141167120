import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { TempoAgoraActions, TempoAgoraActionTypes } from './tempo-agora.actions';
import { TempoAgora } from './tempo-agora.model';

/**
 * Interface to the part of the Store containing TempoAgoraState
 * and other information related to TempoAgoraData.
 */
export interface TempoAgoraState extends EntityState<TempoAgora> {
  selectedId: string;
  loading: boolean;
}

export const adapter: EntityAdapter<TempoAgora> = createEntityAdapter<TempoAgora>({
  selectId: (tempoAgora: TempoAgora) => tempoAgora.codigo,
});

export const initialState: TempoAgoraState = adapter.getInitialState({
  selectedId: null,
  loading: false
});

export function tempoAgoraReducer(state = initialState, action: TempoAgoraActions): TempoAgoraState {

  switch (action.type) {
    case TempoAgoraActionTypes.PickTempoAgora: {
      return Object.assign({}, state, { loading: true });
    }
    case TempoAgoraActionTypes.TempoAgoraSelected: {
      return Object.assign({}, state, { loading: false, selectedId: action.payload });
    }
    case TempoAgoraActionTypes.TempoAgoraAdded: {
      return adapter.upsertOne(action.payload, Object.assign({}, state, { loading: false, selectedId: action.payload.codigo }));
    }
    default:
      return state;
  }
}

export const getSelectedTempoAgoraId = (state: TempoAgoraState) => state.selectedId;
export const getLoadingTempoAgora = (state: TempoAgoraState) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of tempoAgora ids
export const selectTempoAgoraIds = selectIds;

// select the dictionary of tempoAgora entities
export const selectTempoAgoraEntities = selectEntities;

// select the array of tempoAgora
export const selectAllTempoAgora = selectAll;

// select the total tempoAgora count
export const selectTempoAgoraTotal = selectTotal;
