import { Action } from '@ngrx/store';
import { TempoAgora } from './tempo-agora.model';

export enum TempoAgoraActionTypes {
  TempoAgoraAction = '[TempoAgora] Action',
  PickTempoAgora = '[TempoAgora] Pick Data',
  TempoAgoraLoaded = '[TempoAgora] Data Loaded',
  TempoAgoraAdded = '[TempoAgora] Added',
  TempoAgoraSelected = '[TempoAgora] Selected',
}

export class TempoAgoras implements Action {
  readonly type = TempoAgoraActionTypes.TempoAgoraAction;
}

export class PickTempoAgora implements Action {
  readonly type = TempoAgoraActionTypes.PickTempoAgora;
  constructor(public payload) { }
}

export class TempoAgoraLoaded implements Action {
  readonly type = TempoAgoraActionTypes.TempoAgoraLoaded;
  constructor(public payload: TempoAgora[]) { }
}

export class TempoAgoraAdded implements Action {
  readonly type = TempoAgoraActionTypes.TempoAgoraAdded;
  constructor(public payload: TempoAgora) { }
}

export class TempoAgoraSelected implements Action {
  readonly type = TempoAgoraActionTypes.TempoAgoraSelected;
  constructor(public payload: string) { }
}

export type TempoAgoraActions = TempoAgoras
  | PickTempoAgora
  | TempoAgoraLoaded
  | TempoAgoraAdded
  | TempoAgoraSelected
;