import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { Estacao } from '../../../state/estacao/estacao.model';
import { ChartLineComponent } from '../../chart/chart-line/chart-line.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../invervalo/intervalo-select/date.adapter';

@Component({
  selector: 'funceme-estacao-graphs',
  templateUrl: './estacao-graphs.component.html',
  styleUrls: ['./estacao-graphs.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class EstacaoGraphsComponent implements OnInit {

  @Input('estacao') estacao: Estacao;

  public sensores = [];
  public otherSensores = [];

  public horarioSelected: string = '24h';
  public diarioSelected: string = new Date().toISOString();
  public diarioSelectedSliced: string;
  public mensalSelected: string = '1a';

  public today: Date = new Date;

  @ViewChildren(ChartLineComponent) chartChildren !: QueryList<ChartLineComponent>;

  constructor() { 
    
  }

  ngOnInit() {
    this.sensores = this.estacao.sensor.map(sensor => Number(sensor.id));
    this.diarioSelectedSliced = this.diarioSelected.slice(0,10);
  }

  public horarioChanged() {
    this.chartChildren.filter(graph => graph.target.includes('hourly')).map(graph => graph.reload(this.estacao.id, this.horarioSelected));
  }

  public diarioChanged(event:Date, picker) {
    this.diarioSelected = event.toISOString()
    this.diarioSelectedSliced = this.diarioSelected.slice(0,10)
    this.chartChildren.filter(graph => graph.target.includes('daily')).map(graph => graph.reload(this.estacao.id,this.diarioSelectedSliced));
    picker.close();
  }

  public mensalChanged() {
    this.chartChildren.filter(graph => graph.target.includes('monthly')).map(graph => graph.reload(this.estacao.id,this.mensalSelected));
  }
}