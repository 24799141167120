import { Component, Input, OnInit } from '@angular/core';
import { EstacaoFacade } from '../../../state/estacao/estacao.facade';
import { Estacao, EstacaoGroup } from '../../../state/estacao/estacao.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'funceme-estacao-select',
  templateUrl: './estacao-select.component.html',
  styleUrls: ['./estacao-select.component.css']
})
export class EstacaoSelectComponent implements OnInit {

  public estacaoControl = new FormControl();
  public estacoesGroups: EstacaoGroup[];
  public estacaoIdSelected: string;

  @Input('uf') uf: string = ''

  constructor(
    private estcacaoFacade: EstacaoFacade
  ) { 
    this.estcacaoFacade.all$.subscribe(values => this.estacoesGroups = this.makeGroups(values));
    this.estcacaoFacade.current$.subscribe(value => {
      if (!value)
        return;
      if (value.id != null)
      {
        this.estacaoIdSelected = value.id + '';
      }
    });
  }

  ngOnInit() {
    this.estcacaoFacade.loadEstacoes({
      instituicaoIds: [1,41],
      modelos: [1],
      uf: this.uf
    });
  }

  changedEstacao(event) {
    this.estcacaoFacade.select(event.value);
  }

  private makeGroups(estacoes: Estacao[]): EstacaoGroup[] 
  {
    let groups:EstacaoGroup[] = [];
    let UFs: string[] = [];


    for (let i = estacoes.length - 1; i >= 0 ; i--)
    {
      let obj = estacoes[i];
      if (obj == undefined){
        estacoes.splice(i, 1);
      }
    }
    //console.log(estacoes);


    estacoes.map(value => value.municipio.uf).forEach(element => {
      if (!UFs.includes(element))
        UFs.push(element);
    });

    UFs.forEach(
      element => {
        groups.push({
          disabled: false,
          nome: element,
          estacoes: estacoes.filter(value => value.municipio.uf == element).sort((a, b) => {
            return (a.nome > b.nome)? 1 : -1; 
          })
        });
      }
    );

    return groups.sort((a, b) => {
      return (a.nome > b.nome)? 1 : -1;
    });
  }
}
