import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { ZmatChartSchema, ZmatChartSerieSchema } from '../../../generics/zmat-chart/zmap-chart.schema';
import * as moment from 'moment';
import { ZmatChartTimeseriesService } from '../../../generics/zmat-chart/zmat-chart-timeseries.service';

@Component({
  selector: 'estacao-serie-table',
  templateUrl: './estacao-serie-table.component.html',
  styleUrls: ['./estacao-serie-table.component.css']
})
export class EstacaoSerieTableComponent {

  private _data = new BehaviorSubject<ZmatChartSerieSchema[]>([]);

  public displayedColumns: string[] = [];
  public columnTitles = [];

  @Input()
  set data(value) {
      this._data.next(value);
  };

  get data() {
    return this._data.getValue();
  }

  @Input() schema: ZmatChartSchema;

  public dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private timeserieService: ZmatChartTimeseriesService
  ) { 
    this.dataSource.data = [];
  }

  ngOnInit() 
  {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = [];

    this.columnTitles = this.schema.datasets.map(value => value.label);
    
    this._data.subscribe(values => {

      if (!values || values.length == 0)
        return;

      let data = this.timeserieService.seriesToArray(values);

      this.displayedColumns = Object.keys(data[0]);

      this.dataSource.data = data.reverse();
    });
  }
}
