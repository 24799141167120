import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NotificationsService } from '../../notifications/notifications.service';
import { EstacaoSerie } from './estacao-serie.model';
import { Estacao } from '../estacao/estacao.model';

@Injectable({
  providedIn: 'root'
})
export class EstacaoSerieMensalService {
  
  model = 'rpc/v1/dado-sensor-mensal';

  constructor(
    private http: HttpClient,
    private ns: NotificationsService
  ) { }

  getUrl() {
    return `${environment.apiEndpoint}${this.model}`;
  }

  pick(estacaoId: number, sensores: number[], periodo: string, data_inicio?: string, data_fim?: string) {
    
    let url = this.getUrl() + `?estacao=${estacaoId}&sensor=${sensores.join(',')}`;

    if (data_inicio && data_fim)
      url += `&data_inicio=${data_inicio}&data_fim=${data_fim}`;
    else 
      url += `&periodo=${periodo}`;

    return this.http.get<EstacaoSerie[]>(url);
  }  
}
