import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartLineComponent } from './chart-line/chart-line.component';
import { ChartArrowComponent } from './chart-arrow/chart-arrow.component';
import { MaterialModule } from '@funceme/material';

@NgModule({
  declarations: [ChartLineComponent, ChartArrowComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [ChartLineComponent]

})
export class ChartModule { }
