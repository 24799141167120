import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import * as ConfigActions from './config.actions';
import { ConfigState } from './config.reducer';
import { selectViewMode, selectFullscreen } from './config.selectors';

@Injectable({ providedIn: 'root' })
export class ConfigFacade {
  
  viewMode$   = this.store.pipe(select(selectViewMode));
  fullscreen$ = this.store.pipe(select(selectFullscreen));

  constructor(private store: Store<ConfigState>, private actions$: ActionsSubject) {}

  toggleFullscreen() {
    this.store.dispatch(new ConfigActions.ToggleFullscreenAction());
  }

  mini() {
    this.store.dispatch(new ConfigActions.MiniAppSelected());
  }

  large() {
    this.store.dispatch(new ConfigActions.LargeAppSelected());
  }
}
