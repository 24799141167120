import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import * as EstacaoSerieActions from './estacao-serie.actions';
import { EstacaoSerieState } from './estacao-serie.reducer';
import { selectAllEstacaoSeries, isLoadingEstacaoSerie} from './estacao-serie.selectors';
import { EstacaoSerie, EstacaoSeriePayload } from './estacao-serie.model';

@Injectable({ providedIn: 'root' })
export class EstacaoSerieFacade {
  allEstacaoSeries$     = this.store.pipe(select(selectAllEstacaoSeries));
  loading$              = this.store.pipe(select(isLoadingEstacaoSerie));

  constructor(private store: Store<EstacaoSerieState>, private actions$: ActionsSubject) {}

  selectEstacaoSerie(estacaoSerie: EstacaoSerie) {
    this.store.dispatch(new EstacaoSerieActions.EstacaoSeriePicked(estacaoSerie));
  }

  pickEstacaoSerie(estacaoPayload: EstacaoSeriePayload) {
    this.store.dispatch(new EstacaoSerieActions.PickEstacaoSeries(estacaoPayload));
  }
}
