import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { RootComponent } from './layout/root/root.component';
import { MiniComponent } from './pages/mini/mini.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { TempoAgoraMiniComponent } from './pages/tempo-agora-mini/tempo-agora-mini.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { EstacaoIndexComponent } from 'libs/core-pcd/src/lib/components/estacao/estacao-index/estacao-index.component';


const routes: Routes = [
  {
    path: '',
    component: RootComponent, 
    children:  [
      { 
        path: '', 
        redirectTo: 'home', 
        pathMatch: 'full' 
      },

      { path: 'home',                         component: HomeComponent },
      { path: 'contato',                      component: ContatoComponent },
      { path: 'estacoes',                     component: EstacaoIndexComponent },
      { path: 'reports/:estacaoId',           component: ReportsComponent },
    ]
},
{
  path: 'mini/pcd',
  component: MiniComponent,
  pathMatch: 'full'
},
{
  path: 'mini/tempo-agora/:estacaoId',
  component: TempoAgoraMiniComponent,
  pathMatch: 'full'
},   
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
