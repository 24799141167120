import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { TempoAgoraService } from './tempo-agora.service';

import {
  TempoAgoraActionTypes, PickTempoAgora, TempoAgoraAdded, TempoAgoraSelected,
} from './tempo-agora.actions';
import { TempoAgoraState } from './tempo-agora.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { TempoAgora } from './tempo-agora.model';

@Injectable({providedIn: 'root'})
export class TempoAgoraEffects 
{
  //@Effect() effect$ = this.actions$.ofType(TempoAgoraActionTypes.TempoAgoraAction);

  @Effect()
  pickTempoAgora$ = this.dataPersistence.fetch(TempoAgoraActionTypes.PickTempoAgora, {
    run: (action: PickTempoAgora, state: any) => {

      if (this.isValid(state.tempoAgora.entities[action.payload]))
         return new TempoAgoraSelected(state.tempoAgora.entities[action.payload].codigo);
    
      var obj = this.tempoAgoraService
      .pick(action.payload);

      if (obj != null)
      {
        return this.tempoAgoraService
          .pick(action.payload)
          .pipe(
            map((res) => new TempoAgoraAdded(
              Object.assign(
                res['data']['list'], {
                  _cached_at: new Date(res['meta']['cache']['cached_at']['date']),
                  _expires_in: res['meta']['cache']['expires_in'],
                  _queue_in: res['meta']['cache']['queue_in'],
                  _loaded_at: new Date()
                }
              )
            ))
          );
        }
        else
        {
          return null;
        }
    },
    onError: (action: PickTempoAgora, error) => {
      this.notifyError.emit(error.message);
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<TempoAgoraState>,
    private tempoAgoraService: TempoAgoraService,
    private notifyError: NotificationsService
  ) {}

  private isValid(tempoAgora: TempoAgora) {

    if (!tempoAgora)
      return false;

    let lowerTime = (tempoAgora._queue_in < tempoAgora._expires_in)? tempoAgora._queue_in : tempoAgora._expires_in;

    let now = new Date;
    let expirationTime = new Date(tempoAgora._cached_at.getTime() * 1000);
    expirationTime.setSeconds(expirationTime.getSeconds() + lowerTime);

    return now.getTime() < expirationTime.getTime();
  }
}
