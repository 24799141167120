import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSelect } from '@angular/material';

export interface EstacaoTipo {
  id: number;
  nome: string;
}

@Component({
  selector: 'estacao-tipo-select',
  templateUrl: './estacao-tipo-select.component.html',
  styleUrls: ['./estacao-tipo-select.component.css']
})
export class EstacaoTipoSelectComponent implements OnInit {

  public data: EstacaoTipo[] = [
    {id: 1, nome: 'Agrometeorológica'},
    {id: 2, nome: 'Hidrológica'},    
    {id: 3, nome: 'Meteorologica'},   
    {id: 4, nome: 'Pluviométrica'}  
  ];

  private _value = new BehaviorSubject<any>({});  
  
  @Input()
  set value(value) {
    this._value.next(value);
  };

  get value() {
    return this._value.getValue();
  }

  @ViewChild(MatSelect, {static: true}) select: MatSelect;

  @Output() changed: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public itemChanged(event)
  { 
    this.changed.emit(event.value);
  }

  ngOnInit() {
    this._value.subscribe(value => {
      this.select.value = value;
    })
  }

  ngOnDestroy() {
  }
}