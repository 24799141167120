import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import * as EstacaoActions from './estacao.actions';
import { EstacaoState } from './estacao.reducer';
import { selectAllEstacoes, selectCurrentEstacao, selectPickingEstacaoId, selectCurrentEstacoes, selectMetaDataEstacoes, selectTotalEstacoes, selectLoadingEstacoes } from './estacao.selectors';
import { Estacao, EstacaoPayload } from './estacao.model';
import { GenericFacade } from '../../generics/generic-facade';
import { Pagination } from '../../generics/pagination.model';

@Injectable({ providedIn: 'root' })
export class EstacaoFacade implements GenericFacade {
  all$      = this.store.pipe(select(selectAllEstacoes));
  current$  = this.store.pipe(select(selectCurrentEstacao));
  picking$  = this.store.pipe(select(selectPickingEstacaoId));
  currentList$ = this.store.pipe(select(selectCurrentEstacoes));
  metaData$ = this.store.pipe(select(selectMetaDataEstacoes));
  total$ = this.store.pipe(select(selectTotalEstacoes));
  loading$ = this.store.pipe(select(selectLoadingEstacoes));

  constructor(private store: Store<EstacaoState>, private actions$: ActionsSubject) {}

  loadEstacoes(estacaoPayload: EstacaoPayload) {
    this.store.dispatch(new EstacaoActions.LoadEstacoes(estacaoPayload));
  }

  pick(id: number) {
    this.store.dispatch(new EstacaoActions.PickEstacao(id));
  }
  
  selectOne(municipioId: number) {
    this.store.dispatch(new EstacaoActions.EstacaoSelected(municipioId));
  }

  selectMany(municipioIds: number[]) {
    this.store.dispatch(new EstacaoActions.EstacoesSelected(municipioIds));
  }

  unselectMany(municipioIds: number[]) {
    this.store.dispatch(new EstacaoActions.EstacoesUnselected(municipioIds));
  } 

  paginate(pagination: Pagination) {
    this.store.dispatch(new EstacaoActions.PaginateEstacoes(pagination));
  }

  select(id: number) {
    this.selectOne(id);
  }

  load(reset: boolean) {}
  destroy(id: number) {};
  save(model: any) {};
  index() {};
  edit(id: number) {};
  show(id: number) {};
  create(): void {};
  refresh(): void {};
}