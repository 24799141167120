import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import * as SensorActions from './sensor.actions';
import { SensorState } from './sensor.reducer';
import { selectAllSensores, selectFilteredSensorIds, selectCurrentSensor, selectSoughtSensor, selectSensorOperador, selectCurrentSensores, selectMetaDataSensores, selectTotalSensores, selectLoadingSensores } from './sensor.selectors';
import { GenericFacade } from '../../generics/generic-facade';
import { Pagination } from '../../generics/pagination.model';

@Injectable({ providedIn: 'root' })
export class SensorFacade implements GenericFacade {
  all$          = this.store.pipe(select(selectAllSensores));
  filteredIds$  = this.store.pipe(select(selectFilteredSensorIds));
  current$      = this.store.pipe(select(selectCurrentSensor));
  sought$       = this.store.pipe(select(selectSoughtSensor));
  operador$     = this.store.pipe(select(selectSensorOperador));    
  currentList$  = this.store.pipe(select(selectCurrentSensores));
  metaData$     = this.store.pipe(select(selectMetaDataSensores));
  total$        = this.store.pipe(select(selectTotalSensores));
  loading$      = this.store.pipe(select(selectLoadingSensores));

  constructor(private store: Store<SensorState>, private actions$: ActionsSubject) {}

  selectOne(id: number) {
    this.store.dispatch(new SensorActions.SensorSelected(id));
  }

  soughtSensor(sensorId: number) {
    this.store.dispatch(new SensorActions.SensorSought(sensorId));
  }

  changeOperador(operador: string) {
    this.store.dispatch(new SensorActions.SensorOperadorChanged(operador));
  }

  load(reset: boolean = false) {
    this.store.dispatch(new SensorActions.LoadSensores(reset));
  }

  filterMany(municipioIds: number[]) {
    this.store.dispatch(new SensorActions.SensoresFiltered(municipioIds));
  }

  unfilterMany(municipioIds: number[]) {
    this.store.dispatch(new SensorActions.SensoresUnfiltered(municipioIds));
  } 

  selectMany(municipioIds: number[]) {
    this.store.dispatch(new SensorActions.SensoresSelected(municipioIds));
  }

  unselectMany(municipioIds: number[]) {
    this.store.dispatch(new SensorActions.SensoresUnselected(municipioIds));
  } 

  paginate(pagination: Pagination) {
    this.store.dispatch(new SensorActions.PaginateSensores(pagination));
  }

  select(id: number) {
    this.selectOne(id);
  }

  pick(id: number) {};
  destroy(id: number) {};
  save(model: any) {};
  index() {};
  edit(id: number) {};
  show(id: number) {};
  create(): void {};
  refresh(): void {};
}
