import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NotificationsService } from '../../notifications/notifications.service';
import { Instituicao } from './instituicao.model';
import { Pagination, ApiResponse, paginationToString } from '../../generics/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class InstituicaoService {
  
  model = 'rest/adm/instituicao';

  constructor(
    private http: HttpClient,
    private ns: NotificationsService
  ) { }

  getUrl() {
    return `${environment.apiEndpoint}${this.model}`;
  }

  getUrlForId(id) {
    return `${this.getUrl()}/${id}`;
  }

  all() {
    return this.http.get<Instituicao[]>(this.getUrl() + '?limit=no&orderBy=id');
  }

  load(id) {
    return this.http.get<Instituicao>(this.getUrlForId(id));
  }

  pick(id) {
    return this.http.get<Instituicao>(this.getUrlForId(id));
  }  

  paginate(pagination: Pagination) {
    return this.http.get<ApiResponse<Instituicao>>(this.getUrl() + '?' + paginationToString(pagination));
  }

  create(instituicao: Instituicao) {
    
    let request$ = this.http.post(this.getUrl(), instituicao);

    request$.subscribe(
      response => {
        this.ns.emit('Created');
      },
      error => {
        this.ns.emit('[ERRO] ' + error.error.message);
      }
    );

    return request$;
  }

  update(instituicao: Instituicao) {
    return this.http.put(this.getUrlForId(instituicao.id), { ...instituicao, _method: 'PUT' });
  }

  delete(instituicao: Instituicao) {

    let request$ = this.http.delete(this.getUrlForId(instituicao.id));

    request$.subscribe(
      response => {
        this.ns.emit(response['message']);
      },
      error => {
        this.ns.emit('[ERRO] ' + error.error.message);
      }
    );

    return request$;
  }
}
