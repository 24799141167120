import { Action } from '@ngrx/store';
import { Sensor } from './sensor.model';
import { Pagination, ApiResponse } from '../../generics/pagination.model';

export enum SensorActionTypes {
  SensoresAction = '[Sensores] Action',
  SensorSelected = '[Sensores] Selected',
  LoadSensores = '[Sensores] Load Data',
  SensoresLoaded = '[Sensores] Data Loaded',
  SensorSought = '[Sensores] Sought',
  SensorOperadorChanged = '[Sensores] Operador changed',
  SensoresFiltered = '[Sensores] Filtered',
  SensoresUnfiltered = '[Sensores] Unfiltered',
  SensoresSelected = '[Sensores] Selected',
  SensoresUnselected = '[Sensores] Unselected',
  PaginateSensores = '[Sensores] Paginate',
  SensoresPaginated = '[Sensores] Paginated',
  SensoresRequestDone = '[Sensores] RequestDone',
  SensoresRequestFailed = '[Sensores] Request Failed',
}

export class Sensores implements Action {
  readonly type = SensorActionTypes.SensoresAction;
}

export class SensorSelected implements Action {
  readonly type = SensorActionTypes.SensorSelected;
  constructor(public payload) { }
}

export class LoadSensores implements Action {
  readonly type = SensorActionTypes.LoadSensores;
  constructor(public reset: boolean) { }
}

export class SensoresLoaded implements Action {
  readonly type = SensorActionTypes.SensoresLoaded;
  constructor(public payload: Sensor[]) { }
}

export class SensorSought implements Action {
  readonly type = SensorActionTypes.SensorSought;
  constructor(public payload) { }
}

export class SensorOperadorChanged implements Action {
  readonly type = SensorActionTypes.SensorOperadorChanged;
  constructor(public payload) { }
}

export class SensoresFiltered implements Action {
  readonly type = SensorActionTypes.SensoresFiltered;
  constructor(public payload: number[]) { }
}

export class SensoresUnfiltered implements Action {
  readonly type = SensorActionTypes.SensoresUnfiltered;
  constructor(public payload: number[]) { }
}

export class SensoresSelected implements Action {
  readonly type = SensorActionTypes.SensoresSelected;
  constructor(public payload: number[]) { }
}

export class SensoresUnselected implements Action {
  readonly type = SensorActionTypes.SensoresUnselected;
  constructor(public payload: number[]) { }
}

export class PaginateSensores implements Action {
  readonly type = SensorActionTypes.PaginateSensores;
  constructor(public payload: Pagination) { }
}

export class SensoresPaginated implements Action {
  readonly type = SensorActionTypes.SensoresPaginated;
  constructor(public payload: ApiResponse<Sensor>) { }
}

export class SensoresRequestDone implements Action {
  readonly type = SensorActionTypes.SensoresRequestDone;
  constructor() { }
}

export class SensoresRequestFailed implements Action {
  readonly type = SensorActionTypes.SensoresRequestFailed;
  constructor() { }
}

export type SensorActions = Sensores
  | SensorSelected
  | LoadSensores
  | SensoresLoaded
  | SensorSought
  | SensorOperadorChanged
  | SensoresFiltered
  | SensoresUnfiltered
  | SensoresSelected
  | SensoresUnselected
  | PaginateSensores
  | SensoresPaginated
  | SensoresRequestDone
  | SensoresRequestFailed
;