import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SensorFacade } from '../../../state/sensor/sensor.facade';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'funceme-sensor-modal',
  templateUrl: './sensor-modal.component.html',
  styleUrls: ['./sensor-modal.component.css']
})
export class SensorModalComponent implements OnInit {

  public filteredSensors = [];
  public allSensors

  constructor(public dialogRef: MatDialogRef<SensorModalComponent>, @Inject(MAT_DIALOG_DATA) public data,private SensorFacade: SensorFacade)
  {
    combineLatest(
      this.SensorFacade.filteredIds$,
      this.SensorFacade.all$
      ).subscribe(
        ([filtered,all]) => {
          this.allSensors = all.sort((a,b) => a.descricao+a.unidade > b.descricao+b.unidade ? 1 : -1);
          this.filteredSensors = all.filter(value => filtered.find(v => v == value.id));

          const idsToKeep = [2, 3, 4, 6, 7, 8, 22, 20, 31];
          this.allSensors = this.allSensors.filter(element => !idsToKeep.includes(element.id));

          //remove item code 21 - Radição Incidente Total (kJ/m²)
          /*this.allSensors.forEach((element,index)=>{
            if(element.id == '21')
              this.allSensors.splice(index,1);
          });
          this.filteredSensors.forEach((element,index)=>{
            if(element.id == '21')
              this.filteredSensors.splice(index,1);
          });*/
        }
      )
  }

  ngOnInit() {
    // this.SensorFacade.all$.subscribe(
    //   filtered => {
    //     console.log(filtered);
    //   }
    // )
  }

  checked(id: number) {
    // console.log(this.filteredSensors.includes(id))
    return this.filteredSensors.find(sensor => sensor.id == id);
  }

  change(sensor, checked: boolean) {
    console.log(checked);
    if (checked)
      this.SensorFacade.filterMany([Number(sensor.id)]);
    else
      this.SensorFacade.unfilterMany([Number(sensor.id)]);
  }

  public close(): void
  {
      this.dialogRef.close();
  }

}
