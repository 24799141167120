import { Instituicao } from '../instituicao/instituicao.model';
import { Sensor } from '../sensor/sensor.model';

export interface Municipio {
    id: number;
    nome: string,
    uf: string
}

export interface Estacao {
    id: number;
    nome: string,
    tipo_estacao: string,
    modelo: number,
    altitude: string,
    codigo_origem: string;
    ins_codigo: number;
    dt_ult_atual: string,
    rua: string,
    cep: string,
    localizacao: string,
    geom: any,
    municipio: Municipio,
    instituicao: Instituicao,
    sensor: Sensor[]
}

export const emptyEstacao: Estacao = {
    id: null,
    nome: '',
    tipo_estacao: '',
    modelo: null,
    altitude: null,
    codigo_origem: null,
    ins_codigo: null,
    dt_ult_atual: null,
    rua: null,
    cep: null,
    localizacao: null,
    geom: null,
    municipio: null,
    instituicao: null,
    sensor: null
}

export interface EstacaoGroup {
    disabled?: boolean;
    nome: string;
    estacoes: Estacao[];
}

export interface EstacaoPayload {
    instituicaoIds: number[];
    modelos: number[];
    uf: string;
}