import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { InstituicaoActions, InstituicaoActionTypes } from './instituicao.actions';
import { Instituicao } from './instituicao.model';
import { ApiMetaData } from '../../generics/pagination.model';

/**
 * Interface to the part of the Store containing InstituicaoState
 * and other information related to InstituicaoData.
 */
export interface InstituicaoState extends EntityState<Instituicao> {
  selectedId: number | null;
  selectedIds: number[];
  pickingId: number | null;
  soughtIds: number[];
  loaded_at: Date | null;  
  sought_at: Date | null;    
  loading: boolean;
  meta_data: ApiMetaData;
  total: number,
  last_pagination: string
}

export const adapter: EntityAdapter<Instituicao> = createEntityAdapter<Instituicao>({
  selectId: (instituicao: Instituicao) => instituicao.id,
});

export const initialState: InstituicaoState = adapter.getInitialState({
  selectedId: null,
  selectedIds: [],
  pickingId: null,
  soughtIds: [],
  loaded_at: null, 
  sought_at: null,   
  loading: null,
  meta_data: null,
  total: null,
  last_pagination: null
});

export function instituicaoReducer(state = initialState, action: InstituicaoActions): InstituicaoState {

  switch (action.type) {    

    case InstituicaoActionTypes.InstituicaoSelected: {
      return Object.assign({}, state, { selectedId: action.payload, pickingId: null });
    }

    case InstituicaoActionTypes.InstituicaoUnselected: {
      return Object.assign({}, state, { selectedId: null, pickingId: null });
    }

    case InstituicaoActionTypes.InstituicoesSelected: {
      return Object.assign({}, state, { selectedIds: state.selectedIds.concat(action.payload) });
    }

    case InstituicaoActionTypes.InstituicoesUnselected: {
      return Object.assign({}, state, { 
        selectedIds: state.selectedIds.filter(id => !action.payload.includes(id))
      });
    }

    case InstituicaoActionTypes.InstituicoesSought: {
      return Object.assign({}, state, { soughtIds: action.payload.filter((item, pos) => action.payload.indexOf(item) == pos) });
    }

    case InstituicaoActionTypes.InstituicoesLoaded: {
      return adapter.addAll(action.payload, state);
    }

    case InstituicaoActionTypes.InstituicaoAdded: {
      return adapter.addOne(action.payload, state);
    }

    case InstituicaoActionTypes.InstituicaoUpdated: {
      return adapter.upsertOne(action.payload, state);
    }

    case InstituicaoActionTypes.InstituicaoDeleted: {
      return adapter.removeOne(action.payload.id, state);
    }

    default:
      return state;
  }
}

export const getSelectedInstituicaoId = (state: InstituicaoState) => state.selectedId;
export const getSelectedInstituicaoIds = (state: InstituicaoState) => state.selectedIds;
export const getSoughtInstituicaoIds = (state: InstituicaoState) => state.soughtIds;
export const getMetaDataInstituicoes = (state: InstituicaoState) => state.meta_data;
export const getTotalInstituicoes = (state: InstituicaoState) => state.total;
export const getLoadingInstituicoes = (state: InstituicaoState) => state.loading;
export const getPickingInstituicaoId  = (state: InstituicaoState) => state.pickingId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of instituicao ids
export const selectInstituicaoIds = selectIds;

// select the dictionary of instituicao entities
export const selectInstituicaoEntities = selectEntities;

// select the array of instituicao
export const selectAllInstituicoes = selectAll;

// select the total instituicao count
export const selectInstituicaoTotal = selectTotal;
