import { Component, OnInit, Inject } from '@angular/core';
import { EstacaoSerie, EstacaoSeriePayload } from '../../../state/estacao-serie/estacao-serie.model';
import { Pagination } from '../../../generics/pagination.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatTabChangeEvent } from '@angular/material';
import { Subscription } from 'rxjs';
import { EstacaoSerieFacade } from '../../../state/estacao-serie/estacao-serie.facade';
import { Estacao } from '../../../state/estacao/estacao.model';
import { EstacaoFacade } from '../../../state/estacao/estacao.facade';
import { DatepickerSchema } from '../../../generics/zmat-datepicker/zmat-datepicker.schema'
import { ZmatChartSchema, ZmatChartTypes, ZmatChartSerieSchema } from '../../../generics/zmat-chart/zmap-chart.schema';
import { parserToZmatChart, getEstacaoSerieIdFromPayload } from '../../../state/estacao-serie/estacao-serie.adapter';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DownloadCSVService } from '../../../generics/downloadCSV.service';
import { ZmatChartTimeseriesService } from '../../../generics/zmat-chart/zmat-chart-timeseries.service';

@Component({
  selector: 'funceme-estacao-serie-modal',
  templateUrl: './estacao-serie-modal.component.html',
  styleUrls: ['./estacao-serie-modal.component.css']
})
export class EstacaoSerieModalComponent implements OnInit {

  public estacao: Estacao;

  public filters: any[] = [];
  
  private subscriptions = new Subscription();

  public loading: boolean = false;

  public postoNome: string = '';
  public postoId: string = '';

  public selectedTab: string = 'last';

  public optionSelected: string = 'hourly';
  public periodoSelected: string = '0d';
  public sensorSelected: string = 'temp';

  public daySchema: DatepickerSchema;  
  public monthSchema: DatepickerSchema;  
  public yearSchema: DatepickerSchema;  

  public dados: ZmatChartSerieSchema[];
  
  private payload: EstacaoSeriePayload = {
    estacaoId: null,
    sensores: this.getSensorCodes(this.sensorSelected),
    periodo: this.periodoSelected
  };

  public chartSchemaPluvio: ZmatChartSchema;
  public chartSchemaTemp: ZmatChartSchema;
  public chartSchemaUmidade: ZmatChartSchema;
  public chartSchemaPressao: ZmatChartSchema;
  public chartSchemaOrvalho: ZmatChartSchema;

  private day: Moment;
  private month: Moment;
  private year: Moment;

  public isChartVisible: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<EstacaoSerieModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EstacaoSerie,
    private facade: EstacaoSerieFacade,
    private estacaoFacade: EstacaoFacade,
    private downloadService: DownloadCSVService,
    private timeserieService: ZmatChartTimeseriesService
  ) { 
    this.daySchema = {
      placeholder: 'Data referência',
      value: new Date(), // 7 days ago
      max: new Date()
    }

    this.monthSchema = {
      placeholder: 'Mês referência',
      value: new Date(),
      max: new Date()
    }

    this.yearSchema = {
      placeholder: 'Ano referência',
      value: new Date(),
      max: new Date()
    }

    this.chartSchemaPluvio = {
      title: 'Pluviometria',
      type: ZmatChartTypes.Bar,
      target: 'chart-pluvio',
      datasets: [
        {
          color: '#4682B4',
          label: 'Precipitação Pluviométrica'       
        }
      ]
    };

    this.chartSchemaTemp = {
      title: 'Temperatura',
      type: ZmatChartTypes.Line,
      target: 'chart-temp',
      datasets: [
        { color: '#696969',
          label: 'Temperatura Mèdia'
        }, { 
          color: '#FC4400',
          label: 'Temperatura Máxima'
        }, {
          color: '#24BDFD',
          label: 'Temperatura Mínima'
        }
      ]
    }

    this.chartSchemaUmidade = {
      title: 'Umidade',
      type: ZmatChartTypes.Line,
      target: 'chart-temp',
      datasets: [
        { color: '#696969',
          label: 'Umidade Mèdia'
        }, { 
          color: '#FC4400',
          label: 'Umidade Máxima'
        }, {
          color: '#24BDFD',
          label: 'Umidade Mínima'
        }
      ]
    };

    this.chartSchemaOrvalho = {
      title: 'Ponto de Orvalho',
      type: ZmatChartTypes.Line,
      target: 'chart-temp',
      datasets: [
        { color: '#696969',
          label: 'Ponto de Orvalho Mèdio'
        }, { 
          color: '#FC4400',
          label: 'Ponto de Orvalho Máximo'
        }, {
          color: '#24BDFD',
          label: 'Ponto de Orvalho Mínimo'
        }
      ]
    };

    this.chartSchemaPressao = {
      title: 'Pressão Atmosférica',
      type: ZmatChartTypes.Line,
      target: 'chart-temp',
      datasets: [
        { color: '#696969',
          label: 'Pressão Atmosférica Mèdia'
        }, { 
          color: '#FC4400',
          label: 'Pressão Atmosférica Máxima'
        }, {
          color: '#24BDFD',
          label: 'Pressão Atmosférica Mínima'
        }
      ]
    }

    this.subscriptions.add(
      this.facade.loading$.subscribe(value => this.loading = value)
    ).add(
      this.facade.allEstacaoSeries$.subscribe(values => {
        if (!this.payload.estacaoId)
          return;
        
        let serieId = getEstacaoSerieIdFromPayload(this.payload);
        let serie = values.filter(value => value.id == serieId);

        if(serie.length == 1)
          this.dados = parserToZmatChart(serie[0]);
      })
    )
    .add(
      this.estacaoFacade.current$.subscribe(value => {
        if (!value || !value.id) 
          return;

        this.estacao = value;
        this.payload.estacaoId = this.estacao.id;
        this.getSerie();
      })
    );
  }

  ngOnInit() {    
    this.estacaoFacade.select(Number(this.data.id)); 
  }

  ngOnDestroy()
  {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
  }

  tabChanged($event: MatTabChangeEvent) 
  {
    if ($event.index == 0)
      this.isChartVisible = true;
    else   
      this.isChartVisible = false;
  }

  sensorChanged($event) {
    this.sensorSelected = $event;
    this.payload.sensores = this.getSensorCodes(this.sensorSelected);
    this.getSerie();
  }

  intervaloChanged($event) 
  {
    this.periodoSelected = $event;

    if (this.optionSelected == 'hourly') {

      let daysShift = parseInt(this.periodoSelected.replace('d', ''));

      this.payload.data_inicio = this.day
        .clone()
        .subtract(daysShift, 'days')
        .startOf('day')
        .format("YYYY-MM-DD HH:mm:ss");;
      
      this.payload.data_fim = this.day
        .endOf('day')
        .format("YYYY-MM-DD HH:mm:ss");

    } else if (this.optionSelected == 'daily') {

      let monthsShift = parseInt(this.periodoSelected.replace('m', ''));

      this.payload.data_inicio = this.month
        .clone()
        .subtract(monthsShift, 'months')
        .startOf('month')
        .format("YYYY-MM-DD HH:mm:ss");
      
      this.payload.data_fim = this.month
        .endOf('month')
        .format("YYYY-MM-DD HH:mm:ss");

    } else if (this.optionSelected == 'monthly') {

      let yearsShift = parseInt(this.periodoSelected.replace('a', ''));
       
      this.payload.data_inicio = this.year
        .clone()
        .subtract(yearsShift, 'years')        
        .startOf('year')
        .format("YYYY-MM-DD HH:mm:ss");

      this.payload.data_fim = this.year
        .endOf('year')
        .format("YYYY-MM-DD HH:mm:ss");
    }
    this.getSerie();
  }

  optionChanged($event) 
  {
    this.optionSelected = $event.value;

    switch (this.optionSelected) {
      case 'hourly':    
        this.periodoSelected = '0d';
      break;
      case 'daily':   
        this.periodoSelected = '0m';
      break;
      case 'monthly':  
        this.periodoSelected = '0a';
      break;
    }

    this.payload.periodo = this.periodoSelected;
    
    this.getSerie();
  }

  private getSerie() 
  {
    this.dados = null;
    this.facade.pickEstacaoSerie(this.payload);
  }

  daySelected($event: Moment) 
  {
    this.day = $event;

    this.periodoSelected = '0d';

    this.payload.periodo = this.payload.periodo = this.periodoSelected;;
    this.payload.data_inicio = this.day.startOf('day').format('YYYY/MM/DD HH:mm:ss');
    this.payload.data_fim = this.day.endOf('day').format('YYYY/MM/DD HH:mm:ss');

    this.getSerie();
  }

  monthSelected($event: Moment) 
  {
    this.month = $event;

    this.periodoSelected = '0m';

    this.payload.periodo = this.periodoSelected;
    this.payload.data_inicio =  this.month.startOf('month').format('YYYY/MM/DD');
    this.payload.data_fim = this.month.endOf('month').format('YYYY/MM/DD');

    this.getSerie();
  }

  yearSelected($event) 
  { 
    this.year = $event;

    this.periodoSelected = '0a';

    this.payload.periodo = this.periodoSelected;
    this.payload.data_inicio =  this.year.startOf('year').format('YYYY/MM/DD');
    this.payload.data_fim = this.year.endOf('year').format('YYYY/MM/DD');

    this.getSerie();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  download() 
  {
    let headers = ['Data'];

    console.log(this.sensorSelected);

    switch (this.sensorSelected) {
      case 'pluvio':
        headers = headers.concat(this.chartSchemaPluvio.datasets.map(value => value.label));
        break;
      case 'temp':
        headers = headers.concat(this.chartSchemaTemp.datasets.map(value => value.label));
        break;
      case 'umidade':
        headers = headers.concat(this.chartSchemaUmidade.datasets.map(value => value.label));
        break;
      case 'orvalho': 
        headers = headers.concat(this.chartSchemaOrvalho.datasets.map(value => value.label));
        break;
      case 'pressao':
        headers = headers.concat(this.chartSchemaPressao.datasets.map(value => value.label));
        break;
    }

    let dados: any[] = this.timeserieService.seriesToArray(this.dados).map(value => Object.values(value));
    
    dados.unshift(headers);

    let filename = this.sensorSelected + '_' + this.payload.estacaoId; + '_' + this.payload.periodo;
    
    if (this.payload.data_inicio)
      filename += '_' + this.payload.data_inicio;

    if (this.payload.data_fim)
      filename += '_' + this.payload.data_fim;

    this.downloadService.downloadFile(dados, filename);
  }

  getSensorCodes(alias): number[] {
    switch(alias) {
      case 'pluvio':
        return [22];
      case 'temp':
        return [4,2,3];
      case 'umidade':
        return [6,7,8];
      case 'orvalho': 
        return [10,11,12];
      case 'pressao':
        return [14,15,16];
    }
  }
}
