import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { InstituicaoActionTypes } from './instituicao.actions';
import * as InstituicaoActions from './instituicao.actions';
import { InstituicaoState } from './instituicao.reducer';
import { selectAllInstituicoes,selectCurrentInstituicao, selectCurrentInstituicoes, selectSoughtInstituicoes, selectMetaDataInstituicoes, selectTotalInstituicoes, selectLoadingInstituicoes, selectCurrentInstituicaoIds } from './instituicao.selectors';
import { Instituicao } from './instituicao.model';
import { GenericFacade } from '../../generics/generic-facade';
import { Pagination } from '../../generics/pagination.model';

@Injectable({ providedIn: 'root' })
export class InstituicaoFacade implements GenericFacade{
  all$ = this.store.pipe(select(selectAllInstituicoes));
  current$ = this.store.pipe(select(selectCurrentInstituicao));
  currentList$ = this.store.pipe(select(selectCurrentInstituicoes));
  sought$ = this.store.pipe(select(selectSoughtInstituicoes));  
  metaData$ = this.store.pipe(select(selectMetaDataInstituicoes));
  total$ = this.store.pipe(select(selectTotalInstituicoes));
  loading$ = this.store.pipe(select(selectLoadingInstituicoes));

  mutations$ = this.actions$
    .pipe(
      filter(action =>
        action.type === InstituicaoActionTypes.AddInstituicao
        || action.type === InstituicaoActionTypes.UpdateInstituicao
        || action.type === InstituicaoActionTypes.DeleteInstituicao
      )
    );

  constructor(private store: Store<InstituicaoState>, private actions$: ActionsSubject) {}

  select(id: number) {
    this.store.dispatch(new InstituicaoActions.InstituicaoSelected(id));
  }

  unselect(id: number) {
    this.store.dispatch(new InstituicaoActions.InstituicaoUnselected(id));
  }

  sought(ids: number[]) {
    this.store.dispatch(new InstituicaoActions.InstituicoesSought(ids));
  }

  load(reset: boolean = false) {
    this.store.dispatch(new InstituicaoActions.LoadInstituicoes(reset));
  }

  add(model: Instituicao) {
    this.store.dispatch(new InstituicaoActions.AddInstituicao(model));
  }

  update(model: Instituicao) {
    this.store.dispatch(new InstituicaoActions.UpdateInstituicao(model));
  }

  delete(model: Instituicao) {
    this.store.dispatch(new InstituicaoActions.DeleteInstituicao(model));
  }

  pick(id: number) {
    this.store.dispatch(new InstituicaoActions.PickInstituicao(id));
  }
  
  selectOne(id: number) {
    this.select(id);
  }

  selectMany(ids: number[]) {
    this.store.dispatch(new InstituicaoActions.InstituicoesSelected(ids));
  }

  unselectMany(ids: number[]) {
    this.store.dispatch(new InstituicaoActions.InstituicoesUnselected(ids));
  } 

  paginate(pagination: Pagination) {
    this.store.dispatch(new InstituicaoActions.PaginateInstituicoes(pagination));
  }

  destroy(id: number) {};
  save(model: any) {};
  index() {};
  edit(id: number) {};
  show(id: number) {};
  create(): void {};
  refresh(): void {};
}
