import { Component, OnInit } from '@angular/core';
import { EstacaoFacade, Estacao } from '@funceme/core-pcd';
import { ActivatedRoute } from '@angular/router';
import { ConfigFacade } from 'libs/core-pcd/src/lib/state/config/config.facade';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  public estacao: Estacao;
  public loading: boolean = false;
  public fullscreen: boolean = false;

  constructor(
    private estacaoFacade: EstacaoFacade,
    private configFacade: ConfigFacade,
    private route: ActivatedRoute,
  ) { 
    this.estacaoFacade.current$.subscribe(value => this.estacao = value);
    this.configFacade.fullscreen$.subscribe(value => this.fullscreen = value);
  }

  ngOnInit() {
    this.estacaoFacade.pick(Number(this.route.snapshot.paramMap.get('estacaoId')));
  }

  public expandir() 
  {
    this.configFacade.toggleFullscreen();
  }
}