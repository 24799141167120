import { Component, ViewChild, TemplateRef } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { EstacaoSerieModalComponent } from '../estacao-serie-modal/estacao-serie-modal.component';

@Component({
  selector: 'funceme-estacao-serie-modal-button',
  templateUrl: './estacao-serie-modal-button.component.html',
  styleUrls: ['./estacao-serie-modal-button.component.css']
})
export class EstacaoSerieModalButtonComponent {

  @ViewChild(TemplateRef, {static: true}) template: TemplateRef<any>;
  model: any;
  data: any;

  private dialogRef: MatDialogRef<EstacaoSerieModalComponent, any>;

  constructor(public dialog: MatDialog) {}

  openModal(): void 
  {   
    this.dialogRef = this.dialog.open(EstacaoSerieModalComponent, {
      width: '800px',
      data: this.data
    });    

    this.dialogRef.afterClosed().subscribe(result => {
      
    });
  }

}
