import { Action } from '@ngrx/store';
import { EstacaoSerie, EstacaoSeriePayload } from './estacao-serie.model';

export enum EstacaoSerieActionTypes {
  EstacaoSeriesAction   = '[EstacaoSeries] Action',
  EstacaoSeriePicked  = '[EstacaoSeries] Selected',
  PickEstacaoSeries     = '[EstacaoSeries] Pick'
}

export class EstacaoSeries implements Action {
  readonly type = EstacaoSerieActionTypes.EstacaoSeriesAction;
}

export class EstacaoSeriePicked implements Action {
  readonly type = EstacaoSerieActionTypes.EstacaoSeriePicked;
  constructor(public payload: EstacaoSerie) { }
}

export class PickEstacaoSeries implements Action {
  readonly type = EstacaoSerieActionTypes.PickEstacaoSeries;
  constructor(public payload: EstacaoSeriePayload) { }
}

export type EstacaoSerieActions = EstacaoSeries
  | EstacaoSeriePicked
  | PickEstacaoSeries
;
