import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntervaloSelectComponent } from './intervalo-select/intervalo-select.component';
import { MaterialModule } from '@funceme/material';
import { FormsModule } from '@angular/forms';
import { IntervaloLivreSelectComponent } from './intervalo-livre-select/intervalo-livre-select.component';

@NgModule({
  declarations: [IntervaloSelectComponent, IntervaloLivreSelectComponent],
  exports: [IntervaloSelectComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule
  ]
})
export class IntervaloModule { }
