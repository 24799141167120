import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { Instituicao } from './instituicao.model';
import { InstituicaoService } from './instituicao.service';

import {
  LoadInstituicoes, InstituicoesLoaded,
  AddInstituicao, InstituicaoAdded, 
  UpdateInstituicao, InstituicaoUpdated,
  DeleteInstituicao, InstituicaoDeleted,
  InstituicaoActionTypes,
  PaginateInstituicoes,
  InstituicoesRequestDone,
  InstituicoesPaginated,
  InstituicoesRequestFailed,
  PickInstituicao,
  InstituicaoSelected,
} from './instituicao.actions';
import { InstituicaoState } from './instituicao.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { InstituicaoFacade } from './instituicao.facade';
import { ApiResponse, paginationEquals } from '../../generics/pagination.model';

@Injectable({providedIn: 'root'})
export class InstituicaoEffects 
{
  //@Effect() effect$ = this.actions$.ofType(InstituicoesActionTypes.InstituicoesAction);

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<InstituicaoState>,
    private service: InstituicaoService,
    private facade: InstituicaoFacade,
    private notifyError: NotificationsService
  ) {}

  @Effect()
  loadInstituicoes$ = this.dataPersistence.fetch(InstituicaoActionTypes.LoadInstituicoes, {
    run: (action: LoadInstituicoes, state: any) => {
      
      if(action.reset == false && Object.keys(state.instituicao.entities).length > 0){
        let allInstituicoes;

        this.facade.all$.subscribe( instituicoes => allInstituicoes = instituicoes )
        return new InstituicoesLoaded(allInstituicoes);
      }

      return this.service
        .all()
        .pipe(
          map((res: Instituicao[]) => new InstituicoesLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadInstituicoes, error) => {
      this.notifyError.emit(error.message);
    }
  });

  @Effect()
  addInstituicao$ = this.dataPersistence.pessimisticUpdate(InstituicaoActionTypes.AddInstituicao, {
    run: (action: AddInstituicao, state: InstituicaoState) => {
      return this.service.create(action.payload).pipe(map((res: Instituicao) => new InstituicaoAdded(res)))
    },

    onError: (action: AddInstituicao, error) => {
      this.notifyError.emit(error.message);
    }
  });

  @Effect()
  updateInstituicao$ = this.dataPersistence.pessimisticUpdate(InstituicaoActionTypes.UpdateInstituicao, {
    run: (action: UpdateInstituicao, state: InstituicaoState) => {
      return this.service.update(action.payload).pipe(map((res: Instituicao) => new InstituicaoUpdated(res)))
    },

    onError: (action: UpdateInstituicao, error) => {
      this.notifyError.emit(error.message);
    }
  });

  @Effect()
  deleteInstituicao$ = this.dataPersistence.pessimisticUpdate(InstituicaoActionTypes.DeleteInstituicao, {
    run: (action: DeleteInstituicao, state: InstituicaoState) => {
      return this.service.delete(action.payload).pipe(map(_ => new InstituicaoDeleted(action.payload)))
    },

    onError: (action: DeleteInstituicao, error) => {
      this.notifyError.emit(error.message);
    }
  });

  @Effect()
  paginateInstituicoes$ = this.dataPersistence.fetch(InstituicaoActionTypes.PaginateInstituicoes, {
    run: (action: PaginateInstituicoes, state: any) => {

      if (state.instituicao.last_pagination && paginationEquals(action.payload, JSON.parse(state.instituicao.last_pagination))) 
        return new InstituicoesRequestDone;

      return this.service
        .paginate(action.payload)
        .pipe(
          map((res: ApiResponse<Instituicao>) => new InstituicoesPaginated(res))
        )
    },

    onError: (action: PaginateInstituicoes, error) => {
      this.notifyError.emit(error.message);
      return new InstituicoesRequestFailed;
    }
  });
 
}
