import { Action } from '@ngrx/store';

export enum ConfigActionTypes {
  ConfigAction = '[Config] Action',
  ToggleFullscreenAction = '[Config] Toggle Fullscreen',
  MiniAppSelected = '[Config] Mini App Selected',
  LargeAppSelected = '[Config] Large App Selected'
}

export class Config implements Action {
  readonly type = ConfigActionTypes.ConfigAction;
}

export class ToggleFullscreenAction implements Action {
  readonly type = ConfigActionTypes.ToggleFullscreenAction;
  constructor() { }
}

export class MiniAppSelected implements Action {
  readonly type = ConfigActionTypes.MiniAppSelected;
  constructor() { }
}

export class LargeAppSelected implements Action {
  readonly type = ConfigActionTypes.LargeAppSelected;
  constructor() { }
}

export type ConfigActions = Config
  | ToggleFullscreenAction
  | MiniAppSelected
  | LargeAppSelected
;
