import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IntervaloActions, IntervaloActionTypes } from './intervalo.actions';
import { Intervalo } from './intervalo.model';

/**
 * Interface to the part of the Store containing IntervaloState
 * and other information related to IntervaloData.
 */
export interface IntervaloState extends EntityState<Intervalo> {
  ids: string[] | null;
  selectedIntervaloId: string | null;
  soughtIntervaloId: string | null;
  selectedPeriodo: string | null;
  soughtPeriodo: string | null;
}

export const adapter: EntityAdapter<Intervalo> = createEntityAdapter<Intervalo>({
  selectId: (intervalo: Intervalo) => intervalo.id,
});

export const initialState: IntervaloState = adapter.getInitialState({
  ids: ['1h', '1d', '1m', 'l'],
  entities: {
    '1h' : {
      id: '1h',
      nome: 'Horário'
    },
    '1d' : {
      id: '1d',
      nome: 'Diário'
    },
    '1m' : {
      id: '1m',
      nome: 'Mensal'
    },
    'l' : {
      id: 'l',
      nome: 'Livre'
    }
  },
  selectedIntervaloId: '1h',
  selectedPeriodo: '24h',
  soughtIntervaloId: null,
  soughtPeriodo: null
});

export function intervaloReducer(state = initialState, action: IntervaloActions): IntervaloState {

  switch (action.type) {
    case IntervaloActionTypes.IntervaloSelected: {
      return Object.assign({}, state, { selectedIntervaloId: action.payload });
    }

    case IntervaloActionTypes.IntervaloSought: {
      return Object.assign({}, state, { soughtIntervaloId: action.payload });
    }

    case IntervaloActionTypes.PeriodoSelected: {
      return Object.assign({}, state, { selectedPeriodo: action.payload });
    }

    case IntervaloActionTypes.PeriodoSought: {
      return Object.assign({}, state, { soughtPeriodo: action.payload });
    }

    default:
      return state;
  }
}

export const getSelectedIntervaloId = (state: IntervaloState) => state.selectedIntervaloId;
export const getSoughtIntervaloId = (state: IntervaloState) => state.soughtIntervaloId;
export const getSelectedPeriodo = (state: IntervaloState) => state.selectedPeriodo;
export const getSoughtPeriodo = (state: IntervaloState) => state.soughtPeriodo;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of intervalo ids
export const selectIntervaloIds = selectIds;

// select the dictionary of intervalo entities
export const selectIntervaloEntities = selectEntities;

// select the array of intervalo
export const selectAllIntervalos = selectAll;

// select the total intervalo count
export const selectIntervaloTotal = selectTotal;


