import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { Sensor } from './sensor.model';
import { SensorService } from './sensor.service';

import {
  LoadSensores, 
  SensoresLoaded,
  SensorActionTypes,
  PaginateSensores,
  SensoresRequestDone,
  SensoresPaginated,
  SensoresRequestFailed,
} from './sensor.actions';
import { SensorState } from './sensor.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { SensorFacade } from './sensor.facade';
import { paginationEquals, ApiResponse } from '../../generics/pagination.model';

@Injectable({providedIn: 'root'})
export class SensorEffects 
{
  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<SensorState>,
    private service: SensorService,
    private facade: SensorFacade,
    private notifyError: NotificationsService
  ) {}

  //@Effect() effect$ = this.actions$.ofType(SensoresActionTypes.SensoresAction);

  @Effect()
  loadSensores$ = this.dataPersistence.fetch(SensorActionTypes.LoadSensores, {
    run: (action: LoadSensores, state: any) => {

      if(action.reset == false && Object.keys(state.sensor.entities).length > 0){
        let Allsensors;

        this.facade.all$.subscribe( sensors => Allsensors = sensors )
        return new SensoresLoaded(Allsensors);
      }
      return this.service
        .all()
        .pipe(
          map((res: Sensor[]) => new SensoresLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadSensores, error) => {
      this.notifyError.emit(error.message);
    }
  });

  @Effect()
  paginateSensores$ = this.dataPersistence.fetch(SensorActionTypes.PaginateSensores, {
    run: (action: PaginateSensores, state: any) => {

      if (state.sensor.last_pagination && paginationEquals(action.payload, JSON.parse(state.sensor.last_pagination))) 
        return new SensoresRequestDone;

      return this.service
        .paginate(action.payload)
        .pipe(
          map((res: ApiResponse<Sensor>) => new SensoresPaginated(res))
        )
    },

    onError: (action: PaginateSensores, error) => {
      this.notifyError.emit(error.message);
      return new SensoresRequestFailed;
    }
  });
}
