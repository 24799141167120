import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NotificationsService } from '../../notifications/notifications.service';
import { DadoSensor } from './dado-sensor.model';

@Injectable({
  providedIn: 'root'
})
export class DadoSensorService {
  
  private model = 'rpc/v3/dado-sensor';
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
    private ns: NotificationsService
  ) { }

  getUrl() {
    return `${environment.apiEndpoint}${this.model}`;
  }

  getUrlForId(id) {
    return `${this.getUrl()}/${id}`;
  }

  all(sensor, instituicoes, ufs, intervalo, periodo, operador = 'AVG') {
    return this.http.get<DadoSensor[]>(this.getUrl() 
    + '?sensor=' + sensor 
    + '&instituicao=' + instituicoes
    + '&uf=' + ufs
    + '&intervalo=' + intervalo
    + '&periodo=' + periodo
    + '&operador=' + operador);
  }

  load(id) {
    return this.http.get<DadoSensor>(this.getUrlForId(id));
  }

  report($estacao_id, $sensor_id, $periodo, $intervalo, $operador, $data, $valor, $instituicao_nome, $instituicao_id) {
    return this.http.get<DadoSensor>(`${environment.apiEndpoint}/rpc/v1/dado-sensor-report?`
    + 'estacao_id=' + $estacao_id 
    + '&sensor_id=' + $sensor_id 
    + '&periodo=' + $periodo 
    + '&intervalo=' + $intervalo 
    + '&operador=' + $operador 
    + '&data=' + $data 
    + '&valor=' + $valor
    + '&instituicao_nome=' + $instituicao_nome
    + '&instituicao_id=' + $instituicao_id);
  }
}
