import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DadoSensorPayload, emptyDadoSensorPayload } from '../../state/dado-sensor/dado-sensor.model';
import { SensorFacade } from '../../state/sensor/sensor.facade';
import { IntervaloFacade } from '../../state/intervalo/intervalo.facade';
import { UfFacade } from '../../state/uf/uf.facade';
import { InstituicaoFacade } from '../../state/instituicao/instituicao.facade';
import { EstacaoFacade } from '../../state/estacao/estacao.facade';

@Injectable({
  providedIn: 'root'
})
export class NavigateLinkService {
  
  private dadoSensorPayload: DadoSensorPayload = emptyDadoSensorPayload;

  constructor(
    private sensorFacade: SensorFacade,
    private intervaloFacade: IntervaloFacade,
    private ufFacade: UfFacade,
    private instituicaoFacade: InstituicaoFacade,
    private estacaoFacade: EstacaoFacade,
    private router: Router
  ) { 
    this.instituicaoFacade.currentList$.subscribe(values => {
        let list = [];
        values.forEach(instituicao => {
          if (instituicao)
            list.push(instituicao.id);            
        });
        this.dadoSensorPayload.instituicaoIds = list;
      } 
    );

    this.ufFacade.currentList$.subscribe(
      value => {
        let list = [];
        value.forEach(uf => {
          if (uf)
            list.push(uf.id);            
        });
        this.dadoSensorPayload.ufs = list;
      } 
    );

    this.sensorFacade.current$.subscribe(value => 
      this.dadoSensorPayload.sensorId = (value)? value.id : null
    );

    this.sensorFacade.operador$.subscribe(value => 
      this.dadoSensorPayload.operador = (value)? value : null
    );

    this.intervaloFacade.currentIntervalo$.subscribe(value => 
      this.dadoSensorPayload.intervalo = (value)? value.id : null
    );
    
    this.intervaloFacade.currentPeriodo$.subscribe(value => 
      this.dadoSensorPayload.periodo = (value)? value : null
    );

    this.estacaoFacade.current$.subscribe(value => {
      this.dadoSensorPayload.estacao = (value)? value.id : null;
      // this.navigate(this.dadoSensorPayload);
    });

    this.estacaoFacade.picking$.subscribe(value => {
      if (value) {
        this.dadoSensorPayload.estacao = (value)? value : null;
        this.navigate(this.dadoSensorPayload)
      };
    });
  }

  public getPayload(): DadoSensorPayload {
    return this.dadoSensorPayload;
  }

  public navigate(dadoSensorPayload: DadoSensorPayload) 
  {
    this.router.navigate([],{
      queryParams:{
        sensor:       dadoSensorPayload.sensorId,
        intervalo:    dadoSensorPayload.intervalo,
        periodo:      dadoSensorPayload.periodo,
        uf:           dadoSensorPayload.ufs.join(','),
        instituicao:  dadoSensorPayload.instituicaoIds.join(','),
        operador:     dadoSensorPayload.operador,
        estacao:      dadoSensorPayload.estacao,
      },
      replaceUrl: true
    });
  }

  public setPayload(params): DadoSensorPayload
  {
    let keys = Object.keys(params);
          
    if(keys.length) {
      let minimalParams = ['sensor','intervalo','periodo','instituicao','operador'];

      if(minimalParams.every(v => keys.includes(v)) && !Object.values(params).includes('null') && !Object.values(params).includes(''))
        return this.setParametersByRouteParams(params);
    }

    if(this.dadoSensorPayload.sensorId && this.dadoSensorPayload.instituicaoIds.length && this.dadoSensorPayload.ufs.length)
      return this.dadoSensorPayload;

    this.setParametersByDefault();

    return this.dadoSensorPayload;
  }

  setParametersByRouteParams(params)
  {
    this.sensorFacade.select(params['sensor']);
    this.dadoSensorPayload.sensorId = params['sensor'];

    this.intervaloFacade.selectIntervalo(params['intervalo']);
    this.dadoSensorPayload.intervalo = params['intervalo'];

    this.intervaloFacade.selectPeriodo(params['periodo']);
    this.dadoSensorPayload.periodo = params['periodo'];

    this.sensorFacade.changeOperador(params['operador']);
    this.dadoSensorPayload.operador = params['operador'];

    this.dadoSensorPayload.instituicaoIds.map(value => this.instituicaoFacade.unselectMany([value]));
    params['instituicao'].split(',').map(instituicao => this.instituicaoFacade.selectMany([Number(instituicao)]));
    this.dadoSensorPayload.instituicaoIds = [...params['instituicao'].split(',')]
      
    this.dadoSensorPayload.ufs.map(value => this.ufFacade.unselectMany([value]));
    if (params['uf']){
      this.dadoSensorPayload.ufs = [...params['uf'].split(',')]
      params['uf'].split(',').map(uf => this.ufFacade.selectMany([uf]));
    }

    this.dadoSensorPayload.estacao = null;
    this.estacaoFacade.select(null);
    if (params['estacao']){
      this.dadoSensorPayload.estacao = params['estacao'];
      this.estacaoFacade.pick(params['estacao']);
    }

    return this.dadoSensorPayload;
  }

  setParametersByDefault()
  {
    this.sensorFacade.select(2);
    this.dadoSensorPayload.sensorId = 2;

    this.intervaloFacade.selectIntervalo('1h');
    //this.dadoSensorPayload.intervalo = '1h';

    this.intervaloFacade.selectPeriodo('24h');
    //this.dadoSensorPayload.periodo = '24h';

    this.sensorFacade.changeOperador('AVG');
    //this.dadoSensorPayload.operador = 'AVG';

    this.dadoSensorPayload.instituicaoIds.map(value => this.instituicaoFacade.unselectMany([value]));
    this.instituicaoFacade.selectMany([1]);
    //this.dadoSensorPayload.instituicaoIds.push(1);

    this.dadoSensorPayload.ufs.map(value => this.ufFacade.unselectMany([value]));
    this.ufFacade.selectMany(['CE']);
    //this.dadoSensorPayload.ufs.push('CE');

    this.dadoSensorPayload.estacao = null;

    return this.dadoSensorPayload;
  }
}
