import { ActionReducerMap} from '@ngrx/store';
import * as fromSensor from './sensor/sensor.reducer';
import * as fromInstituicao from './instituicao/instituicao.reducer';
import * as fromIntervalo from './intervalo/intervalo.reducer';
import * as fromDadoSensor from './dado-sensor/dado-sensor.reducer';
import * as fromEstacao from './estacao/estacao.reducer';
import * as fromEstacaoSerie from './estacao-serie/estacao-serie.reducer';
import * as fromUf from './uf/uf.reducer';
import * as fromConfig from './config/config.reducer';
import * as fromGrafico from './grafico/grafico.reducer';
import * as fromTempoAgora from './tempo-agora/tempo-agora.reducer';
import * as fromMunicipio from './municipio/municipio.reducer';

// Single Source of Truth
export interface AppState {
    estacao: fromEstacao.EstacaoState,
    sensor: fromSensor.SensorState,
    instituicao: fromInstituicao.InstituicaoState,
    intervalo: fromIntervalo.IntervaloState,
    dadosSensor: fromDadoSensor.DadoSensorState,
    estacaoSerie: fromEstacaoSerie.EstacaoSerieState,
    uf: fromUf.UfState,
    config: fromConfig.ConfigState,
    grafico: fromGrafico.GraficoState,
    tempoAgora: fromTempoAgora.TempoAgoraState,
    municipio: fromMunicipio.MunicipioState
} 

export const reducers: ActionReducerMap<AppState> = { 
    estacao: fromEstacao.estacaoReducer, 
    sensor: fromSensor.sensorReducer,
    instituicao: fromInstituicao.instituicaoReducer,
    intervalo: fromIntervalo.intervaloReducer,
    dadosSensor: fromDadoSensor.dadoSensorReducer,
    estacaoSerie: fromEstacaoSerie.estacaoSerieReducer,
    uf: fromUf.ufReducer,
    config: fromConfig.configReducer,
    grafico: fromGrafico.graficoReducer,
    tempoAgora: fromTempoAgora.tempoAgoraReducer,
    municipio: fromMunicipio.municipioReducer
};