import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { MatDrawer } from '@angular/material';
import { 
  Sensor, 
  SensorFacade, 
  InstituicaoFacade, 
  DadoSensorFacade, 
  EstacaoFacade, 
  UfFacade, 
  DadoSensor,
} from '@funceme/core-pcd';
import { ConfigFacade } from 'libs/core-pcd/src/lib/state/config/config.facade';
import { NavigateLinkService } from 'libs/core-pcd/src/lib/components/pcd/navigate-link.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public loadingDadosSensores: boolean = false;
  public loadingEstacao: boolean = false;
  public queueIn: Date;
  public dataAtualizacao: string = '00:00:00';
  public cachedAt: Date;
  public dataCached: string = '00/00/0000 00:00:00';

  public currentSensor$: Observable<Sensor> = this.sensorFacade.current$;
  public currentEstacao;

  public expanded: boolean = false;
  public first: boolean = true;
  public fullscreen: boolean = false;

  private isFirstLoad: boolean = true;

  @Input() public showSelecionados: boolean = false;
  @Input() public show: String = 'destaque';
  @Input() public legenda: boolean = false;

  @ViewChild('drawer', {static: true}) panelOptions:MatDrawer; 

  constructor(
    private sensorFacade: SensorFacade,
    private instituicaoFacade: InstituicaoFacade,
    private dadoSensorFacade: DadoSensorFacade,
    private estacaoFacade: EstacaoFacade,
    private ufFacade: UfFacade,
    private configFacade: ConfigFacade,
    private route: ActivatedRoute,
    public navigateLinkService: NavigateLinkService
  ) {
    this.configFacade.fullscreen$.subscribe(value => this.fullscreen = value);

    this.estacaoFacade.current$.subscribe(value =>
      this.currentEstacao = value
    )

    this.dadoSensorFacade.allDadoSensores$.subscribe(value => {
      if (!value) return;
      if(this.currentEstacao.id && value.every(v => this.currentEstacao.id != v.codigo))
        this.estacaoFacade.select(null);
    });

    this.dadoSensorFacade.loading$.subscribe(value => {
      this.loadingDadosSensores = value;
      if (this.loadingDadosSensores && this.panelOptions)
        this.panelOptions.close();
    });

    this.estacaoFacade.picking$.subscribe(value => {
      this.loadingEstacao = (value == null)
      this.showSelecionados = false;
    });

    this.dadoSensorFacade.queueIn$.subscribe(value => this.queueIn = value);
    this.dadoSensorFacade.cachedAt$.subscribe(value => this.dataCached = (value) ? value.toLocaleString('pt-BR') : '');
  }

  private estacaoHasData(dadosSensor: DadoSensor[], estacaoId: number) {
    return dadosSensor.find(dado => dado.codigo == estacaoId);
  }

  ngOnInit() 
  {
    this.configFacade.large();

    const routeParams: Object = this.route.snapshot.queryParams;

    this.sensorFacade.load();
    this.instituicaoFacade.load();
    this.ufFacade.load();

    zip(
      this.sensorFacade.all$,
      this.instituicaoFacade.all$,
      this.ufFacade.all$
    ).subscribe(
      ([sensors, instituicoes, ufs]) => {

        if (!this.isFirstLoad || sensors.length == 0) return;

        this.navigateLinkService.setPayload(routeParams);

        this.isFirstLoad = false;

        this.dadoSensorFacade.load();
      }
    );

    this.autoRefresh();
  }

  private autoRefresh() {
    /*setInterval(() => {
      if (this.queueIn && !this.loadingDadosSensores && !this.panelOptions.opened) {
        let queueInTime = this.queueIn.getTime();

        if (queueInTime > Date.now()) {
          let time = (queueInTime - Date.now());
          this.dataAtualizacao = new Date(time).toISOString().substr(11, 8);
        }

        if (queueInTime < Date.now())
          this.dadoSensorFacade.load();
      }
    }, 1000);*/
  }

  public expandir() {
    this.configFacade.toggleFullscreen();
  }

  public atualizar() {
    this.dadoSensorFacade.load(true);

    this.sensorFacade.load(true);
    this.instituicaoFacade.load(true);  
    this.ufFacade.load(true);         
  }

  public selecionados() {
    this.showSelecionados = !this.showSelecionados;
  }
  public showDestaque() {
    if (this.show != 'destaque')
      this.show = 'destaque'
    else
      this.show = ''
  }
  public showLegenda() {
    if (this.show != 'legenda')
      this.show = 'legenda'
    else
      this.show = ''
  }

  public toggleOptions() {
    if (this.loadingDadosSensores) console.log('loading');
    this.panelOptions.toggle()
  }
}
