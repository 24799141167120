import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { DadoSensorActions, DadoSensorActionTypes } from './dado-sensor.actions';
import { DadoSensor } from './dado-sensor.model';

/**
 * Interface to the part of the Store containing DadoSensorState
 * and other information related to DadoSensorData.
 */
export interface DadoSensorState extends EntityState<DadoSensor> {
  loading: boolean;
  loaded_at: Date | null;
  queue_in: Date | null;
  cached_at: Date | null;
}

export const adapter: EntityAdapter<DadoSensor> = createEntityAdapter<DadoSensor>({
  selectId: (dadoDadoSensor: DadoSensor) => dadoDadoSensor.codigo,
});

export const initialState: DadoSensorState = adapter.getInitialState({
  // additional entity state properties
  selectedId: null,
  loading: false,
  loaded_at: null,
  queue_in: null,
  cached_at: null
});

export function dadoSensorReducer(state = initialState, action: DadoSensorActions): DadoSensorState {

  switch (action.type) {
    case DadoSensorActionTypes.DadoSensorSelected: {
      return Object.assign({}, state, { selectedId: action.payload });
    }

    case DadoSensorActionTypes.LoadDadoSensores: {
      return Object.assign({}, state, { loading: true });
    }

    case DadoSensorActionTypes.DadoSensoresFailed: {
      return Object.assign({}, state, { loading: false });
    }

    case DadoSensorActionTypes.ReportDadoSensor: {
      return Object.assign({}, state, { loading: true });
    }

    case DadoSensorActionTypes.DadoSensorReported: {
      return Object.assign({}, state, { loading: false });
    }

    case DadoSensorActionTypes.DadoSensoresLoaded: {
      
      state.loaded_at = new Date();
      state.loading = false;

      return adapter.addAll(action.payload, state);
    }

    case DadoSensorActionTypes.DadoSensoresQueueIn: {
      return Object.assign({}, state, { queue_in: action.payload });
    }

    case DadoSensorActionTypes.DadoSensoresCachedAt: {
      return Object.assign({}, state, { cached_at: action.payload });
    }

    default:
      return state;
  }
}

export const isLoading     = (state: DadoSensorState) => state.loading;
export const getLoadedAt   = (state: DadoSensorState) => state.loaded_at;
export const getQueueIn    = (state: DadoSensorState) => state.queue_in;
export const getCachedAt   = (state: DadoSensorState) => state.cached_at;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of dado-sensor ids
export const selectDadoSensorIds = selectIds;

// select the dictionary of dado-sensor entities
export const selectDadoSensorEntities = selectEntities;

// select the array of dado-sensor
export const selectAllDadoSensores = selectAll;

// select the total dado-sensor count
export const selectDadoSensorTotal = selectTotal;
