import { NativeDateAdapter } from "@angular/material";

export class AppDateAdapter extends NativeDateAdapter {

    format(date: Date, displayFormat: string): string {
        // let month = date.getMonth();
        // let year = date.getFullYear();
        // return  this.getMonthName(month) + '/' + year;

        if (displayFormat == "input") {
            let month = date.getMonth();
            let year = date.getFullYear();
            return  this.getMonthName(month) + '/' + year;
         }
         else {
            let day = this._to2digit(date.getDate());
            let month = this._to2digit(date.getMonth() + 1);
            let year = date.getFullYear();
            return `${day}/${month}/${year}`;          
         }          
        }
        
        private _to2digit(n: number) {
        return ('00' + n).slice(-2);
    }
    parse(value: any): Date | null {
        if (typeof value === 'string') {
            const parts = value.split('/');
            if (parts.length === 3) {
                const day = parseInt(parts[0], 10);
                const monthIndex = parseInt(parts[1], 10) - 1;
                const year = parseInt(parts[2], 10);
                if (!isNaN(day) && !isNaN(monthIndex) && !isNaN(year)) {
                    return new Date(year, monthIndex, day);
                }
            }
        }
        return super.parse(value);
    }

    getMonthName(monthIndex) {
        let months = ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro']

        return months[monthIndex];
    }
    getLocale(){
        return this.locale;
    }

}

export const APP_DATE_FORMATS =
{
   parse: {
       dateInput: {day: 'numeric', month: 'long', year: 'numeric'}
   },
   display: {
       dateInput: 'input',
       monthYearLabel: 'inputMonth',
       dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
       monthYearA11yLabel: {year: 'numeric', month: 'long'},
   }
}

export const APP_DATE_FORMAT_2 =
{
    parse: {
        dateInput: {day: 'numeric', month: 'numeric', year: 'numeric'}
    },
    display: {
        dateInput: 'input_2',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
        monthYearA11yLabel: {year: 'numeric', month: 'long'},
    }
}