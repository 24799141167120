import * as fromSensor from './sensor.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { emptySensor, Sensor } from './sensor.model';

export const selectSensorState = createFeatureSelector<fromSensor.SensorState>('sensor');

export const selectSensorIds = createSelector(
  selectSensorState,
  fromSensor.selectSensorIds
);

export const selectSensorEntities = createSelector(
  selectSensorState,
  fromSensor.selectSensorEntities
);

export const selectAllSensores = createSelector(
  selectSensorState,
  fromSensor.selectAllSensores
);

export const selectFilteredSensorIds = createSelector(
  selectSensorState,
  fromSensor.getFilteredSensorIds
);

export const selectCurrentSensorId = createSelector(
  selectSensorState,
  fromSensor.getSelectedSensorId
);

export const selectCurrentSensor = createSelector(
  selectSensorEntities,
  selectCurrentSensorId,
  (sensorEntities, sensorId) => {
    return sensorId ? sensorEntities[sensorId] : emptySensor;
  }
);

export const selectSoughtSensorId = createSelector(
  selectSensorState,
  fromSensor.getSoughtSensorId
);

export const selectSensorOperador = createSelector(
  selectSensorState,
  fromSensor.getSensorOperador
);

export const selectSoughtSensor = createSelector(
  selectSensorEntities,
  selectSoughtSensorId,
  (sensorEntities, sensorId) => {
    return sensorId ? sensorEntities[sensorId] : emptySensor;
  }
);


export const selectMetaDataSensores = createSelector(
  selectSensorState,
  fromSensor.getMetaDataSensores
);

export const selectTotalSensores = createSelector(
  selectSensorState,
  fromSensor.getTotalSensores
); 

export const selectLoadingSensores = createSelector(
  selectSensorState,
  fromSensor.getLoadingSensores
); 

export const selectCurrentSensorIds = createSelector(
  selectSensorState,
  fromSensor.getSelectedSensorIds
);

export const selectCurrentSensores = createSelector(
  selectSensorEntities,
  selectCurrentSensorIds,
  (estacaoEntities, estacaoIds) => {

    let list: Sensor[] = [];

    estacaoIds.forEach(element => {
      list.push(estacaoEntities[element]);
    });

    return list;
  }
);