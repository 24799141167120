import { Component, OnInit } from '@angular/core';
import { SensorFacade } from '../../../state/sensor/sensor.facade';

@Component({
  selector: 'funceme-sensor-operador-select',
  templateUrl: './sensor-operador-select.component.html',
  styleUrls: ['./sensor-operador-select.component.css']
})
export class SensorOperadorSelectComponent implements OnInit {

  public currentOperador: string = '';

  constructor(
    private sensorFacade: SensorFacade
  ) {
    this.sensorFacade.operador$.subscribe(value => this.currentOperador = value);
  }

  ngOnInit() {
  }

  changedOperador(event) {
    this.currentOperador = event.value;
  }
}
