import * as fromTempoAgora from './tempo-agora.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { emptyTempoAgora } from './tempo-agora.model';

export const selectTempoAgoraState = createFeatureSelector<fromTempoAgora.TempoAgoraState>('tempoAgora');

export const selectTempoAgoraIds = createSelector(
  selectTempoAgoraState,
  fromTempoAgora.selectTempoAgoraIds
);

export const selectTempoAgoraEntities = createSelector(
  selectTempoAgoraState,
  fromTempoAgora.selectTempoAgoraEntities
);

export const selectAllTempoAgora = createSelector(
  selectTempoAgoraState,
  fromTempoAgora.selectAllTempoAgora
);

export const selectCurrentTempoAgoraId = createSelector(
  selectTempoAgoraState,
  fromTempoAgora.getSelectedTempoAgoraId
);

export const selectLoadingTempoAgora = createSelector(
  selectTempoAgoraState,
  fromTempoAgora.getLoadingTempoAgora
);

export const selectCurrentTempoAgora = createSelector(
  selectTempoAgoraEntities,
  selectCurrentTempoAgoraId,
  (tempoAgoraEntities, tempoAgoraId) => {
    return (tempoAgoraEntities[tempoAgoraId])? tempoAgoraEntities[tempoAgoraId] : emptyTempoAgora;
  }
);

